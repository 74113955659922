
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import useValidation from '@/mixins/useValidation';
import useI18n from '@/mixins/useI18n';
import useConfigState from '@/state/configState';

export default defineComponent({
  name: 'Index',
  components: { },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const testing = ref('');
    const { registrationConfig: config } = useConfigState();

    const validations = useValidation(config, 'user.registration');

    const register = () => {
      store.commit('removeRegistrationData');
      store.dispatch('logout');
      router.push({ name: 'register' });
    };

    const login = () => {
      router.push({ name: 'login' });
    };

    return {
      register,
      login,
      testing,
      t,
      ...validations
    };
  },
});
