import useRouterIngoreDuplicate from '@/mixins/useRouterIngoreDuplicate';
import { ResendTimeout, SETTINGS_RESEND_INTERVAL } from '@/models/ResendTimout';
import { Settings, SettingsVerificationType } from '@/models/Settings';
import { getSettings } from '@/services/Settings/Api';
import { computed, ref, watch } from 'vue';
import { useLocalStorage, useTimeout } from '@vueuse/core';
import { debounce } from 'lodash';
import { useStore } from 'vue2-helpers/vuex';

const resendTimeouts:Record<string, ResendTimeout> = {};

Object.values(SettingsVerificationType).forEach((key) => {
  resendTimeouts[key] = useTimeout(SETTINGS_RESEND_INTERVAL, { controls: true, immediate: false });
});

const settings = ref<Settings | null>(null);
const showDeleteAccountPopup = ref(false);
const isSafeToAutoSave = ref(false);

const lastVerification = ref<SettingsVerificationType | null>(null);
const pendingVerification = useLocalStorage<SettingsVerificationType | null>('pendingVerifications', null);
const areSettingsLoaded = ref(false);

watch(pendingVerification, (verification) => {
  if (verification) {
    lastVerification.value = verification;
    areSettingsLoaded.value = false;
  }
});

const loadSettingsImmediately = async () => {
  settings.value = await getSettings();
  areSettingsLoaded.value = true;
};

watch(settings, (settings, prevSetting) => {
  if (settings && !prevSetting) isSafeToAutoSave.value = true;
});

const loadSettings = debounce(loadSettingsImmediately, 100);

let resend = () => {
  // console.log('Nothing sent yet');
};

const setResendFunction = (fn:any) => {
  resend = () => {
    if (pendingVerification.value) {
      resendTimeouts[pendingVerification.value].start();
    }
    fn();
  };
};

const isResendDisabled = computed(() => (pendingVerification.value ? resendTimeouts[pendingVerification.value]?.isPending.value : false));

const useSettings = () => {
  const { pushRoute } = useRouterIngoreDuplicate();
  const store = useStore();

  const updateStoreUser = () => {
    const userSettings = settings.value?.userDataSettings;
    if (userSettings) {
      const user = {
        ...(store.getters.user),
        firstname: userSettings.firstname,
        lastname: userSettings.lastname,
        fullname: `${userSettings.firstname} ${userSettings.lastname}`,
        email: userSettings.primaryEmail
      };
      store.commit('setUser', { user });
    }
  };

  watch(areSettingsLoaded, async (isLoaded) => {
    if (isLoaded && isSafeToAutoSave.value) {
      // after verifying FirstNameLastName or Email
      if (lastVerification.value === SettingsVerificationType.FirstAndLastName
        || lastVerification.value === SettingsVerificationType.Email) {
        updateStoreUser();
      }
      // after deleting account:
      if (lastVerification.value === SettingsVerificationType.DeleteAccount) {
        try {
          await pushRoute({
            name: 'logout',
            query: {
              final: '1'
            }
          });
        } catch (e) {
          window.location.href = '/logout?final=1';
        }
      }
    }
  });

  return ({
    isSafeToAutoSave,
    isResendDisabled,
    areSettingsLoaded,
    lastVerification,
    loadSettings,
    settings,
    pendingVerification,
    showDeleteAccountPopup,
    resend,
    setResendFunction,
  });
};

export default useSettings;
