import store from '@/store';
import MyProjects from '@/views/my-projects/pages/MyProjects.vue';
import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    name: 'myProjects',
    path: '/my-projects/',
    component: MyProjects,
    meta: {
      layout: 'full',
      auth: true,
    },
  },
];
export default routes;
