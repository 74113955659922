
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import {
  computed, defineComponent, onMounted, reactive, ref
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import LRPopup from '@/components/login-register/LRPopup.vue';
import useVuelidate from '@vuelidate/core';
import useBCValidators from '@/mixins/useBCValidators';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import BCXVuelidateErrors from '@/components/molecules/BCXVuelidateErrors.vue';
import ButtonBlock from '@/components/login-register/ButtonBlock.vue';
import useI18n from '@/mixins/useI18n';
import ByteCookieLogoFull from '@/components/svg/logo/logo-full-for-darkBG.svg?inline';
import { useRoute } from 'vue2-helpers/vue-router';
import InviteCodePopup from '@/components/login-register/InviteCodePopup.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import TranslatedBackendErrors from '@/utils/TranslatedBackendErrors';
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import { TranslatedErrorCode } from '@/models/BackendError';
import { UserType } from '@/models/User';

export default defineComponent({
  name: 'Registration',

  components: {
    BackendErrors,
    InviteCodePopup,
    ButtonBlock,
    BCXVuelidateErrors,
    BCXCheckbox,
    LRPopup,
    BCXValidatedInput,
    BCXMarkdown,
    ByteCookieLogoFull
  },

  setup() {
    const { t, locale } = useI18n();
    const {
      emailValidation,
      required,
      termsValidation
    } = useBCValidators();
    const {
      isMobileWidth
    } = useResponsiveness();
    const route = useRoute();

    const accountType = computed(() => {
      switch (route.name) {
        case 'register-guest': return UserType.GUEST;
        case 'register-customer': return UserType.COMPANY;
        default: return UserType.FREELANCER;
      }
    });

    const isCompanyType = computed(() => accountType.value === UserType.COMPANY);
    const isGuestType = computed(() => accountType.value === UserType.GUEST);
    const isFreelancerType = computed(() => accountType.value === UserType.FREELANCER);

    const titleParts = computed(() => (t('registration.title') as string).split(/ByteCookie/));

    const fields = reactive({
      firstname: '',
      lastname: '',
      companyname: '',
      email: '',
      code: '',
      checked: false,
    });

    const rules = computed(() => ({
      firstname: { required },
      lastname: { required },
      email: emailValidation,
      ...isCompanyType.value && {
        companyname: { required }
      },
      ...!isGuestType.value && {
        checked: termsValidation,
        code: isFreelancerType.value ? { required } : {},
      }
    }));

    const v = useVuelidate(rules, fields);

    const checkedTermsAndPrivacy = ref(false);
    const submitting = ref(false);
    const legalText = ref<'terms' | 'privacy' | false>(false);
    const isInvitePopupVisible = ref(false);
    const backendErrors = ref<TranslatedErrorCode[] | string[]>([]);
    const userId = ref('');

    const store = useStore();

    const prefillForm = () => {
      const {
        user,
        inviteCode
      } = store.getters;

      if (user) {
        fields.companyname = user.companyname;
        fields.firstname = user.firstname;
        fields.lastname = user.lastname;
        fields.email = user.email;
        userId.value = user.userId;
      }

      fields.code = inviteCode || route?.query?.code;
      fields.email = (route?.query?.email as string) ?? '';
      fields.firstname = (route?.query?.firstname as string) ?? '';
      fields.lastname = (route?.query?.lastname as string) ?? '';
    };

    onMounted(() => {
      const {
        inviteCode
      } = store.getters;

      prefillForm();

      if (inviteCode) {
        checkedTermsAndPrivacy.value = true;
      } else if (route?.params?.showInvitePopup) {
        isInvitePopupVisible.value = true;
      }
      ['forum-overview-visited', 'locale', 'selected_audio_input', 'selected_camera',
        'threadsViewed', 'video_expansion_panels', 'user_roles', 'user_permissions',
        'token_expires', 'availability', 'last_reload'].forEach((item) => localStorage.removeItem(item));
    });

    const submit = async () => {
      const isValid = await v.value.$validate();
      if (!submitting.value && isValid) {
        const {
          firstname,
          lastname,
          companyname,
          email
        } = fields;
        const params = {
          user: {
            firstname,
            lastname,
            email,
            ...accountType.value === UserType.COMPANY && {
              companyname
            },
            ...userId.value && { userId: userId.value }
          },
          inviteCode: fields.code,
          checkedAgbAndPrivacy: fields.checked,
        };

        submitting.value = true;
        const backendErrorsObject:TranslatedBackendErrors = await store.dispatch('submitBasicData', params);
        backendErrors.value = backendErrorsObject.allErrors;
        submitting.value = false;
      }
    };

    const onShow = (which: 'terms' | 'privacy') => {
      legalText.value = which;
    };

    const showHelpPopup = () => {
      isInvitePopupVisible.value = true;
    };

    const showLoginWithEmail = computed(() => backendErrors.value.includes(TranslatedErrorCode.EMAIL_NOT_UNIQUE));
    const loginLinkText = computed(() => {
      if (locale.value === 'de') {
        return (t('buttons.login') as string).toLowerCase();
      }
      return t('buttons.login');
    });

    return {
      fields,
      submitting,
      legalText,
      v,
      onShow,
      submit,
      titleParts,
      showHelpPopup,
      isInvitePopupVisible,
      isMobileWidth,
      backendErrors,
      showLoginWithEmail,
      loginLinkText,
      isCompanyType,
      isGuestType
    };
  }
});
