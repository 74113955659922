
import { defineComponent, ref, watch } from 'vue';
import ProfileEditActionButtons from '@/views/profile/components/ProfileEditActionButtons.vue';
import IndustriesEditor from '@/views/profile/components/IndustriesEditor.vue';
import { Industry } from '@/models/Tags';
import IndustryTags from '@/views/profile/components/IndustryTags.vue';
import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import ProfileRichTextEditor from '@/views/profile/components/ProfileRichTextEditor.vue';
import { ProfileMaxChars } from '@/models/Profile';
import useProfileState from '@/state/profile/profileState';

export default defineComponent({
  name: 'ProfileAboutusEditor',
  components: {
    ProfileRichTextEditor,
    BCXInfoSection,
    IndustryTags,
    IndustriesEditor,
    ProfileEditActionButtons
  },
  setup() {
    const { profile } = useProfileState();
    const employees = ref('');
    const industries = ref<Industry[]>([]);
    const aboutUs = ref('');
    const canSend = ref(false);

    watch(profile, (profile) => {
      employees.value = `${profile?.companyInformation?.employees ?? ''}`;
      industries.value = profile?.companyInformation?.industrieList ?? [];
      aboutUs.value = profile?.companyInformation?.aboutUs ?? '';
    }, {
      immediate: true
    });

    const save = async () => {
      // @ todo: implement Save to backend
    };

    return {
      aboutUs,
      employees,
      industries,
      ProfileMaxChars,
      canSend,
      save
    };
  }
});
