
import BCXItemsWithPoints from '@/components/molecules/BCXItemsWithPoints.vue';
import BCXLazy from '@/components/molecules/BCXLazy.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import Reporting from '@/components/voting/Reporting.vue';
import Voting from '@/components/voting/Voting.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useI18n from '@/mixins/useI18n';

import {
  Chat, CHAT_TYPE_FORUM, CHAT_TYPE_PROJECT, ChatMessage, MESSAGE_EVENT_SET_TEXT, TEMPORARY_CHAT_USER_ID
} from '@/models/Messenger';
import { User } from '@/models/User';
import useMessengerState from '@/state/messenger/messengerState';
import {
  computed, defineComponent, PropType, Ref, toRefs
} from 'vue';
import { createVuexHelpers } from 'vue2-helpers';
import eventBus from '@/state/messenger/EventBus';

export default defineComponent({
  name: 'BCXMessengerChatMessage',
  components: {
    BCXMarkdown,
    BCXLazy,
    BCXItemsWithPoints,
    Reporting,
    Voting,
  },
  props: {
    message: {
      type: Object as PropType<ChatMessage>,
      default: () => null,
    },
    chat: {
      type: Object as PropType<Chat>,
      default: () => null,
    },
    areInitialMessagesShown: {
      type: Boolean
    }
  },
  setup(props) {
    const { markAsRead, editMessageId, sendingMessageId } = useMessengerState();
    const { getFormattedTimeByAge } = useFormattedDateTime();
    const { message, chat } = toRefs(props);
    const { useGetters } = createVuexHelpers();
    const { user } = useGetters(['user']) as {
      user: Ref<User>;
    };

    const i18n = useI18n();
    const isTemporary = computed(() => message.value.creator === TEMPORARY_CHAT_USER_ID);
    const isMe = computed(() => user.value.userId === message.value.creator || isTemporary.value);

    const created = computed(() => getFormattedTimeByAge(message.value?.created));

    const text = computed(() => message.value.text);

    if (!message.value.read) {
      markAsRead(message.value);
    }

    const edit = () => {
      const { messageId } = message.value;
      editMessageId.value = messageId === editMessageId.value ? '' : messageId;
    };

    const showDownVote = computed(() => chat.value.type === CHAT_TYPE_FORUM || chat.value.type === CHAT_TYPE_PROJECT);

    const isBeingSent = computed(() => sendingMessageId.value === message.value.messageId);

    const isBeingEdited = computed(() => editMessageId.value === message.value.messageId && !isBeingSent.value);

    const isEdited = computed(() => message.value.edited);

    const creatorLabel = computed(() => {
      const truncate = (str:string) => (str.length > 18 ? `${str.substring(0, 16)}..` : str);
      return (isMe.value ? i18n.t('messenger.chat.me') : truncate(chat.value?.privatePartner?.firstname ?? ''));
    });

    const canEdit = computed(() => isMe.value && !isTemporary.value && !isBeingSent.value);

    const canFlag = computed(() => !isMe.value);

    const getFailedText = () => {
      eventBus.emit(MESSAGE_EVENT_SET_TEXT, message.value?.text ?? '');
    };

    return {
      canEdit,
      canFlag,
      isBeingEdited,
      isEdited,
      isMe,
      creatorLabel,
      user,
      created,
      text,
      showDownVote,
      edit,
      isTemporary,
      isBeingSent,
      getFailedText
    };
  },
});
