import axios from '@/plugins/axios';
import { autoEncodeUserId } from '@/utils/userIdEncoding';

interface VerifyCodeResponse {
  mobilephone: string;
}

export default {
  async verifyCode(
    code: string,
    userId: string,
  ): Promise<VerifyCodeResponse> {
    const response = await axios.post('/v1/users/user/password/reset/verify', {
      code,
      userId: autoEncodeUserId(userId)
    });

    return response.data;
  },

  async resetPassword(
    email: string,
  ): Promise<any> {
    return axios.post('/v1/users/user/password/reset', { email });
  },

  async setPassword(
    smsCode: string,
    userId: string,
    newPassword: string,
  ): Promise<any> {
    return axios.post('/v1/users/user/password/reset/set-password', {
      smsCode,
      userId: autoEncodeUserId(userId),
      newPassword,
    });
  },

  async resendEmail(
    email: string,
  ): Promise<any> {
    return axios.post('/v1/users/user/password/reset/resend-email', { email });
  },

  async resendSmsCode(
    code: string,
    userId: string,
  ): Promise<any> {
    return axios.post('/v1/users/user/password/reset/resend-sms', {
      code,
      userId: autoEncodeUserId(userId),
    });
  },

};
