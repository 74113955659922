
import {
  computed,
  defineComponent, PropType, reactive, ref, toRefs, watch
} from 'vue';
import LRPopup from '@/components/login-register/LRPopup.vue';
import { useVModel } from '@vueuse/core';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXRadio from '@/components/molecules/forms/BCXRadio.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import useVuelidate from '@vuelidate/core';
import useBCValidators from '@/mixins/useBCValidators';
import RegisterService from '@/services/RegistrationService';
import useTriggerLegalTextsPopup from '@/mixins/useTriggerLegalTextsPopup';

export default defineComponent({
  name: 'InviteCodePopup',
  components: {
    BCXValidatedInput,
    BCXRadio,
    BCXMarkdown,
    LRPopup
  },
  props: {
    value: {
      type: Boolean
    },
    name: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    type: {
      type: String as PropType<'freelancer' | 'customer'>,
      default: 'freelancer'
    }
  },
  setup(props) {
    const {
      name,
      email, type
    } = toRefs(props);
    const vModel = useVModel(props);
    const { setLegalText } = useTriggerLegalTextsPopup();
    const participation = ref('betatest'); // Diese Zeile wurde geändert
    const registered = ref(false);

    const isBetaTest = computed(() => participation.value === 'betatest');

    const fields = reactive({
      name: '',
      email: '',
      moreInfo: '',
      phone: '',
      company: ''
    });

    watch(name, (name: string) => {
      fields.name = name;
    }, { immediate: true });
    watch(email, (email: string) => {
      fields.email = email;
    }, { immediate: true });

    watch(vModel, (is) => {
      if (!is) registered.value = false;
    });

    const {
      emailValidation,
      emailOptionalValidation,
      required,
      requiredPolite,
      urlValidation
    } = useBCValidators();

    const rules = computed(() => {
      if (!isBetaTest.value) {
        return {
          name: { required },
          email: emailValidation,
        };
      }
      if (type.value === 'freelancer') {
        return {
          name: { required },
          email: emailValidation,
          moreInfo: urlValidation
        };
      }
      if (type.value === 'customer') {
        return {
          name: { requiredPolite },
          phone: { requiredPolite },
          company: { requiredPolite },
          email: emailOptionalValidation,
        };
      }
      return null;
    });

    const v = useVuelidate(rules, fields, { $stopPropagation: true });

    const isSending = ref(false);

    const resetFields = () => {
      fields.name = '';
      fields.email = '';
      fields.moreInfo = '';
      fields.phone = '';
      fields.company = '';
    };

    const preRegister = async () => {
      const validated = await v.value.$validate();

      if (validated && !isSending.value) {
        isSending.value = true;

        const {
          email,
          name: fullname,
          moreInfo,
          phone,
          company
        } = fields;

        const url = (type.value === 'customer') ? `KUNDE # ${company} # ${phone}` : moreInfo;

        const requestData = {
          isBetaTest: isBetaTest.value,
          ...isBetaTest.value && { url },
          email,
          fullname,
        };

        await RegisterService.preRegister(requestData).then(() => {
          resetFields();
        }).finally(() => {
          isSending.value = false;
        });

        registered.value = true;
      }
    };

    const currentFormIsValid = computed(() => v.value.$valid);

    const showPrivacy = () => {
      setLegalText('privacy');
    };

    return {
      vModel,
      participation,
      fields,
      v,
      currentFormIsValid,
      preRegister,
      registered,
      isSending,
      showPrivacy
    };
  }
});
