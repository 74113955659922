
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import Vue from 'vue';
import { RawLocation } from 'vue-router';
import { mapGetters } from 'vuex';
import useI18n from '@/mixins/useI18n';
import { truncate } from 'lodash';
import useQuickInfo from '@/state/quickInfo';

export default Vue.extend({
  props: {
    forumItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters('page', ['isMobileLayout']),
    ...mapGetters('userStates', ['getUserInfo']),
    forumLink(): RawLocation {
      return { name: 'threads', params: { forumId: this.forumItem.forumId, groupId: this.forumItem.groupId } };
    },
    latestThreadTitle(): string {
      if (this.forumItem.forumThreadGroupMetaData.latestAddedThreadId) {
        return truncate(this.forumItem.forumThreadGroupMetaData.latestAddedThreadTitle, { length: (this.isMobileLayout ? 58 : 53) });
      }
      return '';
    },
    latestThreadTimestamp(): string {
      if (this.forumItem.forumThreadGroupMetaData.latestAddedThreadId) {
        return this.formatDate(this.forumItem.forumThreadGroupMetaData.latestAddedThreadTs);
      }
      return '';
    },
    latestThreadCreator(): string {
      if (this.forumItem.forumThreadGroupMetaData.latestAddedThreadId) {
        return this.forumItem.forumThreadGroupMetaData.latestAddedThreadCreatorFullname;
      }
      return '';
    },
    linkToLatestThread(): RawLocation {
      return {
        name: 'thread',
        params: {
          forumId: this.forumItem.forumId,
          groupId: this.forumItem.groupId,
          threadId: this.forumItem.forumThreadGroupMetaData.latestAddedThreadId
        }
      };
    },
    numberOfThreadsLabel(): string {
      if (this.forumItem.forumThreadGroupMetaData.numberOfThreads === 1) {
        return this.$t('forum.labels.threads.singular') as string;
      }
      return this.$t('forum.labels.threads.plural') as string;
    }
  },
  methods: {
    formatDate(timestamp: string): string {
      const { getFormattedTimeByAge } = useFormattedDateTime();
      return getFormattedTimeByAge(timestamp);
    },
    showUserQuickInfoCreator(ev: Event) {
      const { setQuickInfo, quickInfoShowContactHistory, quickInfoShowBadgesLegend } = useQuickInfo();
      quickInfoShowContactHistory.value = false;
      quickInfoShowBadgesLegend.value = false;
      const el = ev.target as HTMLElement;
      const user = {
        userId: this.forumItem.forumThreadGroupMetaData.latestAddedThreadCreatorUserId,
        fullname: this.forumItem.forumThreadGroupMetaData.latestAddedThreadCreatorFullname,
      };
      const { left, top } = el.getBoundingClientRect();
      setQuickInfo(this.getUserInfo(user.userId), left, top + 50);
    },
  },
});
