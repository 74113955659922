
import {
  computed, defineComponent, PropType, ref
} from 'vue';
import { templateRef, whenever } from '@vueuse/core';
import useResponsiveness from '@/mixins/useResponsiveness';
import { is } from '@babel/types';
import useI18n from '@/mixins/useI18n';

export default defineComponent({
  name: 'LPExplainVideo',
  props: {
    type: {
      type: String as PropType<'freelancer' | 'customer'>,
      default: 'freelancer'
    }
  },
  setup(props) {
    const { t } = useI18n();
    const isVideoPlaying = ref(false);
    const showControls = ref(false);
    const videoElement = templateRef<HTMLVideoElement | null>('videoElement');
    const { isLandingPageMobileWidth } = useResponsiveness();

    const videoElementClick = () => {
      const ve = videoElement.value;
      if (!ve) return;

      showControls.value = true;

      if (!ve.paused && ve.ended) {
        ve.currentTime = 0;
      }

      if (ve.paused) {
        if (isLandingPageMobileWidth.value) {
          ve.play();
        }
        isVideoPlaying.value = true;
        ve.addEventListener('ended', () => {
          showControls.value = false;
          isVideoPlaying.value = false;
        });
      }
    };

    whenever(videoElement, (videoElement) => {
      videoElement.volume = 0.5;
    });

    const videoBaseFileName = computed(() => (props.type === 'freelancer' ? 'Explain_ByteCookie' : 'Explain_ByteCookie_Customer_DE'));

    const headline = computed(() => t(
      props.type === 'customer' ? 'landingpage-customer.video.headline' : 'landingpage-freelancer.video.headline'
    ));

    const subline = computed(() => t(
      props.type === 'customer' ? 'landingpage-customer.video.subline' : 'landingpage-freelancer.video.subline'
    ));

    return {
      isVideoPlaying, showControls, videoElementClick, videoBaseFileName, headline, subline
    };
  },
  methods: { is }
});
