import { BlogEntry, BlogForm } from './Blog';

const localeMap = {
  de: 'de-DE',
  en: 'en-US'
} as {
  [key: string]: string;
};

export const emptyBlogEntry: BlogEntry = {
  status: '',
  title: '',
  type: 'BC-News',
  summary: '',
  content: '',
  fileName: '',
  fileContents: '',
  position: 0,
  titleImage: '',
  creator: {
    userId: '',
    firstname: '',
    lastname: '',
    fullname: ''
  },
  publishDate: '',
  forumId: '',
  groupId: '',
  threadId: '',
  numberOfViews: 0,
  metaDataVoting: {
    upvoteCount: 0,
    downvoteCount: 0,
    voteResult: 0,
  },
  lastUpdated: '',
  userVoted: '',
  tagAssignmentList: []
};

export const emptyBlogForm: BlogForm = {
  type: 'ARTICLE',
  status: 'DRAFT',
  title: '',
  summary: '',
  content: '',
  position: 0,
  titleImage: '',
  fileName: '',
  fileContents: '',
  lastUpdated: '',
  creator: {
    fullname: ''
  },
  tagAssignmentList: []
};

export function getEmptyBlogForm(): BlogForm {
  return emptyBlogForm;
}
export function getEmptyBlogEntry(): BlogEntry {
  return emptyBlogEntry;
}

// export function getFormattedDateTime(
//   date?: string,
//   options: Record<string, any> = {
//     hour: '2-digit',
//     minute: '2-digit'
//   }
// ): string {
//   const dateObject = date ? new Date(date) : new Date();
//   return `${dateObject.toLocaleDateString('de-DE', { day: '2-digit', month: 'short' })} ${dateObject.toLocaleTimeString(localeMap['de-DE'], options)}`;
// }
