
import RecommendLayer from '@/views/profile/components/RecommendLayer.vue';
import { defineComponent } from 'vue';
import { useVModel } from '@vueuse/core';

export default defineComponent({
  name: 'RecommendMobileLayer',
  components: { RecommendLayer },
  props: {
    isOpened: Boolean
  },
  setup(props) {
    const isOpenedVM = useVModel(props, 'isOpened');

    const onClose = () => {
      isOpenedVM.value = false;
    };

    return {
      onClose
    };
  }
});
