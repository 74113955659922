
import { SkillListItem } from '@/models/Profile';
import { Tag } from '@/models/Tags';
import {
  computed, defineComponent, PropType, reactive
} from 'vue';
import { orderBy } from 'lodash';

export default defineComponent({
  props: {
    single: {
      type: Boolean,
      default: true
    },
    tags: {
      type: Array as () => Tag[],
      required: false,
      default: () => []
    },
    skills: {
      type: Array as () => SkillListItem[],
      required: false,
      default: () => []
    },
    positivesOnly: {
      type: Boolean,
      required: false,
      default: true
    },
    roleLimit: {
      type: Number,
      required: false,
      default: 3
    },
    skillLimit: {
      type: Number,
      required: false,
      default: 5
    },
    type: {
      type: String as PropType<'ROLE' | 'SKILL'>
    }
  },
  setup(props) {
    const tagsFiltered = props.tags && (reactive<Tag[]>(props.positivesOnly ? props.tags.filter((tag) => tag.voteResult > 0) : props.tags));
    const tagsSorted = computed<Tag[]>(() => orderBy(tagsFiltered, ['voteResult', 'tagName'], 'desc'));
    const roleTags = computed(() => tagsSorted.value.filter((tag) => tag.tagType === 'ROLE').slice(0, props.roleLimit));
    const skillTags = computed(() => tagsSorted.value.filter((tag) => tag.tagType === 'TAG').slice(0, props.skillLimit));
    return {
      roleTags,
      skillTags,
    };
  }
});
