import { createGlobalState } from '@vueuse/core';
import {
  SearchAttribute,
  SearchAvailability,
  SearchAvailabilityStart,
  SearchAvailabilityWithin,
  SearchCountry,
  SearchCriteriumType, SearchQueryParam,
  UserSearchEntry,
  UserSearchField,
  UserSearchRequest
} from '@/views/user-search/models/UserSearch';
import {
  computed, reactive, ref, watch
} from 'vue';
import userSearchService from '@/services/UserSearchService';
import useI18n from '@/mixins/useI18n';
import { BCXExtendedDropdownItem } from '@/components/molecules/forms/BCXMultiselectSection.vue';
import mapObject from '@/utils/mapObject';
import { SelectItem } from '@/models/SelectItem';
import { Route } from 'vue-router';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { isEqual } from 'lodash';
import hasArrayEqualContents from '@/utils/hasArrayEqualContents';

const searchCriteriaItems = {
  [SearchCriteriumType.Attributes]: [
    SearchAttribute.Name,
    SearchAttribute.Location,
    SearchAttribute.Description,
    SearchAttribute.SkillsAndRoles,
    SearchAttribute.Experiences,
    SearchAttribute.PDFProfile,
  ],
  [SearchCriteriumType.Availability]: [
    SearchAvailability.Available,
    SearchAvailability.Partially,
    SearchAvailability.Unavailable,
  ],
  [SearchCriteriumType.AvailabilityStart]: [
    SearchAvailabilityStart.Immediately,
    SearchAvailabilityStart.Later
  ],
  [SearchCriteriumType.Countries]: [
    SearchCountry.Germany,
    SearchCountry.Austria,
    SearchCountry.Switzerland
  ]
};

const availabilityWithinItems: SearchAvailabilityWithin[] = [
  'ALL',
  'IMMEDIATELY',
  'W1', 'W2', 'W4', 'M2', 'M3'
];

const WITHIN_UNIT_LENGHTS: Record<string, number> = {
  D: 86400000,
  W: 86400000 * 7,
  M: 86400000 * 30
};

const useUserSearch = createGlobalState(() => {
  const userList = ref<UserSearchEntry[]>([]);
  const actualResultSize = ref<number>(0);
  const searchTerm = ref('');
  const excludeSearchTerm = ref('');
  const isLoading = ref(false);
  const isSimple = ref(true);
  const showTags = ref(true);
  const hasSearched = ref(false);
  const router = useRouter();
  const route = useRoute();
  const isError = ref(false);
  const { t } = useI18n();

  const initialFilters = {
    attributes: Object.values(SearchAttribute).filter((value) => value !== SearchAttribute.PDFProfile),
    availabilities: Object.values(SearchAvailability),
    availabilityWithin: 'ALL' as SearchAvailabilityWithin,
    countries: Object.values(SearchCountry),
  };

  const filters = reactive({ ...initialFilters });

  const resetFilters = () => {
    Object.assign(filters, initialFilters);
  };

  const resetEverything = () => {
    resetFilters();
    searchTerm.value = '';
    excludeSearchTerm.value = '';
  };

  const getSearchFieldsForRequest = () => {
    const requestFields: UserSearchField[] = [];
    filters.attributes.forEach((attribute) => {
      if (attribute === SearchAttribute.Name) requestFields.push('NAME');
      else if (attribute === SearchAttribute.Location) {
        requestFields.push('CITY');
        requestFields.push('COUNTRY');
      } else if (attribute === SearchAttribute.Description) requestFields.push('SUMMARY');
      else if (attribute === SearchAttribute.SkillsAndRoles) {
        requestFields.push('SKILLS');
        requestFields.push('ROLES');
      } else if (attribute === SearchAttribute.Experiences) requestFields.push('EXPERIENCES');
      else if (attribute === SearchAttribute.PDFProfile) requestFields.push('EXPERIENCES_DOC');
    });
    return requestFields;
  };

  const getRequest = () => {
    const { countries, availabilities } = filters;
    const fields = getSearchFieldsForRequest();

    const request: UserSearchRequest = {
      simple: {
        term: searchTerm.value,
        fields,
      },
    };

    if (!isSimple.value) {
      if (excludeSearchTerm.value) {
        request.simple.excludeTerm = excludeSearchTerm.value;
      }
      if (availabilities.length || countries.length) {
        const requestFilters: UserSearchRequest['filters'] = {
          availability: {
            states: availabilities,
          }
        };
        // If all countries are selected, omit country filter completely:
        if (countries.length < Object.keys(SearchCountry).length) {
          requestFilters.countryCodes = countries;
        }
        let timeDiff = -1;
        if (filters.availabilityWithin === 'IMMEDIATELY') {
          timeDiff = 0;
        } else {
          const match = filters.availabilityWithin.match(/(\w+)(\d+)/);
          if (match) {
            const [, unit, length] = match;
            timeDiff = WITHIN_UNIT_LENGHTS[unit] * +length;
          }
        }
        if (timeDiff >= 0) {
          requestFilters.availability.from = new Date(
            new Date().getTime() + timeDiff
          ).toISOString().substring(0, 10);
        }
        request.filters = requestFilters;
      }
    }

    return request;
  };

  const setFiltersFromQuery = () => {
    const query = route.query as Record<SearchQueryParam, string>;
    resetFilters();
    searchTerm.value = query[SearchQueryParam.SearchTerm] ?? '';
    excludeSearchTerm.value = query[SearchQueryParam.ExcludeSearchTerm] ?? '';

    const attributes = query[SearchQueryParam.Attributes];
    if (attributes) filters.attributes = attributes.split(',') as SearchAttribute[];

    const countries = query[SearchQueryParam.Countries];
    if (countries) filters.countries = countries.split(',') as SearchCountry[];

    const availabilities = query[SearchQueryParam.Availability];
    if (availabilities) filters.availabilities = availabilities.split(',') as SearchAvailability[];

    const availabilitiesWithin = query[SearchQueryParam.AvailabilityWithin];
    if (availabilitiesWithin) filters.availabilityWithin = availabilitiesWithin as SearchAvailabilityWithin;

    if (countries || availabilities || availabilitiesWithin || excludeSearchTerm.value) isSimple.value = false;
  };

  const searchQuery = computed(() => {
    const {
      attributes, countries, availabilities, availabilityWithin
    } = filters;
    const query:Route['query'] = {
      q: searchTerm.value,
    };
    if (excludeSearchTerm.value) {
      query.x = excludeSearchTerm.value;
    }
    if (!hasArrayEqualContents(attributes, initialFilters.attributes)) {
      query.f = attributes.join(',');
    }
    if (!isSimple.value) {
      if (countries.length < initialFilters.countries.length) {
        query.c = countries.join(',');
      }
      if (availabilities.length < initialFilters.availabilities.length) {
        query.a = availabilities.join(',');
      }
      if (availabilityWithin !== initialFilters.availabilityWithin) {
        query.aw = availabilityWithin;
      }
    }
    return query;
  });

  const hasSearchQueryChanged = computed(() => !isEqual(searchQuery.value, route.query));

  const isSearchDisabled = computed(() => {
    if (isError.value) return false;
    if (!hasSearchQueryChanged.value) return true;
    if (isSimple.value) return (searchTerm.value.length < 2) || !filters.attributes?.length;
    return (searchTerm.value.length < 2
        && excludeSearchTerm.value.length < 2)
      || !filters.availabilities?.length
      || !filters.countries?.length
      || !filters.attributes?.length;
  });

  const loadUsers = async () => {
    isLoading.value = true;
    hasSearched.value = true;
    isError.value = false;

    const result = await userSearchService.searchUsers(getRequest()).catch(() => {
      isError.value = true;
    });

    if (result) {
      userList.value = (result?.entries ?? []);
      actualResultSize.value = result.globalResultSize;
    } else {
      userList.value = [];
      actualResultSize.value = 0;
    }

    // Fake > 25 entries:
    // if (userList.value.length) {
    //   let i = 30;
    //   while (i--) {
    //     userList.value.push(userList.value[0]);
    //   }
    // }

    isLoading.value = false;
  };

  const search = async () => {
    if (isSearchDisabled.value) return;

    if (isError.value) {
      await loadUsers();
    }

    await router.push({
      query: searchQuery.value
    }).catch(() => {
      // redundant navigation nonsense
    });
  };

  const itemsToSelectItems = (items: string[], section: string):BCXExtendedDropdownItem[] => items.map((item) => ({
    label: `${t(`user-search.filters.${section}.${item}`)}`,
    value: item
  }));

  const selectItems = computed(() => mapObject(searchCriteriaItems, (items, name) => itemsToSelectItems(items, name)));

  const availabilityWithinList = computed(() => availabilityWithinItems.map((item) => ({
    text: `${t(`user-search.filters.within.${item}`)}`,
    value: item
  })) as SelectItem[]);

  const resetSearch = () => {
    hasSearched.value = false;
    actualResultSize.value = 0;
    userList.value = [];
  };

  watch(() => route.query, async () => {
    setFiltersFromQuery();
    if (searchTerm.value?.length >= 2) await loadUsers();
    else resetSearch();
  }, { immediate: true, deep: true });

  return {
    searchTerm,
    excludeSearchTerm,
    userList,
    isLoading,
    isSimple,
    isSearchDisabled,
    showTags,
    loadUsers,
    search,
    searchCriteriaItems,
    filters,
    resetFilters,
    resetEverything,
    selectItems,
    initialFilters,
    availabilityWithinList,
    actualResultSize,
    hasSearched,
    resetSearch,
    isError,
  };
});

export default useUserSearch;
