
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import { RawLocation } from 'vue-router';
import { mapGetters } from 'vuex';
import { Tag } from '@/models/Tags';
import TagDisplay from '@/components/tagging/TagDisplay.vue';
import ProjectListItemOpenState from '@/views/project-forum/components/ProjectListItemOpenState.vue';
import { defineComponent, PropType } from 'vue';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';

export default defineComponent({
  components: { ProjectListItemOpenState, BCXAvatar, TagDisplay },
  props: {
    project: {
      type: Object as PropType<ProjectListProject>,
      required: true,
      default() {
        return {};
      },
    },
    showTags: {
      type: Boolean,
      required: true,
      default: true,
    },
    statusOnTop: Boolean,
    isNarrow: Boolean,
    hideForumDates: Boolean
  },
  data() {
    return {
      projectStatus: Math.floor(Math.random() * 3),
      createdLatestHasTwoRows: false,
    };
  },
  computed: {
    ...mapGetters('page', ['isMobileLayout']),
    ...mapGetters('forum', ['getForumId', 'getGroupId']),
    displayAsNarrow(): boolean {
      return this.isMobileLayout || this.isNarrow;
    },
    creationDateFormatted(): string {
      return this.formatDate(this.project.created ?? '');
    },
    latestReplyDateFormatted(): string|undefined {
      return this.formatDate(this.project.latestAddedReply ?? '');
    },
    numberOfReplies(): number {
      return this.project.numberOfReplies || 0;
    },
    repliesLabel(): string {
      if (this.numberOfReplies === 1) {
        return this.$t('project-forum.overview.replies.singular') as string;
      }
      return this.$t('project-forum.overview.replies.plural') as string;
    },
    viewsLabel(): string {
      if (this.numberOfViews === 1) {
        return this.$t('project-forum.overview.views.singular') as string;
      }
      return this.$t('project-forum.overview.views.plural') as string;
    },
    hasReplies(): boolean {
      return this.numberOfReplies > 0;
    },
    numberOfViews(): number {
      return this.project.numberOfViews || 0;
    },
    voteResult(): number {
      return this.project.voteResult ?? 0;
    },
    startDateFormatted(): string {
      return this.formatDate(this.project.startTime ?? '', 'short');
    },
    projectDurationText(): string {
      const projectDurationUnitText = this.$t(`project-forum.form-section.conditions.duration.dropdown.${this.project.durationUnit}`) as string;
      return `${this.project.durationAmount} ${projectDurationUnitText}`;
    },
    projectLocationText(): string {
      return this.$t(`project-forum.form-section.conditions.location.values.${this.project.location}`) as string;
    },

    projectRoleTags(): Tag[] {
      return (this.project.tagAssignmentList as Tag []).filter((tag: Tag) => tag.tagType === 'ROLE');
    },
    projectTags(): Tag[] {
      return (this.project.tagAssignmentList as Tag[]).filter((tag: Tag) => tag.tagType === 'TAG');
    },
    projectTitleText(): string {
      if (this.project.metaDataProject.testFlag) {
        return `[TEST] ${this.project.projectTitle}`;
      }
      return this.project.projectTitle ?? '';
    },
    routerLink(): RawLocation {
      return { name: 'projectForumThread', params: { projectId: this.project.externalId ?? '' } };
    },
  },
  mounted() {
    if (this.isMobileLayout) {
      this.$nextTick(() => {
        this.createdLatestHasTwoRows = this.checkCreatedLatestHasTwoRows();
        window.addEventListener('resize', () => {
          this.createdLatestHasTwoRows = this.checkCreatedLatestHasTwoRows();
        });
      });
    }
  },
  methods: {
    goToThread() {
      this.$router.push(this.routerLink);
    },
    checkCreatedLatestHasTwoRows(): boolean {
      const createdLatestElement = this.$refs.createdLatest as Element;
      if (createdLatestElement) {
        const createdElement = createdLatestElement.querySelector('.created-on') as HTMLElement;
        const latestReplyElement = createdLatestElement.querySelector('.latest-reply') as HTMLElement;
        if (Math.ceil(createdElement.clientWidth) + Math.ceil(latestReplyElement.clientWidth) > Math.ceil(createdLatestElement.clientWidth)) {
          return true;
        }
      }
      return false;
    },
    formatDate(timestamp: string, dateformat: 'short'|'diff' = 'diff'): string {
      const { getFormattedTimeByAge, getFormattedDate } = useFormattedDateTime();
      if (dateformat === 'diff') return getFormattedTimeByAge(timestamp);
      return getFormattedDate(timestamp);
    },
  },
});
