
import X from '@/components/svg-components/X.vue';
import { InfoBoxType } from '@/models/InfoBox';
import RecommendToUser from '@/views/recommendations/components/recommend-to-user/RecommendToUser.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RecommendToUserLayer',
  components: {
    RecommendToUser,
    X
  },
  setup(_, { emit }) {
    const onClose = () => {
      emit('close');
    };

    const closeWithDelay = () => {
      setTimeout(onClose, 2000);
    };

    return {
      InfoBoxType,
      onClose,
      closeWithDelay
    };
  }
});
