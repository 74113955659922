
import SidebarContent from '@/components/SidebarContent.vue';
import { InfoBoxType } from '@/models/InfoBox';
import ProfileSidebarContent from '@/views/profile/components/sidebar/RecommendSidebarContainer.vue';
import ProfileSidebarTitle from '@/views/profile/components/sidebar/ProfileSidebarTitle.vue';
import useRecommendations from '@/views/profile/composables/useRecommendations';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ProfileSidebar',
  components: {
    ProfileSidebarTitle,
    SidebarContent,
    ProfileSidebarContent
  },
  props: {
    inBox: {
      type: Boolean
    }
  },
  setup() {
    const isOpened = ref(true);
    const { availability, sidebarType, user } = useRecommendations();

    const userName = computed(() => user.value?.firstname ?? '');

    return {
      isOpened,
      user,
      availability,
      InfoBoxType,
      sidebarType,
      userName
    };
  }
});
