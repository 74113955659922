
import { ForumMaxChars } from '@/views/forum/models/Forum';
import Vue from 'vue';

export default Vue.extend({
  name: 'ProjectTitle',
  props: {
    titleValue: {
      type: String,
      default: undefined,
    },
    isCreatingProject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: '',
      maxLength: ForumMaxChars.PROJECT_TITLE
    };
  },
  computed: {
    charactersLeft(): number {
      return this.maxLength - this.title.length;
    },
  },
  watch: {
    title(value) {
      this.title = value.replace(/[^\u00C0-\u017FA-Za-z\d \-.,@$€%=?!()[\]/]+/, '');
      this.$emit('input', value);
    },
  },
  mounted() {
    if (this.titleValue) {
      this.title = this.titleValue;
    }
  },
  methods: {
    onKeyDown(event: any) {
      this.$emit('keydown', event);
    },
  },
});
