import axios from '@/plugins/axios';
import { Notifications, NotificationType, NotificationTypeCounts } from '@/models/Notification';
import { cloneDeep, merge } from 'lodash';

const FAKE_NOTIFICATION_RESULT = false;

const emptyNotificationObjectForSorting:Notifications = {
  [NotificationType.Messages]: {
    [NotificationTypeCounts.DirectMessage]: 0,
    [NotificationTypeCounts.ProjectMessage]: 0,
    [NotificationTypeCounts.ForumMessage]: 0,
    sum: 0
  },
  [NotificationType.Projects]: {
    [NotificationTypeCounts.NewProjects]: 0,
    [NotificationTypeCounts.NewProjectComments]: 0,
    [NotificationTypeCounts.NewProjectChanges]: 0,
    [NotificationTypeCounts.NewRecommendations]: 0,
    [NotificationTypeCounts.NewRecommendationFeedbacks]: 0,

    sum: 0
  },
  [NotificationType.Talks]: {
    [NotificationTypeCounts.NewTalks]: 0,
    [NotificationTypeCounts.NewTalkComments]: 0,
    sum: 0
  }
};

const FAKE_NOTIFICATION_DATA:Notifications = {
  [NotificationType.Messages]: {
    [NotificationTypeCounts.DirectMessage]: 5,
    [NotificationTypeCounts.ForumMessage]: 3,
    [NotificationTypeCounts.ProjectMessage]: 2,
    sum: 10
  },
  [NotificationType.Projects]: {
    [NotificationTypeCounts.NewProjectChanges]: 2,
    [NotificationTypeCounts.NewProjectComments]: 3,
    [NotificationTypeCounts.NewProjects]: 3,
    [NotificationTypeCounts.NewRecommendations]: 0,
    [NotificationTypeCounts.NewRecommendationFeedbacks]: 0,
    sum: 8
  },
  [NotificationType.Talks]: {
    [NotificationTypeCounts.NewTalkComments]: 3,
    [NotificationTypeCounts.NewTalks]: 2,
    sum: 5
  }
};

// eslint-disable-next-line import/prefer-default-export
export async function getAllNotifications(): Promise<Notifications | null> {
  const targetObject = cloneDeep<Notifications>(emptyNotificationObjectForSorting);
  let data:Notifications | null;
  if (FAKE_NOTIFICATION_RESULT) data = FAKE_NOTIFICATION_DATA;
  else data = (await axios.get('/v1/notifications/widget/overview').catch(() => null))?.data ?? null;

  if (!data) return null;

  // sort the data according to empty Object:
  return merge<Notifications, Notifications>(targetObject, data);
}

export async function clearNotification(type: NotificationTypeCounts | '') {
  const translation: Partial<Record<NotificationTypeCounts, string>> = {
    [NotificationTypeCounts.ProjectMessage]: 'MESSAGING_PROJECT',
    [NotificationTypeCounts.DirectMessage]: 'MESSAGING_PRIVATE',
    [NotificationTypeCounts.ForumMessage]: 'MESSAGING_TALK',

    [NotificationTypeCounts.NewTalks]: 'SUBSCRIPTION_FORUMTHREAD_NEW',
    [NotificationTypeCounts.NewTalkComments]: 'SUBSCRIPTION_FORUMTHREAD_MESSAGE_NEW',

    [NotificationTypeCounts.NewProjects]: 'SUBSCRIPTION_PROJECTS_NEW',
    [NotificationTypeCounts.NewProjectComments]: 'SUBSCRIPTION_PROJECT_MESSAGE_NEW',
    [NotificationTypeCounts.NewProjectChanges]: 'SUBSCRIPTION_PROJECT_CHANGED',
    [NotificationTypeCounts.NewRecommendations]: 'RECOMMENDATIONS_NEW',
    [NotificationTypeCounts.NewRecommendationFeedbacks]: 'RECOMMENDATION_FEEDBACK_NEW',
  };

  return axios.post('/v1/notifications/widget/clear', { type: type ? translation[type] : 'ALL' });
}

export async function clearNotifications(which: NotificationType | ''): Promise<void> {
  const availableClearances: Partial<Record<NotificationType, NotificationTypeCounts[]>> = {
    [NotificationType.Talks]: [
      NotificationTypeCounts.NewTalkComments,
      NotificationTypeCounts.NewTalks
    ],
    [NotificationType.Messages]: [
      NotificationTypeCounts.DirectMessage,
      NotificationTypeCounts.ProjectMessage,
      NotificationTypeCounts.ForumMessage
    ],
    [NotificationType.Projects]: [
      NotificationTypeCounts.NewProjects,
      NotificationTypeCounts.NewProjectComments,
      NotificationTypeCounts.NewProjectChanges,
      NotificationTypeCounts.NewRecommendations,
      NotificationTypeCounts.NewRecommendationFeedbacks
    ],
  };

  if (!which) await clearNotification('');
  else {
    const clearances: NotificationTypeCounts[] = availableClearances?.[which] ?? [];
    if (!clearances.length) throw new Error(`NO CLEARANCES FOUND FOR ${which}!!!`);
    const clearancePromises = clearances.map((clearance) => clearNotification(clearance));
    await Promise.all(clearancePromises);
  }
}
