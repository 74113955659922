
import useResponsiveness from '@/mixins/useResponsiveness';
import { computed, defineComponent, toRefs } from 'vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import { SearchAvailability, UserSearchEntry } from '@/views/user-search/models/UserSearch';
import TagDisplay from '@/components/tagging/TagDisplay.vue';
import useUserSearch from '@/views/user-search/store/useUserSearch';
import { AvailabilityInterface } from '@/models/Availability';
import { SkillListItem } from '@/models/Profile';
import useAvailability from '@/mixins/useAvailability';
import { USER_TYPE_FAKE } from '@/models/Faking';
import { UserType } from '@/models/User';

export default defineComponent({
  name: 'UserSearchItem',
  components: {
    BCXUserBanner,
    TagDisplay,
  },
  props: {
    user: {
      type: Object as () => UserSearchEntry,
      required: true,
      default: () => ({}),
    },
    showTags: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const { user } = toRefs(props);
    const { isLoading } = useUserSearch();
    const { isMobileWidth } = useResponsiveness();

    const availability = computed(() => {
      const {
        availability, availableFrom, availableTo, availableHoursPerWeek
      } = user.value;
      const a: AvailabilityInterface = {
        available: availability !== SearchAvailability.Unavailable,
        availableFrom,
        availableTo,
        availableNow: !availableFrom || new Date(availableFrom).getTime() <= new Date().getTime(),
        availableFulltime: availability === SearchAvailability.Available,
        availableHoursPerWeek,
        overallStatusLabel: ''
      };
      return a;
    });

    const { mode: availabilityMode, when: availabilityWhen, label: availabilityLabel } = useAvailability(availability);

    const userLocation = computed(() => `${user.value.city}, ${user.value.country}`);
    const skills = computed<SkillListItem[]>(() => user.value?.skills?.map((skill, idx) => ({
      skillTagId: skill.skillId,
      skillTagName: skill.skillLabel,
      skillRoleTagId: skill.roleId,
      skillRoleTagName: skill.roleLabel,
      userSkillId: `${idx}`
    })) ?? []);

    const userType = computed(() => user.value?.type ?? USER_TYPE_FAKE);

    const isAvailabilityVisible = computed(() => !user.value || userType.value !== UserType.COMPANY);

    return {
      isMobileWidth,
      isLoading,
      skills,
      availabilityLabel,
      availabilityMode,
      availabilityWhen,
      userLocation,
      userType,
      isAvailabilityVisible,
      availability,
    };
  },
});
