
import useResponsiveness from '@/mixins/useResponsiveness';
import { defineComponent, PropType } from 'vue';
import { NotificationListItem, NotificationTypeCounts } from '@/models/Notification';
import useMessengerState from '@/state/messenger/messengerState';
import {
  CHAT_TYPE_DIRECT, CHAT_TYPE_FORUM, CHAT_TYPE_PROJECT, ChatFilter
} from '@/models/Messenger';
import useNotificationState from '@/state/notificationState';
import useRouterIngoreDuplicate from '@/mixins/useRouterIngoreDuplicate';

export default defineComponent({
  name: 'NotificationPanelItems',
  props: {
    items: { type: Array as PropType<NotificationListItem[]>, default: () => [] },
  },
  setup() {
    const {
      isOpened, isExpanded, groupFilters
    } = useMessengerState();
    const {
      isMobileWidth
    } = useResponsiveness();

    const { pushRoute } = useRouterIngoreDuplicate();

    const { resetInterval, showPanel } = useNotificationState();

    const gotoMessenger = async (type:string) => {
      // console.log('SetGroup called with', type);
      Object.assign(groupFilters, {
        all: false,
        [CHAT_TYPE_DIRECT]: type === CHAT_TYPE_DIRECT,
        [CHAT_TYPE_PROJECT]: type === CHAT_TYPE_PROJECT,
        [CHAT_TYPE_FORUM]: type === CHAT_TYPE_FORUM
      } as ChatFilter);
      isOpened.value = true;
      isExpanded.value = !isMobileWidth.value;
      resetInterval();
      showPanel.value = false;
    };

    const gotoSubscriptions = async (filter:string) => {
      await pushRoute({
        name: 'subscriptions',
        query: {
          filter
        },
      });
      showPanel.value = false;
    };

    const gotoRecommendations = async () => {
      await pushRoute({
        name: 'recommendations',
        query: {
        },
      });
      showPanel.value = false;
    };

    const gotoItem = async (itemName:NotificationTypeCounts) => {
      switch (itemName) {
        case NotificationTypeCounts.DirectMessage:
          await gotoMessenger(CHAT_TYPE_DIRECT);
          break;
        case NotificationTypeCounts.ForumMessage:
          await gotoMessenger(CHAT_TYPE_FORUM);
          break;
        case NotificationTypeCounts.ProjectMessage:
          await gotoMessenger(CHAT_TYPE_PROJECT);
          break;

        case NotificationTypeCounts.NewTalkComments:
        case NotificationTypeCounts.NewTalks:
          await gotoSubscriptions('TALKS');
          break;
        case NotificationTypeCounts.NewProjectChanges:
        case NotificationTypeCounts.NewProjects:
        case NotificationTypeCounts.NewProjectComments:
          await gotoSubscriptions('PROJECTS');
          break;
        case NotificationTypeCounts.NewRecommendations:
        case NotificationTypeCounts.NewRecommendationFeedbacks:
          await gotoRecommendations();
          break;
        default:
      }
    };

    return { gotoItem };
  }
});
