export interface ExternalUserAward {
    label: string;
}
export interface ExternalUserBanner {
    externalUserId?: any;
    firstname: string;
    lastname: string;
    isOnline: boolean;
    lastActive: string;
    cookies: number;
    reputationPoints?: number;
    awards: ExternalUserAward[];
}

export enum AvatarSize {
  S = 40,
  M = 50,
  L = 60,
}

export type AvatarSizes = keyof typeof AvatarSize
