import useWebAudioPlayer from '@/mixins/useWebAudioPlayer';
import {
  Chat,
  CHAT_TYPE_DIRECT,
  CHAT_TYPE_FORUM,
  CHAT_TYPE_PROJECT,
  ChatFilter,
  ChatMessage,
  ChatMessageType,
  ChatText,
  CLEAR_CHAT_MESSAGE_ID,
  MESSAGE_EVENT_REFRESH,
  NEW_CHAT_MESSAGE_ID,
  SendMessageResult
} from '@/models/Messenger';
import { User } from '@/models/User';
import { cleanPreviousNewChats, createNewChatFromUser, findExistingChatForUser } from '@/state/messenger/Chat';
import eventBus from '@/state/messenger/EventBus';
import getLatestChatFromGroups from '@/state/messenger/getLatestChatFromGroups';
import {
  editMessage as apiEditMessage,
  getAllChats,
  invalidateMessage,
  markAsRead as apiMarkAsRead,
  removeChats,
  sendMessage as apiSendMessage
} from '@/state/messenger/Network';
import { traverseMessagesInChats, traverseMessagesInGroups } from '@/state/messenger/traverseHelpers';
import useFilter from '@/state/messenger/useFilter';
import useGrouper from '@/state/messenger/useGrouper';
import { getChatUnreadMessageCount, getGroupsUnreadMessageCount, getLatestUnreadChat } from '@/state/messenger/useGroupsUnreadMessages';
import useSorter from '@/state/messenger/useSorter';
import asyncWait from '@/utils/asyncWait';
import {
  computed, ComputedRef, nextTick, reactive, Ref, ref, watch
} from 'vue';
import {
  createGlobalState, useDocumentVisibility, useIntervalFn, useLocalStorage
} from '@vueuse/core';
import { debounce } from 'lodash';
import { REFRESH_INTERVAL_MESSENGER_BEGINNING } from '@/models/IntervalTimes';

const useMessengerState = createGlobalState(() => {
  const isOpened = ref<boolean>(false);
  const isExpanded = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const isLoadingFirstTime = ref<boolean>(true);
  const searchTerm = ref<string>('');
  const showNewFilter = ref<boolean>(false);
  const showSortPanel = ref<boolean>(false);
  const showOptionsPanel = ref<boolean>(false);
  const sortBy = useLocalStorage('chat-sort-by', 'time');
  const sortDir = useLocalStorage('chat-sort-direction', 'descending');
  const isNewChat = ref<boolean>(false);
  const isSoundEnabled = useLocalStorage<boolean>('soundEnabled', true);
  const isSoundTemporarilyMuted = ref<boolean>(false);
  const sendTestTone = ref<boolean>(false);
  const isFlat = useLocalStorage('chat-flatmode', true);
  const groupFilters = reactive<ChatFilter>({
    all: true,
    [CHAT_TYPE_DIRECT]: false,
    [CHAT_TYPE_FORUM]: false,
    [CHAT_TYPE_PROJECT]: false
  });
  const selectedChatId = ref<string>('');
  const editMessageId = ref<string>('');
  const sendingMessageId = ref('');
  const openedGroupIDs = reactive({
    openedIds: []
  }) as {
    openedIds: string[];
  };
  const isForumGroupOpened = ref(true);
  const isProjectGroupOpened = ref(true);
  const chatTexts = ref([]) as Ref<ChatText[]>;
  const chats = ref<Chat[]>([]);
  const isWaitingForData = ref(true);

  const isInputFocussed = ref<boolean>(false);
  const isInputMaximized = ref<boolean>(false);
  const isMarkingChats = ref<boolean>(false);
  const markedChats = ref<string[]>([]);

  const isOpenedGroupSelectedOnStartup = ref<boolean>(false);

  const isAnythingInsideSidebarBox = ref<boolean>(false);

  const documentVisibility = useDocumentVisibility();

  const {
    resume: resumeMetaPolling,
    pause: pauseMetaPolling
  } = useIntervalFn(() => {
    eventBus.emit(MESSAGE_EVENT_REFRESH);
  }, REFRESH_INTERVAL_MESSENGER_BEGINNING, {
    immediate: false
  });

  watch(isMarkingChats, (is) => {
    if (!is) markedChats.value = [];
  });

  watch(selectedChatId, (chatId) => {
    if (chatId) {
      editMessageId.value = '';
      // changing chat will start an extra interval for meta polling and make an initial refresh
      eventBus.emit(MESSAGE_EVENT_REFRESH);
      resumeMetaPolling();
    } else {
      pauseMetaPolling();
    }
  });

  // User leaves or enters tab => no more regular polling ever again (only via overview request then).
  watch(documentVisibility, (visibility) => {
    if (visibility === 'hidden') pauseMetaPolling();
    else if (selectedChatId.value) resumeMetaPolling();
  });

  const getChatById = (chatId: string) => chats.value.find(({ id }) => id === chatId);
  const getChatTextById = (chatId: string) => chatTexts.value.find(({ id }) => id === chatId);
  const getMessageById = (id: string): ChatMessage | null => {
    let foundMessage = null;
    traverseMessagesInChats(chats.value, (message) => {
      if (message.messageId === id) {
        foundMessage = message;
        return true;
      }
      return false;
    });
    return foundMessage;
  };

  // computed

  const selectedChat = computed(() => getChatById(selectedChatId.value)) as ComputedRef<Chat>;
  const selectedChatText = computed(() => getChatTextById(selectedChatId.value)?.text ?? '');

  const setSelectedChatText = (text: string) => {
    const chatText = getChatTextById(selectedChatId.value);
    if (chatText) {
      chatText.text = text;
    } else {
      chatTexts.value.push({
        text,
        id: selectedChatId.value
      });
    }
  };

  // GROUPING, FILTERING, SORTING

  const { groups } = useGrouper(chats);
  const { groupsFiltered, chatsFiltered } = useFilter(groups, chats, {
    showNew: showNewFilter,
    searchTerm,
    filters: groupFilters
  });
  const { groupsFilteredSorted, chatsSorted } = useSorter(groupsFiltered, chatsFiltered, {
    sortBy,
    sortDir
  });
  const unreadMessages = computed(() => getGroupsUnreadMessageCount(groups.value));
  const unfilteredUnreadMessages = computed(() => chats.value.reduce((prev, chat) => prev + getChatUnreadMessageCount(chat), 0));

  // Sound
  // eslint-disable-next-line global-require
  const newMessageUrl = require('@/assets/audio/newMessage.mp3');

  const { play: _playAudio, init: initAudio } = useWebAudioPlayer();

  const playAudio = async () => {
    if (isSoundEnabled.value && !isSoundTemporarilyMuted.value) await _playAudio();
  };

  initAudio(newMessageUrl).then();

  watch(sendTestTone, (is) => {
    if (is) playAudio();
    sendTestTone.value = false;
  });

  watch(unfilteredUnreadMessages, (cur, prev) => {
    if (cur > prev) playAudio();
  });
  // ACTIONS

  const loadChatsImmediately = async () => {
    const allChats = await getAllChats();
    if (allChats) {
      chats.value = allChats;
      isLoadingFirstTime.value = false;
    }
  };

  const loadChats = debounce(async () => {
    // prevent refreshing when currently writing a new message:
    if (selectedChatId.value !== NEW_CHAT_MESSAGE_ID && !isMarkingChats.value) {
      isLoading.value = true;
      await loadChatsImmediately();
      isLoading.value = false;
      isWaitingForData.value = false;
    }
  }, 50);

  const startNewChat = () => {
    isNewChat.value = true;
    if (!isOpened.value) isOpened.value = true;
  };

  const createNewChat = async (user: User, messageText = '') => {
    if (!chats.value?.length) {
      await loadChatsImmediately();
    }
    const existingChat = findExistingChatForUser(chats, user);
    if (!existingChat) {
      cleanPreviousNewChats(chats);
      chats.value.push(createNewChatFromUser(user));
      selectedChatId.value = NEW_CHAT_MESSAGE_ID;
    } else {
      selectedChatId.value = existingChat.id;
    }
    if (messageText) {
      const msgId = editMessageId.value;
      editMessageId.value = CLEAR_CHAT_MESSAGE_ID;
      await nextTick();
      editMessageId.value = msgId;
    }
    setSelectedChatText(messageText);
    isNewChat.value = false;
  };

  const selectChat = (chatId: string) => {
    if (chatId) {
      selectedChatId.value = chatId === selectedChatId.value && !isExpanded.value ? '' : chatId;
    } else if (!isExpanded.value) {
      selectedChatId.value = '';
    }
    isNewChat.value = false;
  };

  const openUnreadGroups = () => {
    traverseMessagesInGroups(groups.value, (message, chat, group) => {
      if (!message.read && group?.id && !openedGroupIDs.openedIds.includes(group.id)) {
        // console.log('Add to opened groups:', group.id);
        openedGroupIDs.openedIds.push(group.id);
      }
    });
  };

  // when chats are loaded for the first time, open all groups with unread messages:
  watch(chats, (cur, prev) => {
    if (!prev?.length) {
      nextTick(() => openUnreadGroups());
    }
  });

  // API Communication:

  const removeMarkedChats = async () => {
    await removeChats(markedChats.value);
    isMarkingChats.value = false;
    eventBus.emit(MESSAGE_EVENT_REFRESH);
  };

  const onAPIAction = async (apiFunction: () => Promise<any>, errorMessage: string) => {
    isWaitingForData.value = true;
    const result = await apiFunction();
    if (result) {
      nextTick(() => {
        eventBus.emit(MESSAGE_EVENT_REFRESH);
      });
    } else {
      throw new Error(errorMessage);
    }
    return result;
  };

  const sendMessage = async (text: string, messageType: ChatMessageType): Promise<SendMessageResult> => {
    if (selectedChat.value) {
      sendingMessageId.value = selectedChatId.value;
      const result = await apiSendMessage(selectedChat.value, text, messageType);
      sendingMessageId.value = '';
      if (result?.error) {
        switch (result?.error.code) {
          case SendMessageResult.PROJECT_CLOSED:
            return result?.error.code as SendMessageResult;
          default:
            return SendMessageResult.UNKNOWN;
        }
      }
      if (!result) return SendMessageResult.UNKNOWN;

      if (selectedChatId.value === NEW_CHAT_MESSAGE_ID) {
        await loadChatsImmediately();
        selectedChatId.value = result?.externalId ?? '';
      }

      await nextTick();
      eventBus.emit(MESSAGE_EVENT_REFRESH);

      return SendMessageResult.OK;
    }
    throw new Error('No chat selected!');
  };

  const editMessage = async (text: string) => {
    if (editMessageId.value) {
      sendingMessageId.value = editMessageId.value;
      const result = await apiEditMessage(text, selectedChat.value?.chatId ?? '', editMessageId.value, selectedChat.value.privatePartner.userId ?? '', selectedChat.value);

      if (!result) {
        return SendMessageResult.UNKNOWN;
      }

      await nextTick();
      eventBus.emit(MESSAGE_EVENT_REFRESH);
      sendingMessageId.value = '';

      return SendMessageResult.OK;
    } throw new Error('No message ID set!');
  };

  const markAsRead = async (message: ChatMessage) => {
    await asyncWait(1000);
    const oldRead = message.read;
    message.read = true;
    const result = await apiMarkAsRead(message.messageId);
    if (!result) {
      message.read = oldRead;
    } else {
      invalidateMessage(message.messageId);
    }
  };

  const watchIsExpandedToSelectNewestChat = () => {
    watch(isExpanded, (is) => {
      if (is && !selectedChatId.value && groupsFiltered) {
        const { latestChatId, latestGroupId } = getLatestChatFromGroups(groupsFiltered.value);
        selectedChatId.value = latestChatId;
        openedGroupIDs.openedIds.push(latestGroupId);
      }
    });
  };

  const preventInputUnFocusOnce = ref<boolean>(false);

  const openLatestUnreadMessage = () => {
    const latestUnreadChat = getLatestUnreadChat(chats.value);
    if (latestUnreadChat) {
      selectedChatId.value = latestUnreadChat.id;
    }
  };

  return {
    isOpened,
    isExpanded,
    isInputFocussed,
    isInputMaximized,
    isNewChat,
    isWaitingForData,
    isMarkingChats,
    isLoading,
    isLoadingFirstTime,
    isOpenedGroupSelectedOnStartup,
    isFlat,
    isAnythingInsideSidebarBox,
    isSoundTemporarilyMuted,
    markedChats,
    searchTerm,
    showNewFilter,
    sortBy,
    sortDir,
    groupFilters,
    selectedChatId,
    selectedChat,
    selectedChatText,
    openedGroupIDs,
    isForumGroupOpened,
    isProjectGroupOpened,
    chats,
    chatsSorted,
    groupsFiltered,
    groupsFilteredSorted,
    unreadMessages,
    editMessageId,
    eventBus,
    preventInputUnFocusOnce,
    loadChats,
    showSortPanel,
    showOptionsPanel,
    isSoundEnabled,
    sendTestTone,
    removeMarkedChats,
    startNewChat,
    createNewChat,
    selectChat,
    setSelectedChatText,
    watchIsExpandedToSelectNewestChat,
    sendMessage,
    editMessage,
    markAsRead,
    getChatById,
    getMessageById,
    openLatestUnreadMessage,
    sendingMessageId
  };
});

export default useMessengerState;
