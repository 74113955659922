
import Vue from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import ForumService from '@/services/ForumService';
import BCXNewStuffBanner from '@/components/molecules/BCXNewStuffBanner.vue';
import { PostSubscription } from '@/views/subscriptions/models/Subscription';
import ThreadTopic from '../components/ThreadTopic.vue';
import ThreadReplies from '../components/ThreadReplies.vue';
import { ForumMaxChars, MessagePost } from '../models/Forum';

export default Vue.extend({
  name: 'Thread',
  components: {
    ThreadTopic,
    ThreadReplies,
    'new-messages-banner': BCXNewStuffBanner
  },
  provide: () => ({
    maxCreateMessageTextLength: ForumMaxChars.TALK_CONTENT
  }),
  beforeRouteUpdate(to, _from, next) {
    this.clearThread();
    const { forumId, groupId, threadId } = to.params;
    this.fetchThread({
      forumId,
      groupId,
      threadId,
      sortParam: 'TIMESTAMP',
      sortOrder: 'DESC'
    }).then(() => {
      this.updateThreadMeta();
      next();
    });
  },
  beforeRouteLeave(_to, _from, next) {
    this.clearThread();
    clearInterval(window.threadRefreshInterval);
    next();
  },
  data() {
    return {
      newMessagesAvailable: false,
      intervalSeconds: 60,
      numberOfReplies: 0,
      numberOfEdits: 0
    };
  },
  computed: {
    ...mapGetters('forum', ['getForumId', 'getThreadId', 'getGroupId', 'getThreadRepliesTotal', 'getThreadRepliesSorted', 'getOpenEditMessageId']),
    ...mapState('forum', ['thread', 'messagesSort']),
    ...mapGetters('subscriptions', ['getSubscriptionsByObjectId']),
    threadSubscribed(): boolean {
      return this.thread.forumThreadMetaData.userSubscribed || Object.keys(this.getSubscriptionsByObjectId).includes(this.getThreadId);
    }
  },
  mounted() {
    window.threadRefreshInterval = setInterval(() => {
      this.updateThreadMeta(true);
    }, this.intervalSeconds * 1000);
    const { forumId, groupId, threadId } = this.$route.params;
    this.$store.dispatch('forum/fetchThread', {
      forumId, groupId, threadId, sortParam: 'TIMESTAMP', sortOrder: 'DESC',
    }).then(() => {
      this.$nextTick(() => {
        this.updateThreadMeta();
      });
    });
  },
  methods: {
    ...mapActions('forum', [
      'clearThread',
      'clearThreadList',
      'fetchThread',
      'sortThreadReplies',
      'postThreadMessage',
      'updateThreadMessage',
      'editMessage',
      'updateThreadTitle',
    ]),
    ...mapActions('subscriptions', ['postSubscription', 'deleteSubscription', 'fetchSubscriptions']),
    updateThreadMeta(compare = false) {
      ForumService.getThreadMeta(this.getForumId, this.getGroupId, this.getThreadId).then((threadMeta) => {
        if (compare) {
          if (this.numberOfReplies !== threadMeta.numberOfReplies || this.numberOfEdits !== threadMeta.numberOfEdits) {
            this.newMessagesAvailable = true;
          }
        }
        this.numberOfReplies = threadMeta.numberOfReplies;
        this.numberOfEdits = threadMeta.numberOfEdits;
      });
    },
    loadNewMessages() {
      this.fetchThread({ forumId: this.getForumId, groupId: this.getGroupId, threadId: this.getThreadId }).then(() => {
        this.closeNewMessagesBanner();
      });
    },
    closeNewMessagesBanner() {
      this.newMessagesAvailable = false;
      this.intervalSeconds = Math.round(this.intervalSeconds * 1.25);
    },
    sortReplies(sortParam: string, sortOrder: 'ASC' | 'DESC') {
      this.sortThreadReplies({ sortParam, sortOrder });
    },
    postMessage(postMessage: MessagePost) {
      this.postThreadMessage(postMessage).then(() => {
        this.fetchSubscriptions();
      });
      this.numberOfReplies++;
    },
    updateMessage(postMessage: MessagePost, messageCount: string) {
      this.updateThreadMessage({ postMessage, messageCount });
      this.numberOfEdits++;
    },
    toggleEditMessage(editMessageId: string) {
      if (this.getOpenEditMessageId === editMessageId) {
        this.editMessage('');
      } else {
        this.editMessage(editMessageId);
      }
    },
    updateThreadTopic(title: string) {
      const updateData = {
        forumId: this.getForumId,
        groupId: this.getGroupId,
        threadId: this.getThreadId,
        title
      };
      this.updateThreadTitle(updateData);
    },
    updatedThreadTopic() {
      this.numberOfEdits++;
    },
    subscribeToThread() {
      if (!this.threadSubscribed) {
        const postSubscriptionData: PostSubscription = {
          businessObjectId: this.getThreadId,
          subscriptionType: 'FORUM_TALK'
        };
        this.postSubscription(postSubscriptionData);
      }
    },
    unsubscribeFromThread() {
      if (Object.keys(this.getSubscriptionsByObjectId).length > 0 && this.threadSubscribed) {
        const { subscriptionId } = this.getSubscriptionsByObjectId[this.getThreadId];
        if (subscriptionId) {
          this.deleteSubscription(subscriptionId);
        }
      }
    },
    endOfPageReached() {
      // placeholder if needed;
    }
  }
});
