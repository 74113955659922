import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { RawLocation } from 'vue-router';

const useRouterIgnoreDuplicate = () => {
  const route = useRoute();
  const router = useRouter();

  const isSameLocationAsRoute = (location:RawLocation):boolean => {
    if (typeof location === 'object') {
      return location.path === route?.fullPath
        && location?.name === route?.name
        && JSON.stringify(location?.params) === JSON.stringify(route?.params)
        && JSON.stringify(location?.query) === JSON.stringify(route?.query)
        && JSON.stringify(location?.hash) === JSON.stringify(route?.hash);
    }
    return location === route?.fullPath;
  };

  const pushRoute = async (to:RawLocation):Promise<boolean> => {
    if (!isSameLocationAsRoute(to)) {
      await router.push(to);
      return true;
    }
    return false;
  };

  const replaceRoute = async (to:RawLocation):Promise<boolean> => {
    if (!isSameLocationAsRoute(to)) {
      await router.push(to);
      return true;
    }
    return false;
  };

  return {
    pushRoute, replaceRoute, router
  };
};

export default useRouterIgnoreDuplicate;
