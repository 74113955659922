
import BCXMessengerUserSelect from '@/components/messenger/User/BCXMessengerUserSelect.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import BCXTextArea from '@/components/molecules/forms/BCXTextArea.vue';
import TagDisplay from '@/components/tagging/TagDisplay.vue';
import { AvailabilityInterface } from '@/models/Availability';
import { Profile, ProfileStoreKey } from '@/models/Profile';
import { PropertyState, RecommendationApiError, ResponseRecommendationVerify } from '@/models/Recommendations';
import { User } from '@/models/User';
import AvailabilityService from '@/services/AvailabilityService';
import { getProfile } from '@/services/Profile/Api';
import recommendationService from '@/services/RecommendationService';
import ProfileSidebarAvailability from '@/views/profile/components/sidebar/ProfileSidebarAvailability.vue';
import {
  computed, defineComponent, provide, ref, watch
} from 'vue';
import { asyncComputed } from '@vueuse/core';
import { useRoute } from 'vue2-helpers/vue-router';

const FAKE_RESULT = false;

export default defineComponent({
  name: 'RecommendToUser',
  components: {
    BCXTextArea,
    TagDisplay,
    BCXMarkdown,
    ProfileSidebarAvailability,
    BCXUserBanner,
    BCXMessengerUserSelect
  },
  emits: ['recommended', 'selected', 'loaded'],
  setup(_, { emit }) {
    const user = ref<User | null>(null);
    const message = ref('');
    const hasBeenRecommended = ref(false);

    const route = useRoute();

    // dirty hack to get ProfileBasicView working
    provide('storeKey', ProfileStoreKey.PROFILE);

    watch(user, (user) => {
      hasBeenRecommended.value = false;
      if (user) emit('selected');
    });

    const availability = asyncComputed<AvailabilityInterface | null | false>(
      async () => {
        const userId = user.value?.userId;
        if (!userId) return null;

        const availability = await AvailabilityService.getAvailabiltyforUser(userId);
        if (!availability) return false;

        return availability;
      },
      null,
      { lazy: true }
    );

    const profile = asyncComputed<Profile | null>(
      async () => {
        const userId = user.value?.userId;
        if (!userId) return null;
        return getProfile(userId);
      },
      null,
      { lazy: true }
    );

    const projectVerification = asyncComputed<ResponseRecommendationVerify | null>(
      async () => {
        if (!(route.params.projectId && user.value?.userId)) {
          return null;
        }
        const verificationResult = await recommendationService.verifyRecommendation(user.value.userId, route.params.projectId);

        if (FAKE_RESULT) {
          verificationResult.resultMessageKey = 'api.error.4xx.recommendation.rule.projectpreferences_not_matching';
          verificationResult.projectPreferencesPropertyList.verifyResultList = [
            {
              propertyKey: 'userAvailability',
              propertyState: PropertyState.MANDATORY
            }
          ];
        }

        return verificationResult;
      },
      null,
      {
        lazy: true
      }
    );

    const notMatchingReasons = computed<string[] | null>(() => projectVerification.value?.projectPreferencesPropertyList?.verifyResultList?.map(
      (entry) => entry.propertyKey
    ) ?? null);

    const recommendationKey = computed<RecommendationApiError | null>(() => {
      const key = projectVerification.value?.resultMessageKey;
      if (!key) return null;
      return (key.match(/rule\.(.*?)$/)?.[1] as RecommendationApiError) ?? null;
    });

    const resultMessageKey = computed(() => {
      if (availability.value === false) return 'recommendations.apiErrors.availability_fetch_error';
      if (!recommendationKey.value) return null;
      return `recommendations.apiErrors.${recommendationKey.value}`;
    });

    const verifiedProject = computed(() => projectVerification.value?.project ?? null);

    const onSelectUser = (newUser: User) => {
      user.value = newUser;
    };

    const recommend = async () => {
      const { projectId } = route.params;
      if (!projectId || !user.value?.userId) return;
      await recommendationService.createRecommendation(user.value.userId, projectId, message.value);
      hasBeenRecommended.value = true;
      emit('recommended');
    };

    const isLoading = computed(() => user.value?.userId && !availability.value && !profile.value && !verifiedProject.value);

    const cantRecommend = computed(
      () => hasBeenRecommended.value
          || availability.value === false
          || !availability.value?.available
          || (resultMessageKey.value && recommendationKey.value !== RecommendationApiError.PROJECTPREFERENCES_DO_NOT_MATCH)
    );

    return {
      onSelectUser,
      user,
      availability,
      profile,
      message,
      recommend,
      hasBeenRecommended,
      isLoading,
      cantRecommend,
      verifiedProject,
      resultMessageKey,
      notMatchingReasons
    };
  }
});
