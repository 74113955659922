
import {
  defineComponent, ref, computed, Ref, ComputedRef
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';
import FavouriteIcon from '@/components/svg/favourite.svg?inline';
import FavouriteMutedIcon from '@/components/svg/favourite-muted.svg?inline';
import Triangle from '@/components/svg-components/Triangle.vue';
import BCXInfoBanner from '@/components/molecules/BCXInfoBanner.vue';
import BCXSkeleton from '@/components/molecules/BCXSkeleton.vue';
import { PostSubscription, Subscription, SubscriptionListSort } from '@/views/subscriptions/models/Subscription';
import { truncate } from 'lodash';

export default defineComponent({
  name: 'SubscriptionList',
  components: {
    FavouriteIcon,
    FavouriteMutedIcon,
    BCXInfoBanner,
    Triangle,
    BCXSkeleton,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);

    const subscriptionList = computed(() => store.getters['subscriptions/getSubsciptionListSorted']);
    const isLoading: ComputedRef<boolean> = computed(() => store.state.subscriptions.loadingSubscriptions);

    function getSubscriptionTypeCategory(subscription: Subscription): string {
      if (subscription.type.includes('PROJECT')) {
        if (subscription.type === 'PROJECTS') {
          return t('subscriptions.projects') as string;
        }
        return t('subscriptions.project') as string;
      } if (subscription.type === 'FORUM_TALKS') {
        return t('subscriptions.talks') as string;
      }
      return t('subscriptions.talk') as string;
    }

    function getSubscriptionTypeClass(subscription: Subscription): string {
      if (subscription.type.includes('PROJECT') || subscription.type.includes('PROJECTS_')) {
        return 'subscription-type--project';
      }
      return 'subscription-type--talk';
    }

    function getSubscriptionTitle(subscription: Subscription): string {
      let { title } = subscription;
      if (['PROJECTS'].includes(title)) {
        title = t('subscriptions.all-projects') as string;
      }
      if (isMobileLayout.value) {
        return truncate(title, { length: 45 });
      }
      return title;
    }

    function isTypeCreator(subscription: Subscription): boolean {
      return subscription.createdType === 'CREATOR';
    }

    const previouslyUnsubscribed: Ref<Subscription|null> = ref(null);
    const hasPreviousUnsubscribe: Ref<boolean> = computed(() => previouslyUnsubscribed.value !== null);
    function unsubscribe(subscription: Subscription) {
      if (isTypeCreator(subscription)) {
        return;
      }
      store.dispatch('subscriptions/deleteSubscription', subscription.subscriptionId);
      previouslyUnsubscribed.value = subscription;
      if (window.unsubscribedTimeout !== undefined) {
        clearTimeout(window.unsubscribedTimeout);
      }
      window.unsubscribedTimeout = setTimeout(() => {
        previouslyUnsubscribed.value = null;
      }, 10000);
    }

    function undoUnsubscribe() {
      if (previouslyUnsubscribed.value) {
        const postSubscription: PostSubscription = {
          businessObjectId: previouslyUnsubscribed.value.subscribedObjectIdentifier,
          subscriptionType: previouslyUnsubscribed.value.type,
        };
        store.dispatch('subscriptions/postSubscription', postSubscription);
        previouslyUnsubscribed.value = null;
      }
    }

    function hidePreviousUnsubscribe() {
      previouslyUnsubscribed.value = null;
    }

    function getSubscriptionCreatedType(subscription: Subscription): string {
      return t(`subscriptions.created-types.${subscription.createdType}`) as string;
    }

    function linkToSubscriptionItem(subscription: Subscription) {
      if (subscription.type === 'PROJECTS') {
        return {
          name: 'projectList',
        };
      } if (subscription.type.includes('PROJECT')) {
        return {
          name: 'projectForumThread',
          params: {
            projectId: subscription.metaProjectId,
          },
        };
      } if (subscription.type === 'FORUM_TALKS') {
        return {
          name: 'threads',
          params: {
            forumId: subscription.metaForumId,
            groupId: subscription.metaForumThreadGroupId
          },
        };
      }
      return {
        name: 'thread',
        params: {
          forumId: subscription.metaForumId,
          groupId: subscription.metaForumThreadGroupId,
          threadId: subscription.metaForumThreadId,
        },
      };
    }

    const subscriptionListSort: ComputedRef<SubscriptionListSort> = computed(() => store.state.subscriptions.subscriptionListSort);

    function getSubscriptionSortClass(sortBy: string) {
      if (sortBy === subscriptionListSort.value.sortBy) {
        return `subscription-sort--active sort-${subscriptionListSort.value.sortOrder.toLowerCase()}`;
      }
      return '';
    }

    function sort(sortBy: string) {
      let sortOrder: 'ASC' | 'DESC' = 'ASC';
      if (sortBy === subscriptionListSort.value.sortBy && subscriptionListSort.value.sortOrder === 'ASC') {
        sortOrder = 'DESC';
      }
      store.dispatch('subscriptions/setSubscriptionListSort', { sortBy, sortOrder });
    }

    return {
      isMobileLayout,
      isLoading,
      subscriptionList,
      getSubscriptionTitle,
      linkToSubscriptionItem,
      getSubscriptionTypeCategory,
      getSubscriptionTypeClass,
      getSubscriptionCreatedType,
      isTypeCreator,
      unsubscribe,
      previouslyUnsubscribed,
      hasPreviousUnsubscribe,
      undoUnsubscribe,
      hidePreviousUnsubscribe,
      subscriptionListSort,
      getSubscriptionSortClass,
      sort,
      t
    };
  },
});
