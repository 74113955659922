
import { computed, defineComponent } from 'vue';
import ProjectListItem from '@/views/project-forum/components/ProjectListItem.vue';
import useProfileState from '@/state/profile/profileState';
import { ProfileStoreKey } from '@/models/Profile';
import BCXPaginatedContent from '@/components/molecules/BCXPaginatedContent.vue';

export default defineComponent({
  name: 'ProfilePublicProjectsView',
  components: { BCXPaginatedContent, ProjectListItem },
  setup() {
    const { profile, canEdit, isQuickInfo } = useProfileState(ProfileStoreKey.PROFILE);
    const projects = computed(() => profile.value?.companyInformation?.publicProjects ?? []);

    return {
      projects, canEdit, isQuickInfo
    };
  }
});
