
import {
  defineComponent, ref, computed, PropType, toRef, Ref, ComputedRef
} from 'vue';
import useI18n from '@/mixins/useI18n';
import { LatestUserDashboard } from '@/views/home/models/Dashboard';
import BCXProgressDots from '@/components/molecules/BCXProgressDots.vue';
import DashboardNewMembersSkeleton from '@/views/home/components/DashboardNewMembersSkeleton.vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import ByteCookieIcon from '@/components/svg/logo/logo-circles-only.svg?inline';
import MarvinLookUpIcon from '@/components/svg/marvin/marvin_look_up.svg?inline';
import SendArrow from '@/components/svg/send-arrow.svg?inline';
import Chevron from '@/components/svg-components/Chevron.vue';
import useQuickInfo from '@/state/quickInfo';

import axios from '@/plugins/axios';
import { truncate } from 'lodash';
import { useStore } from 'vue2-helpers/vuex';
import useMessengerState from '@/state/messenger/messengerState';
import { User } from '@/models/User';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';

export default defineComponent({
  name: 'DashboardNewMembers',
  components: {
    BCXProgressDots,
    DashboardNewMembersSkeleton,
    ByteCookieIcon,
    SendArrow,
    Chevron,
    BCXAvatar,
    MarvinLookUpIcon,
  },
  props: {
    members: {
      type: Array as PropType<LatestUserDashboard[]>,
      default: () => null
    },
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const { loadChats } = useMessengerState();

    const isLoading: ComputedRef<boolean> = computed(() => store.state.dashboard.usersLoading);

    const newMembers:Ref<LatestUserDashboard[]> = toRef(props, 'members');

    const hasNewMembers: ComputedRef<boolean> = computed(() => !isLoading.value && newMembers.value.length > 0);

    const currentPosition: Ref<number> = ref(0);
    const maxChars = ref(255);
    const maxDotsPerView: Ref<number> = ref(5);
    const maxPositions: ComputedRef<number> = computed(() => newMembers.value.length);
    const maxPages: ComputedRef<number> = computed(() => Math.ceil(maxPositions.value / maxDotsPerView.value));
    const currentPage: ComputedRef<number> = computed(() => Math.ceil((currentPosition.value + 1) / maxDotsPerView.value));
    const maxDots: ComputedRef<number> = computed(() => {
      if (maxPositions.value < maxDotsPerView.value) {
        return maxPositions.value;
      }
      if (currentPage.value === maxPages.value
        && maxPositions.value % maxDotsPerView.value !== 0) {
        return (maxPositions.value % maxDotsPerView.value);
      }
      return maxDotsPerView.value;
    });

    const currentDotState: ComputedRef<number> = computed(() => {
      if (currentPosition.value > 0) {
        return (currentPosition.value % maxDotsPerView.value);
      }
      return 0;
    });

    function nextPosition() {
      if (currentPosition.value < maxPositions.value - 1) {
        currentPosition.value++;
      } else {
        currentPosition.value = 0;
      }
    }
    function previousPosition() {
      if (currentPosition.value > 0) {
        currentPosition.value--;
      } else {
        currentPosition.value = maxPositions.value - 1;
      }
    }
    function setCurrentPosition(index: number) {
      currentPosition.value = index;
    }
    function isCurrentPosition(index: number): boolean {
      return currentPosition.value === index;
    }
    const currentMember = computed(() => newMembers.value[currentPosition.value]);

    const messageText = ref('');

    const sendMessageButtonEnabled = computed(() => messageText.value.length > 0);

    const messageSentText = ref('');

    function sendMessage() {
      axios.post('/v1/messaging/chats',
        {
          targetUserId: currentMember.value.externalUserBanner.externalUserId,
          content: BCXHTMLSanitizer.beforeSave(messageText.value)
        }).then(({ }) => {
        messageText.value = '';
        messageSentText.value = t('dashboard.new-members.message-sent') as string;
        setTimeout(() => {
          messageSentText.value = '';
        }, 5000);
        loadChats();
      });
    }

    function getUserFullName(user: LatestUserDashboard): string {
      return `${user.externalUserBanner.firstname} ${user.externalUserBanner.lastname}`;
    }

    function getUserId(user: LatestUserDashboard): string {
      return user.externalUserBanner.externalUserId;
    }

    function hasSummary(user: LatestUserDashboard): boolean {
      const { profileSummary } = user.externalUserProfile.basic;
      return profileSummary !== undefined && profileSummary.length > 0;
    }

    function getProfileSummary(user: LatestUserDashboard): string {
      if (hasSummary(user)) {
        return truncate(user.externalUserProfile.basic.profileSummary, { length: 100 });
      }
      return t('profile.basic.emptyTextView', { name: getUserFullName(user) }) as string;
    }

    function showUserQuickInfo(item: LatestUserDashboard, ev: Event) {
      const { setQuickInfo, quickInfoShowContactHistory, quickInfoShowBadgesLegend } = useQuickInfo();
      quickInfoShowContactHistory.value = false;
      quickInfoShowBadgesLegend.value = false;
      const el = ev.target as HTMLElement;
      const user:User = {
        userId: getUserId(item),
        fullname: getUserFullName(item),
        firstname: item.externalUserBanner.firstname,
        lastname: item.externalUserBanner.lastname,
      };
      const { left, top } = el.getBoundingClientRect();
      setQuickInfo(store.getters['userStates/getUserInfo'](user.userId), left, top + 50);
    }

    return {
      isLoading,
      newMembers,
      currentMember,
      currentPosition,
      maxPositions,
      maxDots,
      currentDotState,
      nextPosition,
      previousPosition,
      setCurrentPosition,
      isCurrentPosition,
      messageText,
      sendMessageButtonEnabled,
      getUserFullName,
      getUserId,
      getProfileSummary,
      hasSummary,
      sendMessage,
      messageSentText,
      showUserQuickInfo,
      hasNewMembers,
      maxChars,
      t
    };
  },
});

