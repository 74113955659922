
import { defineComponent, ref } from 'vue';
import useI18n from '@/mixins/useI18n';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';

export default defineComponent({
  name: 'ProjectGuestsAddExisting',
  components: {
    BCXSearchSelect
  },
  setup() {
    const { t } = useI18n();

    const searchInput = ref('');

    return {
      t,
      searchInput,
    };
  },
});
