import { render, staticRenderFns } from "./LandingpageCustomer.vue?vue&type=template&id=6c321e36&scoped=true"
import script from "./LandingpageCustomer.vue?vue&type=script&lang=ts"
export * from "./LandingpageCustomer.vue?vue&type=script&lang=ts"
import style0 from "./LandingpageCustomer.vue?vue&type=style&index=0&id=6c321e36&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c321e36",
  null
  
)

export default component.exports