
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import ProfileQuickInfoSkeleton from '@/components/molecules/ProfileQuickInfoSkeleton.vue';
import Chevron from '@/components/svg-components/Chevron.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import { HtmlTarget } from '@/models/HtmlTarget';
import { ProfileStoreKey } from '@/models/Profile';
import { User, UserType } from '@/models/User';
import useProfileState from '@/state/profile/profileState';
import useQuickInfo from '@/state/quickInfo';
import ContactHistory from '@/views/profile/components/ContactHistory.vue';
import ProfileSection from '@/views/profile/components/ProfileSection.vue';
import ProfileBasicView from '@/views/profile/components/views/ProfileBasicView.vue';
import ProfileCertificatesEducationsView from '@/views/profile/components/views/ProfileCertificatesEducationsView.vue';
import ProfileInvitedBy from '@/views/profile/components/views/ProfileInvitedBy.vue';
import ProfilePreferencesView from '@/views/profile/components/views/ProfilePreferencesView.vue';
import ProfileProjectsView from '@/views/profile/components/views/ProfileProjectsView.vue';
import ProfileReferencesView from '@/views/profile/components/views/ProfileReferencesView.vue';
import ProfileSkillsView from '@/views/profile/components/views/ProfileSkillsView.vue';
import {
  computed, defineComponent, PropType, ref, watch
} from 'vue';
import { toRefs, useVModel } from '@vueuse/core';
import ProfileAboutusView from '@/views/profile/components/views/ProfileAboutusView.vue';
import ProfileMembersView from '@/views/profile/components/views/ProfileMembersView.vue';
import ProfilePublicProjectsView from '@/views/profile/components/views/ProfilePublicProjectsView.vue';
import { USER_TYPE_FAKE } from '@/models/Faking';
import UserBadgesLegend from './components/UserBadgesLegend.vue';

export default defineComponent({
  name: 'ProfileQuickInfo',
  components: {
    ProfilePublicProjectsView,
    ProfileMembersView,
    ProfileAboutusView,
    ProfileQuickInfoSkeleton,
    ProfileProjectsView,
    Chevron,
    BCXCollapsible,
    ProfileBasicView,
    ProfileReferencesView,
    ProfileCertificatesEducationsView,
    ProfileSkillsView,
    ProfilePreferencesView,
    ProfileSection,
    ProfileInvitedBy,
    ContactHistory,
    UserBadgesLegend
  },
  props: {
    user: {
      type: Object as PropType<User>,
      default: () => null
    },
    showMore: {
      type: Boolean
    },
    target: {
      type: String as PropType<HtmlTarget>,
      default: '_self'
    }
  },
  setup(props, { emit }) {
    const isExpanding = ref(false);
    const { user: propUser } = toRefs(props);
    const {
      init, profile, user: stateUser, isSectionFilled
    } = useProfileState(ProfileStoreKey.QUICK_INFO, propUser);
    const showMoreVM = useVModel(props, 'showMore');
    const { isMobileWidth } = useResponsiveness();
    const { setShowContactHistory, quickInfoShowContactHistory, quickInfoShowBadgesLegend } = useQuickInfo();
    init();

    const showProfile = computed(() => !!stateUser.value && !!profile.value);
    const showProfileInfos = computed(() => showProfile.value && !quickInfoShowContactHistory.value && !quickInfoShowBadgesLegend.value);

    watch(showProfile, (show) => {
      if (show) {
        emit('show-profile');
      }
    });

    const setContactHistory = (enable: boolean) => {
      setShowContactHistory(enable);
    };

    const userType = computed(() => stateUser.value?.type ?? USER_TYPE_FAKE);

    return {
      isSectionFilled,
      showProfile,
      profile,
      showMoreVM,
      isMobileWidth,
      quickInfoShowContactHistory,
      quickInfoShowBadgesLegend,
      showProfileInfos,
      setContactHistory,
      stateUser,
      isExpanding,
      UserType,
      userType,
    };
  }
});
