
import Vue, {
  computed, nextTick, PropType, ref, toRefs
} from 'vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import { templateRef, useVModel } from '@vueuse/core';

export default Vue.extend({
  components: { BCXIcon },
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'home',
    },
    maxlength: {
      type: [String, Number, Boolean],
      default: false
    },
    showSearchIcon: {
      type: Boolean,
      default: true
    },
    enterEvent: {
      type: Function as () => any,
      default: () => null,
      required: false,
    },
    iconEvent: {
      type: Function as PropType<CallableFunction>,
      default: null,
    },
    placeholder: {
      type: String,
      default: undefined
    },
    invertOnContent: Boolean
  },
  setup(props) {
    const { type } = toRefs(props);
    const isFocussed = ref(false);
    const inputVM = useVModel(props);
    const isSearchExpanded = ref(false);
    const input = templateRef<HTMLInputElement>('input');

    const isExpanded = computed(() => type.value === 'messenger' || isFocussed.value || inputVM.value || isSearchExpanded.value);
    const showInputField = computed(() => (type.value === 'home' || type.value === 'user-search') || isExpanded.value);

    const expandSearch = () => {
      isSearchExpanded.value = true;
      nextTick(() => {
        if (input.value) input.value.focus();
      });
    };

    const clear = () => {
      inputVM.value = '';
      isSearchExpanded.value = false;
    };

    const onFocus = () => { isFocussed.value = true; };
    const onBlur = () => {
      isFocussed.value = false;
      isSearchExpanded.value = false;
    };

    const loupeSize = computed(() => (type.value === 'home' ? 16 : 20));

    return {
      isFocussed,
      inputVM,
      isExpanded,
      showInputField,
      loupeSize,
      expandSearch,
      onFocus,
      onBlur,
      clear,
      isSearchExpanded,
    };
  },

});
