
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import BCXConfirm from '@/components/molecules/BCXConfirm.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import IconCleanup from '@/components/svg/cleanup.svg?inline';
import IconMyProjects from '@/components/svg/my-projects.svg?inline';
import IconWatchlist from '@/components/svg/watchlist.svg?inline';
import useBCXConfirm from '@/mixins/useBCXConfirm';
import useI18n from '@/mixins/useI18n';
import useResponsiveness from '@/mixins/useResponsiveness';
import {
  ExternalUserProject, PAGE_SIZE_RECOMMENDATIONS, RECOMMENDATIONS_EVENT_REFRESH, ResponseMyProjects
} from '@/models/Recommendations';
import { RouterEntry } from '@/models/Router';
import RecommendationService from '@/services/RecommendationService';
import useRecommendationState from '@/state/recommendationsState';
import MyProjectDetails from '@/views/my-projects/components/MyProjectDetails.vue';
import ProjectListItem from '@/views/project-forum/components/ProjectListItem.vue';
import { PromisePool } from '@supercharge/promise-pool';
import {
  computed, defineComponent, ref, watch
} from 'vue';
import { useToggle } from '@vueuse/core';
import BCXPaginatedContent from '@/components/molecules/BCXPaginatedContent.vue';

export default defineComponent({
  name: 'MyProjects',
  components: {
    BCXPaginatedContent,
    BCXConfirm,
    MyProjectDetails,
    ProjectListItem,
    BCXMarkdown,
    BCXBreadcrumbs,
    IconWatchlist,
    IconMyProjects,
    IconCleanup
  },
  setup() {
    const { t } = useI18n();
    const { isMobileWidth } = useResponsiveness();
    const myProjectsResponse = ref<ResponseMyProjects | null>(null);
    const checkedProjectIds = ref([]);
    const { recommendationsEventBus } = useRecommendationState();

    const [isCleanup, toggleIsCleanup] = useToggle();

    const { onConfirm, showConfirm, ask } = useBCXConfirm();

    const load = async () => {
      myProjectsResponse.value = await RecommendationService.getMyProjects();
    };
    load();

    recommendationsEventBus.on((event) => {
      if (event === RECOMMENDATIONS_EVENT_REFRESH) load();
    });

    const watchList = computed<ExternalUserProject[]>(() => {
      const myProjects = myProjectsResponse.value?.externalUserProjectList ?? [];
      return myProjects.sort((a, b) => `${b.project.latestAddedReply || b.createdTimestamp}`.localeCompare(`${a.project.latestAddedReply || a.createdTimestamp}`));
    });

    const myProjectsIntro = computed(() => t('my-projects.intro') as string);

    const removeRecommendation = async (userProjectId:string) => {
      if (!await ask()) return;
      await RecommendationService.deleteMyProject(userProjectId);
      await load();
    };

    const breadcrumbItems: RouterEntry[] = [
      { text: `${t('project-forum.labels.projects')}`, to: { name: 'myProjects' } },
      { text: `${t('my-projects.title')}`, to: { name: 'myProjects' } },
    ];
    if (isMobileWidth.value) { // display logo as first item
      breadcrumbItems.unshift({ text: 'ByteCookie' });
    }

    const cleanup = async () => {
      await PromisePool.for(checkedProjectIds.value)
        .withConcurrency(20)
        .process(async (projectId) => {
          await RecommendationService.deleteMyProject(projectId);
        });
      isCleanup.value = false;
      await load();
    };

    // clear selected id when entereing cleanup mode:
    watch(isCleanup, (is) => {
      if (is) checkedProjectIds.value = [];
    });

    const confirmText = computed(() => t('my-projects.confirmDelete.text', { count: checkedProjectIds.value.length }).toString());

    return {
      isCleanup,
      isMobileWidth,
      cleanup,
      confirmText,
      toggleIsCleanup,
      breadcrumbItems,
      watchList,
      removeRecommendation,
      myProjectsIntro,
      onConfirm,
      showConfirm,
      checkedProjectIds,
      PAGE_SIZE_RECOMMENDATIONS
    };
  }
});
