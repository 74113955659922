
import Vue from 'vue';
import IconEdit from '@/components/svg/edit-circle.svg?inline';
import IconChevronDown from '@/components/svg/chevron-down.svg?inline';
import EditProjectForumSection from '@/views/project-forum/components/EditProjectForumSection.vue';

export default Vue.extend({
  name: 'ProjectForumSection',
  components: {
    IconEdit,
    IconChevronDown,
    EditProjectForumSection,
  },
  props: {
    type: {
      type: String,
      default: undefined,
      // required: true,
    },
    editValue: {
      type: [String, Number, Object, Array],
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isExpandable: {
      type: Boolean,
      default: false,
    },
    showTestFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      expanded: true,
    };
  },
  computed: {
    showEditButton(): boolean {
      return this.isEditable && !this.edit;
    },
    formExplainText(): string {
      if (this.$t(`project-forum.form-section.${this.type.toLowerCase()}.explain`)) {
        return this.$t(`project-forum.form-section.${this.type.toLowerCase()}.explain`) as string;
      }
      return '';
    },
    contentCollapsed(): boolean {
      return !this.expanded && !this.edit;
    },
    showExpansionButton(): boolean {
      return this.isExpandable && !this.expanded && !this.edit;
    },
  },
  watch: {
    isExpandable(newValue) {
      this.expanded = !newValue;
    }
  },
  mounted() {
    this.expanded = !this.isExpandable;
  },
  methods: {
    toggleEditSection() {
      this.edit = !this.edit;
    },
    expandContent() {
      if (this.isExpandable) {
        this.expanded = !this.expanded;
      }
    },
    editSave(data: any) {
      this.$emit('edit-save', data);
      this.toggleEditSection();
    },
  }
});
