import axios, { searchApiConfig } from '@/plugins/axios';
import { ForumLink } from '@/models/ForumLink';
import { AxiosResponse } from 'axios';
import { logError } from '@/utils/logger';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';

export default {
  async getMatchingTopics(newTitle:string, participationType:string):Promise<ForumLink[]> {
    const response:AxiosResponse = await axios.post('/v1/participation/search', {
      newTitle,
      participationType,
    }, searchApiConfig).catch((err) => logError(err)) as AxiosResponse;
    return response?.data ?? [] as ForumLink[];
  },

  async addParticipationTopic(title:string, participationType:string, content:string):Promise<AxiosResponse> {
    return await axios.post('/v1/participations', {
      title: BCXHTMLSanitizer.beforeSave(title),
      participationType,
      content: BCXHTMLSanitizer.beforeSave(content),
    })
      .catch((err) => logError(err)) as AxiosResponse;
  },
};
