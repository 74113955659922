// Proxy for easier use in composition api

import VueI18n from '@/i18n';
import { computed } from 'vue';

export default () => ({

  t: (key:string, ...args:any) => VueI18n.t(key, ...args),
  tc: (key:string, ...args:any) => VueI18n.tc(key, ...args),
  te: (key:string, ...args:any) => VueI18n.te(key, ...args),
  locale: computed({
    get():string {
      return VueI18n.locale;
    },
    set(locale:string) {
      localStorage.setItem('locale', locale);
      VueI18n.locale = locale;
    },
  }),
});
