
import useMobileNavigationVisibility from '@/mixins/useMobileNavigationVisibility';
import useResponsiveness from '@/mixins/useResponsiveness';
import { InfoBoxState } from '@/models/InfoBox';
import usePageState from '@/state/pageState';
import Full from '@/views/layouts/Full.vue';
import LoginRegister from '@/views/layouts/LoginRegister.vue';
import Plain from '@/views/layouts/Plain.vue';
import Landingpage from '@/views/layouts/Landingpage.vue';
import Vue, { computed, provide } from 'vue';
import { useRoute } from 'vue2-helpers/vue-router';
import { mapMutations, MutationMethod } from 'vuex';
import * as Sentry from '@sentry/vue';
import { logDebug } from '@/utils/logger';
import { whenever } from '@vueuse/core';
import useAppVersionState from './state/appVersionState';
import BCXInfoBanner from './components/molecules/BCXInfoBanner.vue';
import BCXMarkdown from './components/molecules/BCXMarkdown.vue';
import useI18n from './mixins/useI18n';

const defaultLayout = 'plain';

export default Vue.extend({
  name: 'App',

  components: {
    Plain,
    Full,
    LoginRegister,
    Landingpage,
    BCXInfoBanner,
    BCXMarkdown
  },

  setup() {
    const { isHidden } = useMobileNavigationVisibility();
    const { isSidebarVisible, isDesktopWidth } = useResponsiveness();
    const { mainClasses } = usePageState();
    const { t } = useI18n();

    const route = useRoute();

    const {
      isAppUpdateAvaliable, isNotificationIgnored, ignoreNotification
    } = useAppVersionState();

    const showAppUpdateNotification = computed(() => isAppUpdateAvaliable.value && isNotificationIgnored.value === false);

    whenever(showAppUpdateNotification, () => {
      logDebug('App update notification shown');
    });

    const reloadApp = () => {
      window.location.reload();
    };

    provide('isMobileLayerHidden', isHidden);

    const layout = computed(() => route.meta?.layout ?? defaultLayout);

    const classes = computed(() => [layout.value, ...mainClasses.value]);

    const appUpdateText = computed(() => {
      const latestVersionText = t('common.appUpdateAvailable').toString();
      if (isDesktopWidth.value) {
        return latestVersionText.replace('<br>', '');
      }
      return latestVersionText;
    });

    return {
      isSidebarVisible,
      classes,
      layout,
      route,
      showAppUpdateNotification,
      ignoreNotification,
      reloadApp,
      isDesktopWidth,
      appUpdateText
    };
  },

  data: () => ({
    showErrorMessage: false,
    errorMessageTimeout: -1
  }),

  computed: {
    errorMessage(): string {
      return this.$store.getters.errorMessage;
    },
    sidebarComponent(): Vue | null {
      return this.$route.meta?.sidebarComponent ?? null;
    },
    infoBoxContent(): InfoBoxState | null {
      return this.$route.meta?.infoBox ?? null;
    }
  },

  created() {
    this.setInitialTimestamp();

    const config: { [key: string]: MutationMethod } = {
      '(max-width:480px)': this.setIsSmartphoneWidth,
      '(max-width:767px)': this.setIsMobileWidth,
      '(min-width:768px) and (max-width:1439px)': this.setIsMini,
      '(max-height:640px)': this.setIsFlat,
      '(min-width:768px) and (max-width:1255px)': this.setIsTabletWidth,
      '(min-width:1256px)': this.setIsDesktopWidth
    };
    Object.entries(config).forEach(([mediaQuery, setter]) => {
      const mql: MediaQueryList = window.matchMedia(mediaQuery);
      setter(mql.matches);
      mql.onchange = (evt) => setter(evt.matches);
    });
  },

  mounted() {
    this.checkLastReload();
  },

  methods: {
    setInitialTimestamp() {
      const timestamp = new Date().getTime();
      localStorage.setItem('last_reload', String(timestamp));
    },

    checkLastReload() {
      const timestamp = new Date().getTime();
      const lastReload = localStorage.getItem('last_reload');
      const latestVersion = localStorage.getItem('latest_version');

      if (parseInt(latestVersion || '0') > timestamp) {
        logDebug('NEW VERSION');
      } else if (parseInt(lastReload || '0') !== timestamp) {
        logDebug('PAGE RELOADED');
        localStorage.setItem('last_reload', String(timestamp));
      }
    },

    ...mapMutations('page', ['setIsFlat', 'setIsMini', 'setIsMobileWidth', 'setIsTabletWidth', 'setIsDesktopWidth', 'setIsSmartphoneWidth'])
  }
});
