
import {
  computed, defineComponent, PropType,
} from 'vue';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import { useVModel } from '@vueuse/core';

export type BCXExtendedDropdownItemValue = string | number;

export interface BCXExtendedDropdownItem {
  label: string;
  value: BCXExtendedDropdownItemValue;
  description?: string;
}

export default defineComponent({
  name: 'BCXMultiselectSection',
  components: { BCXCheckbox },
  props: {
    items: {
      type: Array as () => BCXExtendedDropdownItem[],
      required: true
    },
    value: {
      type: [Array, String] as PropType<BCXExtendedDropdownItemValue[] | BCXExtendedDropdownItemValue | null>,
      default: () => []
    }
  },
  setup(props) {
    const isMultiple = computed(() => Array.isArray(props.value));
    const selectedValue = useVModel(props);

    const isSelected = (item: BCXExtendedDropdownItemValue) => {
      if (isMultiple.value) return (selectedValue.value as BCXExtendedDropdownItemValue[]).includes(item);
      return selectedValue.value === item;
    };

    const selectValue = (item: BCXExtendedDropdownItemValue) => {
      if (isMultiple.value) {
        const selectedItems = selectedValue.value as BCXExtendedDropdownItemValue[];
        if (isSelected(item)) {
          selectedValue.value = selectedItems.filter((i) => i !== item);
        } else {
          selectedValue.value = [...selectedItems, item];
        }
      } else {
        selectedValue.value = item;
      }
    };

    return {
      selectValue,
      isSelected,
      isMultiple,
    };
  }
});
