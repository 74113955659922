
import { defineComponent, Ref, ref } from 'vue';
import IconCheckmark from '@/components/svg/checkmark.svg?inline';

export default defineComponent({
  name: 'BCXCheckbox',
  components: { IconCheckmark },
  inheritAttrs: false,
  props: {
    checked: Boolean,
    readonly: Boolean,
    isRadio: Boolean,
  },
  setup(props, { emit }) {
    const input = ref(null) as Ref<HTMLInputElement | null>;

    const onChange = () => {
      const isChecked = !!input?.value?.checked;
      emit('input', isChecked);
    };

    return {
      input,
      onChange,
    };
  },
});
