
import { computed, defineComponent, PropType } from 'vue';
import { ExternalProjectListEntry } from '@/__generated__/types';
import BCXItemsWithPoints from '@/components/molecules/BCXItemsWithPoints.vue';
import ProjectListItemOpenState from '@/views/project-forum/components/ProjectListItemOpenState.vue';
import { toRefs } from '@vueuse/core';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';

export default defineComponent({
  name: 'ProjectMinimalListItem',
  components: { ProjectListItemOpenState, BCXItemsWithPoints },
  props: {
    project: {
      type: Object as PropType<ExternalProjectListEntry>,
      required: true
    }
  },
  setup(props) {
    const { project } = toRefs(props);
    const { getFormattedDate } = useFormattedDateTime();

    const creationDateFormatted = computed(() => getFormattedDate(project.value.created));

    return {
      creationDateFormatted
    };
  }
});
