
import { defineComponent } from 'vue';
import UserDataSetting from '@/views/settings/components/settings/UserDataSetting.vue';
import EmailSetting from '@/views/settings/components/settings/EmailSetting.vue';

export default defineComponent({
  name: 'SectionUserDataEmail',
  components: {
    EmailSetting,
    UserDataSetting,
  },
});
