
import SearchInput from '@/components/molecules/SearchInput.vue';
import X from '@/components/svg-components/X.vue';
import ByteCookieLogoCirclesOnly from '@/components/svg/logo/logo-circles-only.svg?inline';
import ByteCookieLogoFull from '@/components/svg/logo/logo-full-for-darkBG_topbar-beta.svg?inline';
import useResponsiveness from '@/mixins/useResponsiveness';
import { SizeThresholds } from '@/models/Toolbar';
import { User, UserType } from '@/models/User';
import asyncWait from '@/utils/asyncWait';
import Vue, {
  computed, onMounted, Ref, ref, watch
} from 'vue';
import { templateRef, useMutationObserver } from '@vueuse/core';
import { createVuexHelpers } from 'vue2-helpers';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import onSizeChange from '@/mixins/onSizeChange';
import { USER_TYPE_FAKE } from '@/models/Faking';
import Availability from './Availability.vue';
import Notification from './notification/Notification.vue';

export default Vue.extend({
  components: {
    X,
    SearchInput,
    Notification,
    Availability,
    ByteCookieLogoFull,
    ByteCookieLogoCirclesOnly
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    // const store = useStore();
    const isHighlighted = ref(false);
    const searchInput = ref('');
    const topbar = templateRef<HTMLElement>('topbar');
    const notification = templateRef<HTMLElement>('notification');
    const isNoSpaceForFullLogo = ref(false);
    const isNoSpaceForFullAvailability = ref(false);
    const { useState, useGetters, useMutations } = createVuexHelpers();
    const {
      isFlat, isMini, isMobileWidth, isSmartphoneWidth
    } = useResponsiveness();
    const {
      isMobileSearchVisible,
    } = useState('page', [
      'isMobileSearchVisible',
    ]);
    // const isAdmin = computed(() => (store.getters.user.roles as Array<string>).includes('ADMIN'));
    const { toggleIsMobileSearchVisible } = useMutations('page', ['toggleIsMobileSearchVisible']);

    const { user } = useGetters(['user']) as {
      user: Ref<User>;
    };

    const showMobileSearch = computed(() => isMobileSearchVisible.value && isMobileWidth.value);

    const performSearch = () => {
      if (searchInput.value !== route?.query.q) {
        router.push({ name: 'user-search', query: { q: searchInput.value } });
      }
    };

    const calcSpace = async () => {
      const barWidth = topbar.value.offsetWidth;
      const notificationWidth = notification.value.getBoundingClientRect().width;
      await asyncWait(10); // prevent infinite loop in rare edge cases
      const availableWidth = barWidth - notificationWidth;
      isNoSpaceForFullLogo.value = availableWidth < SizeThresholds.BCLogo;
      isNoSpaceForFullAvailability.value = availableWidth < SizeThresholds.Availability;
    };

    useMutationObserver(topbar, () => {
      calcSpace();
    }, {
      subtree: true,
      childList: true
    });

    onSizeChange(topbar, calcSpace);

    onMounted(() => {
      calcSpace();
    });

    watch(showMobileSearch, async (is) => {
      if (is) {
        isHighlighted.value = true;
        await asyncWait(250);
        isHighlighted.value = false;
      }
    });

    const userType = computed(() => user.value.type ?? USER_TYPE_FAKE);

    const isAvailabilityVisible = computed(() => !user.value || userType.value !== UserType.COMPANY);

    return {
      isFlat,
      isNoSpaceForFullLogo,
      isMini,
      isMobileWidth,
      isMobileSearchVisible,
      isHighlighted,
      isSmartphoneWidth,
      isNoSpaceForFullAvailability,
      showMobileSearch,
      toggleIsMobileSearchVisible,
      user,
      searchInput,
      performSearch,
      isAvailabilityVisible
    };
  },
});
