
import RegistrationService from '@/services/RegistrationService';
import { autoEncodeUserId } from '@/utils/userIdEncoding';
import {
  computed, defineComponent, onMounted, reactive, ref
} from 'vue';
import LRPopup from '@/components/login-register/LRPopup.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import useBCValidators from '@/mixins/useBCValidators';
import useVuelidate from '@vuelidate/core';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { useStore } from 'vue2-helpers/vuex';
import ButtonBlock from '@/components/login-register/ButtonBlock.vue';
import BackendErrors from '@/components/login-register/BackendErrors.vue';

export default defineComponent({
  name: 'Verification',
  components: {
    BackendErrors,
    ButtonBlock,
    BCXValidatedInput,
    BCXMarkdown,
    LRPopup
  },
  setup() {
    const resent = ref(false);
    const resending = ref(false);
    const resendError = ref(false);
    const submitting = ref(false);
    const showConfirm = ref(false);
    const emptyCode = ref(true);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const backendErrors = ref([]);

    const { email: storeEmail, user, registrationProcessId: pid } = store.getters;

    const email = computed(() => storeEmail || user?.email);

    const userId = computed(() => {
      if (route?.query?.uid) {
        return autoEncodeUserId(`${route.query.uid}`);
      }
      return user.userId;
    });

    const registrationProcessId = computed(() => route?.query?.pid ?? pid);

    const { passwordValidation, required } = useBCValidators();

    const fields = reactive({
      code: route?.query.code ?? '',
      // code: '', // der code in der URL sah schon eher recht ulkig aus.
      password: ''
    });

    const rules = {
      code: { required },
      password: passwordValidation
    };

    const v = useVuelidate(rules, fields);

    const resendEmail = async () => {
      resending.value = true;
      await RegistrationService.resendEmail(userId.value, registrationProcessId.value as string).catch(() => {
        resendError.value = true;
      });
      resent.value = true;
      resending.value = false;
    };

    const submit = async () => {
      if (!submitting.value && (await v.value.$validate())) {
        const params = {
          verificationCode: fields.code,
          password: fields.password,
          registrationProcessId: registrationProcessId.value,
          userId: userId.value
        };

        submitting.value = true;
        const backendErrorObject = await store.dispatch('submitVerificationData', params);

        backendErrors.value = backendErrorObject.allErrors;

        submitting.value = false;
      }
    };

    const goBack = () => {
      router.push({ name: 'register' });
    };

    const resend = () => {
      showConfirm.value = true;
    };

    const onConfirmed = (is: boolean) => {
      if (is) {
        resendEmail();
      } else {
        goBack();
      }
    };

    onMounted(() => {
      emptyCode.value = (route !== undefined && route.query.code.length === 0);
    });

    return {
      showConfirm,
      fields,
      v,
      resend,
      goBack,
      onConfirmed,
      submit,
      email,
      resent,
      resending,
      resendError,
      backendErrors,
      emptyCode,
    };
  }
});
