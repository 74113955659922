import { Link } from '@/models/Link';
import {
  Chat,
  ChatMessage,
  ChatMessageType,
  ChatMetaObject,
  ChatStub,
  CONCURRENT_MESSAGE_LOADING,
  MAX_FAILED_REQUESTS_IN_A_ROW,
  MESSAGE_EVENT_REFRESH,
  MINIMAL_CONSECUTIVE_LOADING_INTERVAL_MS,
  SendMessageResponse,
  TEMPORARY_CHAT_MESSAGE_ID,
  TEMPORARY_CHAT_USER_ID
} from '@/models/Messenger';
import messengerService from '@/services/MessengerService';
import splitConversations from '@/state/messenger/conversationSplitter';
import eventBus from '@/state/messenger/EventBus';
import { PromisePool } from '@supercharge/promise-pool';
import Vue, { nextTick } from 'vue';
import { cloneDeep } from 'lodash';
import { failedQueue } from '@/plugins/axios';
import { logDebug, logError } from '@/utils/logger';

// for development:
const FAKE_NO_MESSAGES = false;

const {
  getChatsMeta, getChatDetails, getChatStubs, removeChat, updateMessage
} = messengerService;

const getChatsDetailed = async (chats: ChatStub[]): Promise<Chat[]> => {
  if (FAKE_NO_MESSAGES) return [];

  const { results } = await PromisePool
    .for(chats)
    .withConcurrency(CONCURRENT_MESSAGE_LOADING)
    .process(async ({
      externalId,
      ...chat
    }) => {
      const chatDetails = await getChatDetails(externalId) as ChatStub;

      if (chatDetails) {
        const {
          externalId,
          groupType,
          links,
          ...rest
        } = chatDetails;

        const fullChat = {
          ...chat,
          ...rest,
          type: groupType,
          chatId: externalId,
          id: externalId,
          links: (links ? JSON.parse(links) : []) as Link[]
        };

        return fullChat;
      }
      return null;
    });

  return results.filter((result) => !!result) as Chat[];
};

let lastLoadingTime = 0;
let chatMeta:ChatMetaObject | null = null;
let chatStubs:ChatStub[] = [];
let chatsDetailed:Chat[] = [];
let splittedConversations:Chat[] | null = null;
let isLoadingChats = false;

const invalidateChat = (chatId:string) => {
  if (chatMeta) chatMeta[chatId] = { messages: 0, edited: 0 };
};

const invalidateMessage = (messageId:string) => {
  chatsDetailed.some((chat) => {
    if (chat.chatId && chat.messages.some((message) => message.messageId === messageId)) {
      invalidateChat(chat.chatId);
      eventBus.emit(MESSAGE_EVENT_REFRESH);
      return true;
    }
    return false;
  });
};

let inARow = 0;

const getAllChats = async (): Promise<Chat[] | null> => {
  const isOnStage = window.location.host.includes('stage');

  const now = new Date().getTime();
  const diff = now - lastLoadingTime;

  if (lastLoadingTime + MINIMAL_CONSECUTIVE_LOADING_INTERVAL_MS > now) {
    logDebug(`Last message load was only ${diff}ms ago, skipping this one.`);
    return splittedConversations ?? null;
  }

  if (isLoadingChats) {
    if (inARow && isOnStage) logDebug('not loading meta now, already loading chats: ', inARow, ' in a row');
    inARow++;
    if (inARow > MAX_FAILED_REQUESTS_IN_A_ROW) {
      inARow = 0;
      logDebug('Pending requests:', failedQueue.value);
      if (!failedQueue.value.length) {
        logDebug('No pending request, complete messages reload');
        isLoadingChats = false;
        chatMeta = null;
      }
    }
    return splittedConversations ?? null;
  }
  inARow = 0;

  isLoadingChats = true;
  if (!chatMeta) {
    // For the first time, read everything once
    splittedConversations = null;
    chatStubs = await getChatStubs();
    chatMeta = await getChatsMeta();
    if (chatStubs?.length) {
      chatsDetailed = await getChatsDetailed(chatStubs);
    }
  } else {
    try {
      // All consecutive times: Do a "diff" with chatMeta:
      const newChatMeta = await getChatsMeta();
      if (newChatMeta) {
        // Compare newChatMeta with previous one:
        await PromisePool.for(Object.entries(newChatMeta))
          .withConcurrency(CONCURRENT_MESSAGE_LOADING)
          .process(async ([chatId, { messages: newMessages, edited: newEdited }]) => {
            const { messages: oldMessages = 0, edited: oldEdited = 0 } = chatMeta?.[chatId] ?? {};
            if (newMessages !== oldMessages || newEdited !== oldEdited) {
              // Number of messages changed or new message?
              const [newChat] = await getChatsDetailed([{ externalId: chatId }]);
              const oldChatIndex = chatsDetailed.findIndex((chat) => chat.chatId === chatId);
              if (oldChatIndex >= 0) {
                // replace chat
                chatsDetailed[oldChatIndex] = newChat;
              } else {
                // add chat
                chatsDetailed.push(newChat);
              }
              splittedConversations = null;
            }
          });
        Object.entries(chatMeta).forEach(([chatId]) => {
          if (!newChatMeta[chatId]) {
            const chatIndex = chatsDetailed.findIndex((chat) => chat.chatId === chatId);
            if (chatIndex >= 0) {
              // remove unused chat (not anymore in chatMeta)
              chatsDetailed.splice(chatIndex, 1);
              splittedConversations = null;
            }
          }
        });

        chatMeta = newChatMeta;
      }
    } catch (e) {
      logError('newChatMeta error');
      isLoadingChats = false;
    }
  }

  if (!splittedConversations) {
    try {
      splittedConversations = splitConversations(cloneDeep(chatsDetailed).filter((chat) => chat?.messages?.length >= 1));
    } catch (e) {
      logError('splitConversation error');
      isLoadingChats = false;
    }
  }

  isLoadingChats = false;
  lastLoadingTime = new Date().getTime();

  return splittedConversations;
};

let sendMessageTemporaryIdCounter = 0;

const insertTemporaryMessage = (chat: Chat, text:string, messageType: ChatMessageType): ChatMessage['messageId'] => {
  const messageId = TEMPORARY_CHAT_MESSAGE_ID + (sendMessageTemporaryIdCounter++);
  nextTick(() => {
    chat.messages.push({
      text,
      messageType,
      messageId,
      read: true,
      created: new Date().toString(),
      upvoteCount: 0,
      downvoteCount: 0,
      edited: false,
      flagged: false,
      subMessages: [],
      creator: TEMPORARY_CHAT_USER_ID,
      replyTo: TEMPORARY_CHAT_USER_ID,
      voteResult: 0,
    });
  });
  return messageId;
};

const markTemporaryMessageAsFailed = (chat:Chat, messageId: ChatMessage['messageId']) => {
  const messageIndex = chat.messages.findIndex((message) => message.messageId === messageId);
  if (messageIndex < 0) throw new Error(`temporary message not found: ${messageId}`);

  Vue.set(chat.messages, messageIndex, {
    ...chat.messages[messageIndex],
    hasFailedSending: true
  });

  /* const index = chat.messages.findIndex((message) => message.messageId === messageId);
  if (index >= 0) chat.messages.splice(index, 1); */
};

const deleteFailedMessages = (chat:Chat) => {
  let i = chat.messages.length;
  while (i--) {
    const message = chat.messages[i];
    if (message.hasFailedSending) {
      chat.messages.splice(i, 1);
    }
  }
};

const sendMessage = async (chat: Chat, text: string, messageType?: ChatMessageType): Promise<SendMessageResponse> => {
  deleteFailedMessages(chat);
  const temporaryMessageId = insertTemporaryMessage(chat, text, messageType ?? ChatMessageType.PRIVATE_FEEDBACK_MESSAGING);
  let result;
  const chatLink = chat.forum ?? chat?.links?.[0];
  if (chatLink) {
    const {
      forumId,
      forumTalkId,
      forumTalkGroupId,
    } = chatLink;
    if (!(forumTalkId && forumId && forumTalkGroupId)) throw new Error(`Now Forum link information for chat ${chat.id}`);
    result = await messengerService.postMessageToForum(forumTalkGroupId, {
      forumId,
      forumThreadId: forumTalkId,
      messageContent: text,
      replyToMessageId: chat.messages.at(-1)?.messageId ?? '???',
      replyToUserId: chat.privatePartner.userId ?? '',
    });
  } else {
    result = await messengerService.postMessage({
      targetUserId: chat.privatePartner.userId ?? '',
      content: text,
      messageType: messageType ?? ChatMessageType.PRIVATE_MESSAGING_MESSAGE
    });
  }

  if (!result) {
    markTemporaryMessageAsFailed(chat, temporaryMessageId);
  }

  return result;
};

const markAsRead = messengerService.markMessageAsRead;
const getUsers = messengerService.searchChatUsers;
const getContacts = messengerService.getChatContacts;

const editMessage = async (
  messageContent:string,
  chatId:string,
  messageId:string,
  targetUserId:string,
  chat: Chat,
) => {
  const result = await updateMessage(chatId, messageId, {
    messageContent, targetUserId
  });
  if (!result) {
    markTemporaryMessageAsFailed(chat, messageId);
  }
  invalidateChat(chatId);
  return result;
};

const removeChats = (ids:string[]): Promise<any> => PromisePool
  .for(ids)
  .withConcurrency(CONCURRENT_MESSAGE_LOADING)
  .process(async (id) => removeChat(id));

export {
  getUsers,
  getContacts,
  markAsRead,
  sendMessage,
  getAllChats,
  editMessage,
  removeChats,
  invalidateChat,
  invalidateMessage,
};
