
import Vue from 'vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { useStore } from 'vue2-helpers/vuex';
import useResponsiveness from '@/mixins/useResponsiveness';
import { useRouter } from 'vue2-helpers/vue-router';
import { onMounted } from 'vue-demi';

export default Vue.extend({
  name: 'RegistrationFinished',
  components: { BCXMarkdown },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { isMobileWidth } = useResponsiveness();

    const { user } = store.getters;

    const gotoLogin = async () => {
      store.commit('clearUserAndToken');
      await router.push({ name: 'login', query: { email: user.email } });
    };

    onMounted(() => {
      setTimeout(gotoLogin, 5000);
    });

    return {
      isMobileWidth,
      user,
      gotoLogin
    };
  },
});
