import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';

export enum Lang {
  de = 'de-DE',
  en = 'en-US',
}

export enum UserType {
  FREELANCER = 'freelancer',
  COMPANY = 'company',
  GUEST = 'guest',
}

export interface User {
  userId?: string;
  externalUserId?: string;
  firstname?: string;
  lastname?: string;
  fullname?: string;
  companyname?: string;
  email?: string;
  preferredLocale?: Lang;
  reputationPoints?: number;
  city?: string;
  type?: UserType;
  projects?: ProjectListProject[];
}

export interface ExternalUser {
  userId: string;
  firstname: string;
  fullname: string;
  lastname: string;
}
