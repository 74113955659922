/**
 * This should help to move backend errors to the frontend. Simple.
 */
export enum TranslatedErrorCode {
  REGISTRATION_WRONG_STATUS = 'wrong_status',
  INVALID_INVITE_CODE = 'invite_code_not_valid',
  INVITE_CODE_ALREADY_USED = 'invite_code_already_used',
  EMAIL_NOT_UNIQUE = 'email_not_unique',
  EMAIL_CODE_INVALID = 'email_code_not_valid',
  EMAIL_NOT_VALID = 'email_not_valid',
  EMAIL_NOT_VERIFIED = 'email_not_verified',
  MOBILE_NUMBER_IN_USE = 'mobile_number_in_use',
  FIRSTNAME_NOT_CONSISTENT = 'firstname_not_consistent',
  FIRSTNAME_MIN_LENGTH = 'firstname_min_length',
  FIRSTNAME_MAX_LENGTH = 'firstname_max_length',
  FIRSTNAME_SIZE = 'firstname_size',
  FIRSTNAME_EMPTY = 'firstname_empty',
  LASTNAME_NOT_CONSISTENT = 'lastname_not_consistent',
  LASTNAME_MIN_LENGTH = 'lastname_min_length',
  LASTNAME_MAX_LENGTH = 'lastname_max_length',
  LASTNAME_SIZE = 'lastname_size',
  LASTNAME_EMPTY = 'lastname_empty',
  REGISTRATION_LEGAL_NOT_CONFIRMED = 'registration_legal_not_confirmed',
  MOBILE_NUMBER_NOT_VALID = 'mobile_number_not_valid',
  MOBILE_NUMBER_NOT_VERIFIED = 'mobile_number_not_verified',
  MOBILE_VERIFICATION_CODE_INVALID = 'mobile_verification_code_invalid',
  PASSWORD_DOES_NOT_MATCH = 'password_does_not_match',
  TOO_MANY_LOGINS = 'too_many_logins',
  UNSPECIFIC_ERROR = 'unspecific_error',
  PASSWORD_EMPTY = 'password_empty',
  PASSWORD_TOO_SHORT = 'password_too_short',
  PASSWORD_NOT_VALID = 'password_not_valid',
  UNKNOWN_ERROR = 'unknown_error',
  PASSWORD_ALREADY_GIVEN = 'password_already_given',
  ACCOUNT_SUSPENDED = 'account_suspended',
}

export interface BackendError {
  code: TranslatedErrorCode;
  message?: string | null;
  inputField?: string | null;
}

export interface BackendErrors {
  errorCode?: TranslatedErrorCode | string | null;
  errorMessage?: string | null;
  validationErrors?: BackendError[] | null;
}
