
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXItemsWithPoints from '@/components/molecules/BCXItemsWithPoints.vue';
import TagDisplay from '@/components/tagging/TagDisplay.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { Project } from '@/views/project-forum/models/ProjectForum';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';

export default defineComponent({
  name: 'ProfileSidebarProjectSummary',
  components: {
    TagDisplay, BCXAvatar, BCXItemsWithPoints
  },
  props: {
    project: {
      type: Object as PropType<Project | null>,
      default: null
    },
  },
  setup(props) {
    const { project } = toRefs(props);
    const { getFormattedDate } = useFormattedDateTime();

    const startDate = computed(() => getFormattedDate(project.value?.startDate));

    return {
      startDate
    };
  }

});
