export enum NotificationType {
  Messages = 'messages',
  Projects = 'projects',
  Talks = 'talks',
}

export enum NotificationTypeCounts {
  ForumMessage = 'numberOfUnreadForumMessages',
  ProjectMessage = 'numberOfUnreadProjectForumMessages',
  DirectMessage = 'numberOfUnreadPrivateMessages',

  NewProjectChanges = 'numberOfNewProjectChanges',
  NewProjectComments = 'numberOfNewProjectComments',
  NewProjects = 'numberOfNewProjects',

  NewTalkComments = 'numberOfNewTalkComments',
  NewTalks = 'numberOfNewTalks',

  NewRecommendations = 'numberOfNewRecommendations',
  NewRecommendationFeedbacks = 'numberOfNewRecommendationFeedbacks',

  Sum = 'sum'
}

export type Notification = {
  [key in NotificationTypeCounts]?: number;
}

export interface NotificationCountable extends Notification {
  [NotificationTypeCounts.Sum]: number;
}

export interface NotificationMessages extends NotificationCountable {
  [NotificationTypeCounts.ForumMessage]: number;
  [NotificationTypeCounts.ProjectMessage]: number;
  [NotificationTypeCounts.DirectMessage]: number;
}

export interface NotificationProjects extends NotificationCountable {
  [NotificationTypeCounts.NewProjectChanges]: number;
  [NotificationTypeCounts.NewProjectComments]: number;
  [NotificationTypeCounts.NewProjects]: number;
  [NotificationTypeCounts.NewRecommendations]: number;
  [NotificationTypeCounts.NewRecommendationFeedbacks]: number;
}

export interface NotificationTalks extends NotificationCountable {
  [NotificationTypeCounts.NewTalkComments]: number;
  [NotificationTypeCounts.NewTalks]: number;
}

export interface Notifications {
  [NotificationType.Projects]: NotificationProjects;
  [NotificationType.Messages]: NotificationMessages;
  [NotificationType.Talks]: NotificationTalks;
}

export interface NotificationListItem {
  count: number;
  name: NotificationTypeCounts;
}

export interface NotificationBarPanelItem {
  count: number;
  name: NotificationType;
}

export type NotificationBarPanelItems = Partial<Record<NotificationType, NotificationBarPanelItem>>;
