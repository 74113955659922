
import BCXNavigationItems from '@/components/navigation/BCXNavigationItems.vue';
import { NavigationTree } from '@/models/Navigation';
import SearchInput from '@/components/molecules/SearchInput.vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import NavigationPanel from '@/components/navigation/NavigationPanel.vue';
import { findInNavigationTree, traverseNavigationTreeItems } from '@/navigation/traverseNavigationTreeItems';
import useAvatarState from '@/state/avatarState';
import useTokenState from '@/state/tokenState';
import {
  computed, defineComponent, inject, onBeforeUnmount, onMounted, ref, toRefs, watch
} from 'vue';
import navigation from '@/navigation/prepareNavigation';
import { and, not } from '@vueuse/math';
import { createVuexHelpers } from 'vue2-helpers';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import { Route } from 'vue-router';
import useI18n from '@/mixins/useI18n';
import { useStore } from 'vue2-helpers/vuex';
import useResponsiveness from '@/mixins/useResponsiveness';
import debouncedOnFalse from '@/mixins/debouncedOnFalse';
import {
  debouncedRef, onClickOutside, templateRef
} from '@vueuse/core';
import useRouterIgnoreDuplicate from '@/mixins/useRouterIngoreDuplicate';

const WIDTH_EXPANDED = 250;
const WIDTH_MINI = 72;

export default defineComponent({
  name: 'BCXNavigation',
  components: {
    NavigationPanel,
    BCXIcon,
    SearchInput,
    BCXNavigationItems,
  },
  props: {
    isMini: {
      type: Boolean,
    },
  },
  setup(props) {
    const { isMini } = toRefs(props);
    const isExpanded = ref<boolean>(false);
    const isHiddenMobile = inject('isMobileLayerHidden');
    // const navigation = reactive(prepareNavigation());
    const { useState, useMutations } = createVuexHelpers();
    const { isParticipateVisible } = useState('page', ['isParticipateVisible']);
    const { setIsParticipateVisible }: any = useMutations('page', ['setIsParticipateVisible']);
    const { isMobileLayout, isFlat } = useResponsiveness();
    const { locale } = useI18n();
    const { useNativeBrowserScaling } = useAvatarState();
    const {
      renew
    } = useTokenState();
    const route = useRoute() as Route;
    const router = useRouterIgnoreDuplicate();
    const store = useStore();
    const el = templateRef<HTMLElement>('el');
    const searchInput = ref('');
    let removeRouterHook: any = null;

    const isParticipateVisibleDebounced = debouncedRef(isParticipateVisible, 250);

    // showExpanded will be delayed and also not allowed, when the participate panel is visible
    const showExpanded = and(debouncedOnFalse(isExpanded, 750), not(isParticipateVisible));

    const style = computed(() => (isMini.value
      ? {
        width: `${showExpanded.value ? WIDTH_EXPANDED : WIDTH_MINI}px`,
      }
      : null));

    const isMinified = computed(() => isMini.value && !showExpanded.value);

    // methods used by navigation.yaml:
    const navigationMethods = {
      switchLanguage: () => {
        locale.value = locale.value === 'en' ? 'de' : 'en';
      },

      logout: async () => {
        // eslint-disable-next-line no-alert
        if (window.confirm('Wirklich ausloggen?')) {
          await store.dispatch('logout');
          await router.pushRoute({ name: 'index' });
        }
      },

      toggleParticipate: (item: NavigationTree) => {
        setIsParticipateVisible(item._active);
        // isExpanded.value = false;
      },

      toggleBrowserScaling: async () => {
        useNativeBrowserScaling.value = !useNativeBrowserScaling.value;
      }
    };

    watch(isParticipateVisible, (state: boolean) => {
      traverseNavigationTreeItems(navigation, (value, level, key) => {
        if (key === 'participate') value._active = state;
      });
    });

    const clearActiveMenu = () => traverseNavigationTreeItems(navigation, (value) => {
      value._active = false;
      value._opened = false;
    });

    const setActiveMenu = (route:Route) => traverseNavigationTreeItems(navigation, (value, _1, _2, parent) => {
      if (value._to && route.path.startsWith(value._to)) {
        value._active = true;
        if (parent) {
          parent._opened = !isMobileLayout.value;
          parent._active = true;
        }
      }
    });

    onMounted(async () => {
      await router.router.isReady();
      removeRouterHook = router.router.afterEach((to) => {
        clearActiveMenu();
        setActiveMenu(to);
      });
      setActiveMenu(route);
    });

    onBeforeUnmount(() => {
      if (removeRouterHook) removeRouterHook();
    });

    const closePanel = () => {
      traverseNavigationTreeItems(navigation, (item: NavigationTree, level) => {
        if (level === 0) item._opened = false;
      });
    };

    const closePanelMobile = () => {
      if (isMobileLayout.value) closePanel();
    };

    const performSearch = async () => {
      await router.pushRoute({ name: 'user-search', query: { q: searchInput.value } }).catch((e) => {
        // just catch this avoided redundant navigation nonsense
      });
    };

    const select = ({ item, level }: any) => {
      // Navigation tree behaviour:
      if (!item?._toggle && !item?._button) {
        traverseNavigationTreeItems(navigation, (loopItem, loopLevel) => {
          if (level === loopLevel && !loopItem._toggle && !loopItem._button) {
            if (loopLevel === 0) {
              // Main menu points: Toggle if already opened or set exclusively as opened:
              loopItem._opened = loopItem._opened ? false : loopItem._id === item._id;
            } else if (loopLevel === 1) {
              // Sub menu point: Set exclusively active if clicked on:
              loopItem._active = loopItem._id === item._id;
            }
          }
        });
        traverseNavigationTreeItems(navigation, (loopItem, loopLevel) => {
          if (loopLevel === 0 && !loopItem._toggle && !loopItem._button) {
            // Set main menu opened if sub-item is active (and not a toggle item)
            loopItem._active = loopItem._items && !!findInNavigationTree(loopItem._items, (item) => item._active && !item._toggle && !item._button);
          }
        });
      } else if (!item._button) {
        // toggle:
        item._active = !item._active;
      }

      // Click behaviour:

      if (item._to) {
        if (route.path !== item._to) {
          if (isMobileLayout.value) {
            setTimeout(() => closePanel(), 500);
          }
        }
      } else if (item._href) {
        window.location.href = item._href;
      }

      if (item._method) {
        (navigationMethods as any)[item._method](item);
      }
    };

    onClickOutside(el, closePanelMobile);

    const onClickMenu = () => {
      if (isParticipateVisibleDebounced.value) setIsParticipateVisible(false);
    };

    return {
      select,
      closePanel,
      closePanelMobile,
      isHiddenMobile,
      onClickMenu,
      navigation,
      setIsParticipateVisible,
      isExpanded,
      style,
      isMobileLayout,
      isParticipateVisible,
      isMinified,
      isFlat,
      showExpanded,
      searchInput,
      performSearch,
    };
  },
});
