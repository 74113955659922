
import { defineComponent } from 'vue';
import BCXMarkBeforeWrapped from '@/components/molecules/BCXMarkBeforeWrapped.vue';

export default defineComponent({
  name: 'BCXItemsWithPoints',
  components: { BCXMarkBeforeWrapped },
  props: {
    gap: {
      type: String,
      default: '12px'
    },
    removeWrappingPoints: { // use with caution, may impede performance
      type: Boolean
    },
    isAlignedRight: {
      type: Boolean
    }
  }
});
