
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import Chevron from '@/components/svg-components/Chevron.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { getBlogEntriesAdmin } from '@/services/Blog/Api';
import {
  defineComponent, onMounted, reactive, ref
} from 'vue';
import BlogEntryCardPreview from '@/views/blog/components/BlogEntryPreview.vue';
import BlogEntriesTableAdmin from '../components/BlogEntriesTableAdmin.vue';
import BlogEntryEditor from '../components/BlogEntryEditor.vue';
import { BlogEntriesAdmin, BlogForm } from '../models/Blog';
import BlogArticlePreview from '../components/BlogArticlePreview.vue';

export default defineComponent({
  components: {
    BCXContentArea,
    'bc-chevron': Chevron,
    BlogEntryEditor,
    BlogEntriesTableAdmin,
    BlogEntryCardPreview,
    BlogArticlePreview
  },
  setup() {
    const listExpanded = ref(true);
    const previewMode = ref(false);
    const previewEntry = reactive({ entry: {} as BlogForm });
    const entryId = ref('');
    const entries = reactive<BlogEntriesAdmin>({ blogEntries: [] });
    const { getFormattedTimeByAge } = useFormattedDateTime();

    const loadBlogEntries = async () => {
      const blogEntries = (await getBlogEntriesAdmin())?.blogEntries.filter((entry) => entry.status !== 'DELETED') ?? [];

      entries.blogEntries = blogEntries;
      entries.blogEntries.map((entry) => {
        entry.author = entry.creator?.fullname;
        if (entry.lastUpdated) {
          entry.lastUpdated = getFormattedTimeByAge(entry.lastUpdated);
        }
        return entry;
      });
    };

    const updateEntry = (id: string) => {
      entryId.value = id;
    };
    const showPreview = (entry: BlogForm) => {
      previewEntry.entry = entry;
      previewMode.value = true;
    };

    const hidePreview = () => {
      previewMode.value = false;
    };
    onMounted(loadBlogEntries);

    return {
      listExpanded,
      entries,
      entryId,
      loadBlogEntries,
      previewMode,
      showPreview,
      previewEntry,
      hidePreview,
      updateEntry
    };
  },
  computed: {},
  methods: {
    toggleList() {
      this.listExpanded = !this.listExpanded;
    },
    edit(entry?: BlogForm) {
      this.entryId = entry?.externalId || '';
    },
    publishPost() {
      return false;
    }
  }
});
