
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXLinkedUser from '@/components/molecules/BCXLinkedUser.vue';
import Smiley from '@/components/svg-components/Smiley.vue';
import BCXChevronSwitch from '@/components/molecules/BCXChevronSwitch.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { ExternalRecommendation, feedBackType2SmileyType } from '@/models/Recommendations';
import RecommendationsFeedbackCard from '@/views/recommendations/components/RecommendationsFeedbackCard.vue';
import {
  computed, defineComponent, PropType, ref, toRefs
} from 'vue';

export default defineComponent({
  name: 'MyRecommendationsTableMobileItem',
  components: {
    BCXLinkedUser,
    RecommendationsFeedbackCard,
    Smiley,
    BCXAvatar,
    BCXChevronSwitch
  },
  props: {
    recommendation: {
      type: Object as PropType<ExternalRecommendation>,
      default: null
    }
  },
  setup(props) {
    const { recommendation } = toRefs(props);
    const isOpened = ref(false);
    const { getFormattedDate } = useFormattedDateTime();

    const created = computed(() => {
      const date = recommendation.value?.created;
      return date ? getFormattedDate(date) : '?';
    });

    return { isOpened, feedBackType2SmileyType, created };
  }
});
