
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import BCXDottedBox from '@/components/molecules/BCXDottedBox.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { defineComponent } from 'vue';
import X from '@/components/svg-components/X.vue';

export default defineComponent({
  name: 'BCXInfoCollapsible',
  components: {
    BCXDottedBox,
    BCXCollapsible,
    BCXMarkdown,
    X
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    isOpened: { type: Boolean },
    addTopPadding: { type: String, default: '0' },
    addBottomPadding: { type: String, default: '0' }
  },
});
