import { logDebug } from '@/utils/logger';
import { computed, ref } from 'vue';
import {
  createGlobalState, useTimestamp, whenever
} from '@vueuse/core';

const IGNORE_NOTIFICATION_TIME = 1000 * 60 * 10;

const useAppVersionState = createGlobalState(() => {
  const timestamp = useTimestamp({ interval: 500 });

  const currentAppVersion = ref<string>('');
  const latestAppVersion = ref<string>('');

  const calculateAppVersion = (version: string) => {
    const versionBuild = version.split('-');
    const versionNumber = versionBuild[0];
    const versionBuildNumber = versionBuild[1];

    const versionArray = versionNumber.split('.');
    const major = parseInt(versionArray[0], 10);
    const minor = parseInt(versionArray[1], 10);
    const patch = parseInt(versionArray[2], 10);
    const build = parseInt(versionBuildNumber, 10);

    return major * 1000000000 + minor * 1000000 + patch * 1000 + build / 10;
  };

  const isAppUpdateAvaliable = computed(() => currentAppVersion.value !== latestAppVersion.value
    && (currentAppVersion.value !== '' && latestAppVersion.value !== '')
    && calculateAppVersion(currentAppVersion.value) < calculateAppVersion(latestAppVersion.value));

  const ignoreNotificationUntil = ref(0);

  const ignoreNotification = () => {
    ignoreNotificationUntil.value = new Date().getTime() + IGNORE_NOTIFICATION_TIME;
  };

  const isNotificationIgnored = computed(() => ignoreNotificationUntil.value > timestamp.value);
  whenever(isNotificationIgnored, () => {
    logDebug('Notification ignored until:', new Date(ignoreNotificationUntil.value).toLocaleString());
  });

  return {
    currentAppVersion,
    latestAppVersion,
    isAppUpdateAvaliable,
    ignoreNotification,
    isNotificationIgnored,
    ignoreNotificationUntil
  };
});

export default useAppVersionState;
