
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import { SmileySizes, SmileyTypes } from '@/components/svg-components/Smiley.vue';
import useI18n from '@/mixins/useI18n';
import MyRecommendations from '@/views/recommendations/components/my-recommendations/MyRecommendations.vue';
import RecommendationsForMe from '@/views/recommendations/components/recommendations-for-me/RecommendationsForMe.vue';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  name: 'Recommendations',
  components: {
    RecommendationsForMe,
    MyRecommendations,
    BCXBreadcrumbs,
    BCXContentArea,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);
    const smileyChoice = ref('');

    const breadcrumbItems = computed(() => {
      const breadcrumbItems: Array<any> = [
        { text: t('project-forum.labels.projects'), to: { name: 'projectList' } },
        { text: t('recommendations.recommendations.title'), to: { name: 'recommendations' } },
      ];
      if (isMobileLayout.value) { // display logo as first item
        breadcrumbItems.unshift({ text: 'ByteCookie' });
      }
      return breadcrumbItems;
    });

    return {
      t,
      breadcrumbItems,
      SmileySizes,
      SmileyTypes,
      smileyChoice,
    };
  }
});
