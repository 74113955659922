
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import { useRouter } from 'vue2-helpers/vue-router';
import { defineComponent, reactive, ref } from 'vue';
import LRPopup from '@/components/login-register/LRPopup.vue';
import useBCValidators from '@/mixins/useBCValidators';
import useVuelidate from '@vuelidate/core';
import { useStore } from 'vue2-helpers/vuex';
import ButtonBlock from '@/components/login-register/ButtonBlock.vue';
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import { TranslatedErrorCode } from '@/models/BackendError';

export default defineComponent({
  name: 'Mobilephone',
  components: {
    BackendErrors, ButtonBlock, BCXValidatedInput, BCXMarkdown, LRPopup
  },
  setup() {
    const submitting = ref(false);
    const router = useRouter();
    const store = useStore();
    const backendErrors = ref<TranslatedErrorCode[]>([]);
    const { phoneValidation } = useBCValidators();
    const { mobileNumber } = store.getters;
    const fields = reactive({
      phone: mobileNumber ?? ''
    });
    const rules = {
      phone: phoneValidation
    };
    const v = useVuelidate(rules, fields);
    const showLRPopup = ref(false);

    const submit = async () => {
      if (!submitting.value && await v.value.$validate()) {
        submitting.value = true;
        const backendErrorsObject = await store.dispatch('submitMobilephone', fields.phone.replace(/\s/g, ''));
        backendErrors.value = backendErrorsObject.allErrors;
        submitting.value = false;
      }
    };

    const goBack = () => router.push({ name: 'register-verify' });

    return {
      goBack,
      submit,
      v,
      fields,
      backendErrors,
      showLRPopup,
    };
  },
});
