// this will maybe replace the page store some time

import favIconNew from '@/assets/img/favicon-new.png';
import useNotificationState from '@/state/notificationState';
import {
  computed, Ref, ref, watch
} from 'vue';
import { createGlobalState, useFavicon, useTitle } from '@vueuse/core';

const usePageState = createGlobalState(() => {
  const { totalCount } = useNotificationState();
  const title = ref('ByteCookie');
  const mainClasses = ref<string[]>([]);

  const addMainClass = (className:string) => {
    if (!mainClasses.value.includes(className)) mainClasses.value.push(className);
  };

  const removeMainClass = (className:string) => {
    mainClasses.value = mainClasses.value.filter((name) => name !== className);
  };

  const displayedTitle = useTitle();

  watch([title, totalCount], ([title, totalCount]) => {
    const countDisplay = totalCount ? `(${totalCount}) ` : '';
    displayedTitle.value = countDisplay + title;
  }, {
    immediate: true
  });

  const favIcon = computed(() => (totalCount.value ? favIconNew : '/favicon.ico'));

  useFavicon(favIcon);

  return {
    title,
    addMainClass,
    removeMainClass,
    mainClasses: mainClasses as Readonly<Ref<string[]>>
  };
});

export default usePageState;
