
import Vue from 'vue';
import { mapActions } from 'vuex';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import { convertToItemList } from '@/utils/selectItemTools';
import { SelectItem } from '@/models/SelectItem';

const placeText = ({
  placeName,
  postalCode
}: any) => (postalCode || placeName ? `${postalCode}, ${placeName}` : '');

export default Vue.extend({
  components: {
    BCXSearchSelect
  },
  props: {
    countryCode: {
      type: String,
      default: 'DE'
    },
    placeName: {
      type: String,
      default: undefined
    },
    postalCode: {
      type: String,
      default: undefined
    },
    industryTag: {
      type: Object,
      default() {
        return undefined;
      }
    },
    companySizeValue: {
      type: String,
      default: 'RANGE_50_500'
    },
    countryList: {
      type: Array,
      default: () => []
    },
    cityList: {
      type: Array,
      default: () => []
    },
    industryList: {
      type: Array,
      default: () => []
    },
    isCreatingProject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: '',
      country: 'DE',
      city: '',
      companySize: 'RANGE_50_500',
      industry: '',
      selectedCityName: '',
      selectedCityPostalcode: '',
      selectedCityCountryCode: ''
    };
  },
  computed: {
    selectedPlaceText():string {
      return placeText({
        postalCode: this.selectedCityPostalcode,
        placeName: this.selectedCityName
      });
    },
    countryItems(): Array<SelectItem> {
      return convertToItemList(this.countryList, 'isoCode', 'countryName');
    },
    cityItems(): Array<any> {
      return this.cityList.map((city: any) => {
        const text = placeText(city);
        return ({
          text,
          value: text
        });
      });
    },
    industryItems(): Array<SelectItem> {
      return convertToItemList(this.industryList, 'externalId', 'label');
    },
    companySizeItems(): Array<any> {
      return [
        { text: '-', value: '' },
        ...this.getDropdownItems('company-size')
      ];
    }
  },
  watch: {
    text(value) {
      this.$emit('input', value);
    },
    country(value) {
      if (value !== this.selectedCityCountryCode) {
        this.city = '';
        this.selectedCityName = '';
        this.selectedCityPostalcode = '';
      }
      this.$nextTick(() => {
        this.pushValue();
      });
    },
    companySize(value) {
      if (value !== this.companySizeValue) {
        this.$nextTick(() => {
          this.pushValue();
        });
      }
    },
    industry() {
      this.$nextTick(() => {
        this.pushValue();
      });
    }
  },
  mounted() {
    if (this.countryCode) {
      this.country = this.countryCode;
    }

    if (this.placeName) {
      this.city = this.placeName;
      this.selectedCityName = this.placeName;
      this.selectedCityPostalcode = this.postalCode;
      this.selectedCityCountryCode = this.countryCode;
    }

    if (this.companySizeValue) {
      this.companySize = this.companySizeValue;
    }

    if (this.industryTag) {
      this.industry = this.industryTag.tagId;
    }
  },
  methods: {
    ...mapActions('projectForum', ['fetchCities']),
    getDropdownItems(translationKey: string): Array<any> {
      const translation = {
        ...(this.$t(
          `project-forum.form-section.client.${translationKey}.dropdown`
        ) as any)
      };
      const keys = Object.keys(translation);
      return keys.map((key) => ({
        text: translation[key],
        value: key
      }));
    },
    inputCity(value: string) {
      this.fetchCities({ countryId: this.country, searchString: value });
    },
    selectCity(value: string) {
      this.cityList.forEach((city: any) => {
        if (placeText(city) === value) {
          this.selectedCityName = city.placeName;
          this.selectedCityPostalcode = city.postalCode;
          this.selectedCityCountryCode = city.countryCode;
        }
      });
      this.$nextTick(() => {
        this.pushValue();
      });
    },
    pushValue() {
      const clientData = {
        countryCode: this.country,
        cityPlaceName: this.selectedCityName,
        cityPostalCode: this.selectedCityPostalcode,
        companySize: this.companySize,
        industry: this.industry
      };
      this.$emit('input', clientData);
    }
  }
});
