
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import BCXItemsWithPoints from '@/components/molecules/BCXItemsWithPoints.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXQuickLayer from '@/components/molecules/BCXQuickLayer.vue';
import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import Eye from '@/components/svg-components/Eye.vue';
import ShareIcon from '@/components/svg/share_cropped.svg?inline';
import TagList from '@/components/tagging/TagList.vue';
import Reporting from '@/components/voting/Reporting.vue';
import Voting from '@/components/voting/Voting.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import BCXHTMLSanitizer from '@/utils/BCXHTMLSanitizer';
import CreateMessage from '@/views/forum/components/CreateMessage.vue';
import ThreadTopicTitle from '@/views/forum/components/ThreadTopicTitle.vue';
import { MessagePost } from '@/views/forum/models/Forum';
import InnerSection from '@/views/project-forum/components/InnerSection.vue';
import ProjectForumSection from '@/views/project-forum/components/ProjectForumSection.vue';
import ProjectForumTopicSkeleton from '@/views/project-forum/components/ProjectForumTopicSkeleton.vue';
import { PostProject, Project } from '@/views/project-forum/models/ProjectForum';
import RecommendToUserLayer from '@/views/recommendations/components/recommend-to-user/RecommendToUserLayer.vue';
import { truncate } from 'lodash';
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: 'ProjectForumTopic',
  components: {
    BCXItemsWithPoints,
    BCXQuickLayer,
    RecommendToUserLayer,
    BCXInfoSection,
    BCXBreadcrumbs,
    ThreadTopicTitle,
    Eye,
    Voting,
    ProjectForumSection,
    InnerSection,
    ProjectForumTopicSkeleton,
    Reporting,
    BCXUserBanner,
    CreateMessage,
    TagList,
    BCXMarkdown,
    ShareIcon
  },
  props: {
    project: {
      type: Object as () => Project,
      required: true,
      default() {
        return {} as Project;
      }
    },
    threadCreator: {
      type: Object,
      default() {
        return { userId: '' };
      }
    },
    created: {
      type: String,
      default: '2022-01-01T20:20:00.000Z'
    }
  },
  data() {
    return {
      noForumReplies: false,
      isOpen: true,
      createMessageVisible: false,
      projectDescriptionIsExpandable: false,
      isRecommendationOpen: false
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('page', ['isMobileLayout', 'isSidebarVisible']),
    ...mapGetters('projectForum', ['getProjectThreadNumberOfViews']),
    ...mapState('projectForum', ['cities', 'countries', 'industries', 'thread', 'guests']),
    ...mapState('page', ['isSmartphoneWidth']),
    breadcrumbItems(): Array<any> {
      const breadcrumbItems: Array<any> = [
        {
          text: this.$t('project-forum.labels.projects'),
          to: { name: 'projectList' }
        },
        {
          text: this.project.title
        }
      ];
      if (this.isMobileLayout) {
        // display logo as first item
        breadcrumbItems.unshift({ text: 'ByteCookie' });
        breadcrumbItems[2].text = truncate(this.project.title, { length: 32 });
      }
      return breadcrumbItems;
    },
    isProjectClosed(): boolean {
      return this.project.status === 'CLOSED';
    },
    initialMessageId(): string {
      return this.thread.initialMessage.messageId;
    },
    flagged(): boolean {
      return this.project.userMetaDataProject.flagged || false;
    },
    voteResult(): number {
      return this.project.metaDataVoting.voteResult;
    },
    userVoting(): string {
      return this.project.userMetaDataVoting.userVoted;
    },
    messageEdited(): boolean {
      return this.thread.initialMessage.edited;
    },
    labelViews(): string {
      if (this.getProjectThreadNumberOfViews === 1) {
        return this.$t('forum.labels.views.singular') as string;
      }
      return this.$t('forum.labels.views.plural') as string;
    },
    projectStatus(): string {
      return this.$t(`project-forum.labels.status.${this.project.status}`) as string;
    },
    projectDescription(): string {
      return this.project.descriptionLong ?? '';
    },
    userIsCreator(): boolean {
      return this.user.userId === this.project.creator.userId;
    },
    showOpenCloseProject(): boolean {
      return this.userIsCreator || this.user.roles.includes('PROJECT_MODERATOR') || this.user.roles.includes('FORUM_MODERATOR');
    },
    userCanEdit(): boolean {
      return this.userIsCreator || this.user.roles.includes('FORUM_MODERATOR') || this.user.roles.includes('PROJECT_MODERATOR');
    },
    backLink(): any {
      return { name: 'projectList' };
    },
    createMessageAttachedTo(): string {
      if (this.isMobileLayout) {
        return `#${this.$root.$el.id}`;
      }
      return '.create-message-block';
    },
    projectStatusIndicatorClass(): string {
      if (this.project.status === 'ACTIVE') {
        return 'project-status--open';
      }
      if (this.project.status === 'HOLD') {
        return 'project-status--hold';
      }
      return 'project-status--closed';
    },
    avatarSize(): number {
      return this.isMobileLayout ? 50 : 60;
    },
    createdTimestamp(): string {
      return this.formatDate(this.created);
    },
    clientValue(): any {
      return {
        countryCode: this.project.city.countryCode,
        placeName: this.project.city.placeName,
        postalCode: this.project.city.postalCode,
        industryTag: this.project.industryTagId,
        countryList: this.countries,
        cityList: this.cities,
        companySizeValue: this.project.companySize,
        industryList: this.industries
      };
    },
    conditionsValue(): any {
      return {
        locationValue: this.project.projectLocation,
        remoteWorkValue: this.project.remoteWork,
        teamSizeValue: this.project.teamSize,
        startDateValue: this.project.startDate,
        durationAmountValue: this.project.durationAmount,
        durationUnitValue: this.project.durationUnit,
        hoursPerWeekValue: this.project.hoursPerWeek
      };
    },
    innerSectionsClient(): any {
      const sections = [];
      if (this.project.city.placeName) {
        sections.push({
          title: this.$t('project-forum.form-section.client.office.label'),
          text: this.project.city.placeName
        });
      }
      if (this.project.companySize) {
        sections.push({
          title: this.$t('project-forum.form-section.client.company-size.label'),
          text: this.$t(`project-forum.form-section.client.company-size.dropdown.${this.project.companySize}`)
        });
      }
      if (this.project.industryTagId?.tagName) {
        sections.push({
          title: this.$t('project-forum.form-section.client.industry.label'),
          text: this.project.industryTagId.tagName
        });
      }
      return sections;
    },
    innerSectionsConditions(): Array<any> {
      const projectDurationUnitText = this.$t(`project-forum.form-section.conditions.duration.dropdown.${this.project.durationUnit}`) as string;
      const projectConditions = [];
      if (this.project.projectLocation) {
        projectConditions.push({
          title: this.$t('project-forum.form-section.conditions.location.label'),
          text: this.$t(`project-forum.form-section.conditions.location.dropdown.${this.project.projectLocation}`)
        });
      }
      if (this.project.remoteWork && this.project.projectLocation === 'ONSITE_AND_REMOTE') {
        projectConditions.push({
          title: this.$t('project-forum.form-section.conditions.remote-work.label'),
          text: this.$t(`project-forum.form-section.conditions.remote-work.values.${this.project.remoteWork}`)
        });
      }
      if (this.project.teamSize) {
        projectConditions.push({
          title: this.$t('project-forum.form-section.conditions.team-size.label'),
          text: this.project.teamSize
        });
      }
      if (this.project.startDate) {
        projectConditions.push({
          title: this.$t('project-forum.form-section.conditions.start-date.label'),
          text: this.formatDate(this.project.startDate, 'short')
        });
      }
      if (this.project.durationAmount && this.project.durationUnit) {
        projectConditions.push({
          title: this.$t('project-forum.form-section.conditions.duration.label'),
          text: `${this.project.durationAmount} ${projectDurationUnitText}`
        });
      }
      if (this.project.hoursPerWeek) {
        projectConditions.push({
          title: this.$t('project-forum.form-section.conditions.hours-per-week.label'),
          text: this.project.hoursPerWeek
        });
      }
      return projectConditions;
    },
    projectTitleText(): string {
      if (this.project.metaDataProject.testFlag) {
        return `[TEST] ${this.project.title}`;
      }
      return this.project.title;
    },
    isTestProject(): boolean {
      return this.project.metaDataProject.testFlag || false;
    },
    messageTextTemplate(): string {
      return this.$t('project-forum.templates.reply-comment') as string;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchCountries().then(() => {
        this.fetchCities({ countryId: this.project.city.countryCode, searchString: this.project.city.placeName });
      });
      this.fetchIndustries();
      setTimeout(() => {
        this.setDescriptionExpandable();
      }, 200);
    });
    window.addEventListener('resize', () => {
      this.setDescriptionExpandable();
    });
  },
  methods: {
    ...mapActions('projectForum', ['fetchCountries', 'fetchCities', 'fetchIndustries', 'updateProject', 'postThreadMessage']),
    ...mapActions('subscriptions', ['fetchSubscriptions']),
    setDescriptionExpandable() {
      const projectDescriptionEl: Element = this.$refs.projectDescriptionEl as Element;
      if (projectDescriptionEl && projectDescriptionEl.clientHeight > 90) {
        this.projectDescriptionIsExpandable = true;
      }
    },
    formatDate(timestamp: string, dateformat: 'short' | 'diff' = 'diff'): string {
      const { getFormattedTimeByAge, getFormattedDate } = useFormattedDateTime();
      if (dateformat === 'diff') return getFormattedTimeByAge(timestamp);
      return getFormattedDate(timestamp);
    },
    toggleCreateMessageVisible() {
      this.createMessageVisible = !this.createMessageVisible;
    },
    saveTitle(data: { title: string; testFlag: boolean }) {
      this.saveEdit({ ...data });
    },
    saveDescription(description: string) {
      this.saveEdit({ descriptionLong: BCXHTMLSanitizer.beforeSave(description) });
    },
    saveClient(clientData: any) {
      this.saveEdit({
        cityPlacename: clientData.cityPlaceName,
        countryId: clientData.countryCode,
        cityPostalCode: clientData.cityPostalCode,
        companySize: clientData.companySize,
        industryTagId: clientData.industry
      });
    },
    saveConditions(conditions: any) {
      // check for empty strings and set to 0 if so
      if (conditions.durationAmount === '') conditions.durationAmount = 0;
      if (conditions.hoursPerWeek === '') conditions.hoursPerWeek = 0;
      if (conditions.teamSize === '') conditions.teamSize = 0;

      this.saveEdit({
        durationAmount: conditions.durationAmount,
        durationUnit: conditions.durationUnit,
        projectLocation: conditions.location,
        remoteWork: conditions.remoteWork,
        teamSize: conditions.teamSize,
        startDate: conditions.startDate,
        hoursPerWeek: conditions.hoursPerWeek
      });
    },
    saveGuests(guests: any) {
      this.$store.dispatch('projectForum/updateProjectGuests', { projectId: this.project.externalId, guests });
    },
    closeProject() {
      this.saveEdit({ status: 'CLOSED' });
    },
    reopenProject() {
      this.saveEdit({ status: 'ACTIVE' });
    },
    saveEdit(updateObject: any) {
      const project: PostProject = {
        externalId: this.project.externalId,
        title: this.project.title,
        descriptionLong: this.project.descriptionLong.trim(),
        status: this.project.status,
        testFlag: this.project.metaDataProject.testFlag,
        type: this.project.type,
        paymentType: this.project.paymentType,
        cityPostalCode: this.project.city.postalCode,
        cityPlacename: this.project.city.placeName,
        countryId: this.project.city.countryCode,
        companySize: this.project.companySize,
        hourlyRateInEUR: this.project.hourlyRateInEUR,
        startDate: this.project.startDate,
        startDateLatest: this.project.startDateLatest,
        optionToExtendDuration: this.project.optionToExtendDuration,
        remotePercentage: this.project.remotePercentage,
        companyId: '',
        projectLocation: this.project.projectLocation,
        remoteWork: this.project.remoteWork,
        teamSize: this.project.teamSize,
        industryTagId: this.project.industryTagId?.tagId,
        durationAmount: this.project.durationAmount,
        durationUnit: this.project.durationUnit,
        hoursPerWeek: this.project.hoursPerWeek,
        ...updateObject
      };
      this.updateProject(project);
    },
    postMessage(message: string) {
      const postMessage: MessagePost = {
        forumId: this.thread.forumId,
        forumThreadId: this.thread.threadId,
        messageContent: message,
        replyToMessageId: this.thread.initialMessage.messageId,
        replyToUserId: this.project.creator?.userId as string
      };
      this.postThreadMessage(postMessage).then(() => {
        this.createMessageVisible = false;
        this.$emit('increase-replies', true);
        this.fetchSubscriptions();
      });
    }
  }
});
