
import Vue, { ref, watch } from 'vue';
import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import ProfileEditActionButtons from '@/views/profile/components/ProfileEditActionButtons.vue';
import UploadableItem from '@/components/molecules/UploadableItem.vue';
import { updateExperiences } from '@/services/Profile/Api';
import useProfileState from '@/state/profile/profileState';
import { ProfileMaxChars } from '@/models/Profile';
import sanitizeText from '@/views/profile/utils/sanitizeText';
import ProfileRichTextEditor from '@/views/profile/components/ProfileRichTextEditor.vue';

export default Vue.extend({
  name: 'ProfileProjectsEditor',
  components: {
    ProfileRichTextEditor,
    UploadableItem,
    ProfileEditActionButtons,
    BCXInfoSection,
  },
  setup() {
    const { profile } = useProfileState();
    const text = ref('');
    const title = ref('');
    const fileName = ref('');
    const fileContents = ref('');
    const initialTitle = ref('');
    const canSend = ref(false);

    watch(
      profile,
      (profile) => {
        const { experiencesDocumentLabel = '', experiencesText = '' } = profile?.experiences ?? {};
        text.value = sanitizeText(experiencesText);
        title.value = experiencesDocumentLabel;
        initialTitle.value = title.value;
      },
      { immediate: true }
    );

    const save = async () => {
      const responseData = await updateExperiences({
        experiencesDocument: fileContents.value,
        experiencesText: text.value, // editor.value.invoke('getHTML'),
        experiencesDocumentLabel: title.value || fileName.value
      });
      text.value = responseData.experiencesText;
      // editor.value.invoke('setHTML', responseData.experiencesText);
    };
    return {
      canSend,
      text,
      title,
      fileName,
      fileContents,
      save,
      initialTitle,
      ProfileMaxChars,
    };
  }
});
