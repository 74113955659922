export enum LegalType {
  LegalTerms = 'LEGAL_TERMS',
  Privacy = 'PRIVACY',
}

export enum EmailNotificationInterval {
  Immediately = 'IMMEDIATELY',
  DailyTwice = 'DAILY_TWICE',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  WeeklyTwice = 'WEEKLY_TWICE',
  Never = 'NEVER',
}

export enum EmailNotificationType {
  IncomingMessages = 'PERSONAL_MESSAGING',
  Subscriptions = 'SUBSCRIPTION_NEWS',
  Newsletter = 'NEWSLETTER',
  Announcements = 'GLOBAL_NOTIFICATION'
}

export enum Security2FaMethodType {
  Email = 'EMAIL',
  SMS = 'SMS'
}

export enum Security2FaUsageType {
  Important = 'IMPORTANT_ACCOUNT_CHANGES'
}

export interface SettingsLegal {
  acceptedDate: string;
  type: LegalType;
  version: string;
}

export interface SettingsNotifications {
  emailNotificationType: EmailNotificationType;
  emailNotificationInterval: EmailNotificationInterval;
}

export interface SettingsNotificationRequest {
  emailNotificationList: SettingsNotifications[];
}

export interface SettingsPlatform {
  platformLocale: string;
}

export interface SettingsSecurity {
  security2FaMethod: Security2FaMethodType;
  security2FaUsage: Security2FaUsageType;
  mobilephoneNumber?: string;
}

export interface SettingsUserData {
  firstname: string;
  lastname: string;
  salutation?: string;
  primaryEmail: string;
}

export interface SettingsConsentObject {
  legalTerms: SettingsLegal;
  privacy: SettingsLegal;
}

export interface Settings {
  consentObjectSettings: SettingsConsentObject;
  notificationSettings: SettingsNotifications[];
  platformSettings: SettingsPlatform;
  securitySettings: SettingsSecurity;
  userDataSettings: SettingsUserData;
}

// Requests, etc.:

export enum SettingsVerificationType {
  FirstAndLastName = 'FIRST_AND_LASTNAME',
  Password = 'PASSWORD',
  Email = 'EMAIL',
  MobilePhone = 'MOBILEPHONE',
  Security2FAMethod = 'SECURITY_2FAMETHOD',
  Security2FaUsage = 'SECURITY_2FAUSAGE',
  DeleteAccount = 'DELETE_ACCOUNT',
}

export interface SettingsUserDataRequest {
  firstname: string;
  lastname: string;
}

export interface SettingsNewPasswordRequest {
  oldPassword: string;
  newPassword: string;
}

export interface SettingsEmailRequest {
  newEmail: string;
}

export interface Settings2FAMethodRequest {
  newSecurity2FaMethod: Security2FaMethodType;
}

export interface SettingsMobilePhoneRequest {
  newMobilephone: string;
}

export interface Settings2FAUsageRequest {
  newSecurity2FaUsage: Security2FaUsageType;
}
