
import BCXQuickLayer from '@/components/molecules/BCXQuickLayer.vue';
import X from '@/components/svg-components/X.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import useQuickInfo from '@/state/quickInfo';
import ProfileQuickInfo from '@/views/profile/ProfileQuickInfo.vue';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'QuickInfoLayer',
  components: {
    BCXQuickLayer,
    X,
    ProfileQuickInfo
  },
  setup() {
    const {
      quickInfoUser, setQuickInfoUser, target, quickInfoX, quickInfoY
    } = useQuickInfo();
    const { isMobileWidth } = useResponsiveness();

    const showMore = ref(false);
    const isClosing = ref(false);

    const close = () => {
      isClosing.value = true;
    };
    const onHasDisappeared = () => {
      setQuickInfoUser(null);
      isClosing.value = false;
    };

    const onShowProfile = () => {
      showMore.value = false;
    };

    const isOpen = computed(() => !!(quickInfoUser.value && !isClosing.value));

    return {
      quickInfoUser,
      close,
      onHasDisappeared,
      isMobileWidth,
      showMore,
      onShowProfile,
      isOpen,
      target,
      quickInfoX,
      quickInfoY
    };
  }
});
