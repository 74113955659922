import { Module } from 'vuex';
import router from '@/router';
import PasswordResetService from '@/services/PasswordResetService';

interface State {
  email: string;
}

const module: Module<State, any> = ({

  state: {
    email: localStorage.getItem('email') || '',
  },
  getters: {
    email: (state) => state.email,
  },
  mutations: {
    updatePasswordReset(state, data) {
      state.email = data.email;
      localStorage.setItem('email', data.email);
    },
  },
  actions: {
    resetPassword({ commit }, email) {
      return new Promise<void>((resolve, reject) => {
        PasswordResetService.resetPassword(email)
          .then(() => {
            commit('updatePasswordReset', { email });
            router.push({ name: 'password-reset-confirmation' });
            resolve();
          })
          .catch((error) => reject(error));
      });
    },
  },
});

export default module;
