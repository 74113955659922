var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"el",staticClass:"bcx-pointer",on:{"click":function($event){return _vm.showQuickInfo()}}},[_c('div',{staticClass:"c-user-avatar",class:[
      `avatar-size--${_vm.size}`,
      {
        'show-online-indicator': _vm.showOnlineIndicator,
        'is-online': _vm.showUserAsOnline,
        'use-native-image-scaling': _vm.useNativeBrowserScaling
      }
    ]},[_c('div',{staticClass:"online-indicator",style:(_vm.imageBorderClassObject)},[_vm._t("default",function(){return [(_vm.avatarImageData === _vm.LOADING)?void 0:(_vm.avatarImageData)?_c('img',{key:_vm.avatarImageData,staticClass:"avatar-image",attrs:{"src":_vm.avatarImageData,"width":_vm.size,"height":_vm.size,"alt":"avatar"},on:{"dragstart":function($event){$event.preventDefault();}}}):_c('div',{staticClass:"avatar-fallback"},[_c('FallbackAvatar',{staticClass:"avatar-fallback-img",attrs:{"width":_vm.size,"height":_vm.size}})],1)]})],2),(_vm.showCookies)?_vm._l((_vm.displayedAmountOfCookies),function(cookie){return _c('Cookie',{key:cookie,staticClass:"c-user-avatar-cookie",class:{'cookie--16': _vm.size === 40},attrs:{"size":_vm.cookieSize}})}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }