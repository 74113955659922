import { Ability } from '@/models/Ability';
import ability from './index';
import Permissions from './permissions';

export function updatePermissions(roles: string[], permissions: string[]) {
  const allPermissions = [] as Ability[];

  localStorage.setItem('user_roles', JSON.stringify(roles));
  roles.forEach((role) => {
    allPermissions.push(...Permissions.rolePermissions[role] ?? []);
  });

  localStorage.setItem('user_permissions', JSON.stringify(permissions));
  permissions.forEach((permission) => {
    allPermissions.push(...Permissions.additionalPermissions[permission] ?? []);
  });

  ability.update(allPermissions);
}

export function checkRoutePermissions(requiredPermissions: Ability[]): boolean {
  let result = false;

  requiredPermissions.forEach((permission) => {
    if (ability.can(permission.action, permission.subject)) result = true;
  });
  // console.log('result', result);
  return result;
}
