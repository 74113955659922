
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXChevronSwitch from '@/components/molecules/BCXChevronSwitch.vue';
import BCXLinkedUser from '@/components/molecules/BCXLinkedUser.vue';
import Smiley from '@/components/svg-components/Smiley.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import { ExternalRecommendation, feedBackType2SmileyType } from '@/models/Recommendations';
import RecommendationsFeedbackCard from '@/views/recommendations/components/RecommendationsFeedbackCard.vue';
import Vue, { defineComponent, PropType, reactive } from 'vue';

export default defineComponent({
  name: 'MyRecommendationsTable',
  components: {
    BCXLinkedUser,
    BCXChevronSwitch,
    RecommendationsFeedbackCard,
    Smiley,
    BCXAvatar
  },
  props: {
    recommendations: {
      type: Array as PropType<ExternalRecommendation[]>,
      default: () => []
    }
  },
  setup() {
    const isFeedbackOpened = reactive <Record<string|number, boolean | undefined>>({});
    const { getFormattedDate } = useFormattedDateTime();

    const toggleOpened = (i: number | string) => {
      if (isFeedbackOpened[i] === undefined) {
        Vue.set(isFeedbackOpened, i, true);
      } else {
        isFeedbackOpened[i] = !isFeedbackOpened[i];
      }
    };

    return {
      toggleOpened,
      isFeedbackOpened,
      feedBackType2SmileyType,
      getFormattedDate
    };
  }
});
