
import useRouterIgnoreDuplicate from '@/mixins/useRouterIngoreDuplicate';
import { markdown } from '@/utils/markdown/markdown';
import {
  defineComponent, nextTick, onMounted, PropType, ref, toRefs, watch
} from 'vue';
import { templateRef, useEventListener } from '@vueuse/core';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';

export default defineComponent({
  name: 'BCXMarkdown',
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    text: {
      type: String,
      default: ''
    },
    stripTags: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String as PropType<'restricted' | 'normal' | 'nolinks'>,
      default: 'normal'
    },
    unstyled: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const { text, stripTags, mode } = toRefs(props);

    const el = templateRef<HTMLElement>('el');

    const { pushRoute } = useRouterIgnoreDuplicate();

    useEventListener(el, 'click', (evt) => {
      const target: HTMLElement | null = (evt.target as HTMLAnchorElement).closest('a[data-markdown-event]');
      if (!target) return;

      const { markdownEvent, markdownEventDetail } = target.dataset ?? {};
      if (markdownEvent) {
        if (markdownEvent === 'router-go' && markdownEventDetail) {
          pushRoute(markdownEventDetail);
        } else {
          emit(markdownEvent, markdownEventDetail);
        }
        // window.dispatchEvent(new CustomEvent(markdownEvent, { detail: markdownEventDetail }));
        evt.preventDefault();
        evt.stopPropagation();
      }
    });

    const viewer = ref<Viewer | null>(null);

    const setMarkdown = (mdText: string) => {
      if (viewer.value) {
        if (stripTags.value) {
          viewer.value.setMarkdown(mdText.replaceAll(/(<\/?(strong|b|i|em|del[^>]+)>)/ig, '').replaceAll(/(<([^>]+)>)/ig, ' ').substring(0, 75).replaceAll(/[\n]+|[\s]{2,}/gi, ' '));
        } else {
          viewer.value.setMarkdown(mdText);
        }
      }
    };

    watch(() => el.value, (el) => {
      if (el) {
        el.firstElementChild?.removeAttribute('class');
      }
    }, { immediate: true });

    watch(() => text.value, (mdText) => {
      setMarkdown(mdText);
    });

    onMounted(() => {
      if (el.value) {
        el.value.firstElementChild?.removeAttribute('class');
      }
      viewer.value = markdown(el.value, mode.value);
      setMarkdown(text.value);
    });

    return {
      el
    };
  }
});
