

import useI18n from '@/mixins/useI18n';
import useResponsiveness from '@/mixins/useResponsiveness';
import { ProfileStoreKey } from '@/models/Profile';
import useProfileState from '@/state/profile/profileState';
import { DELETED_USER_FULLNAME } from '@/state/quickInfo';
import Vue, {
  computed, onMounted, provide, watch
} from 'vue';
import ProfileFreelancer from '@/views/profile/components/profile-types/ProfileFreelancer.vue';
import ProfileGuest from '@/views/profile/components/profile-types/ProfileGuest.vue';
import ProfileCompany from '@/views/profile/components/profile-types/ProfileCompany.vue';
import { UserType } from '@/models/User';
import { USER_TYPE_FAKE } from '@/models/Faking';
import { useRouter } from 'vue2-helpers/vue-router';

export default Vue.extend({
  name: 'Profile',
  components: {
    ProfileCompany,
    ProfileGuest,
    ProfileFreelancer,
  },
  setup() {
    const {
      init, isSelf, user, profile, isEditSectionOpen, isSectionFilled, isQuickInfo, isLoading
    } = useProfileState(ProfileStoreKey.PROFILE);
    const { t } = useI18n();
    const router = useRouter();
    const { isMobileWidth } = useResponsiveness();
    init();

    const breadcrumbs = computed(() => [
      {
        text: 'ByteCookie',
        to: {
          name: 'home',
        },
      },
      {
        text: isSelf.value ? t('profile.common.profile') : user.value?.fullname ?? '?',
      },
    ]);

    provide('breadcrumbs', breadcrumbs);

    const showProfile = computed(() => !!user.value && !!profile.value);

    const userType = computed(() => user.value?.type ?? USER_TYPE_FAKE);

    const checkForDeletedUser = async () => {
      const ivUserLoaded = setInterval(() => {
        if (!isLoading.value) {
          clearInterval(ivUserLoaded);
          if ((user.value?.fullname ?? '').trim().toLowerCase() === DELETED_USER_FULLNAME) {
            router.push({ name: 'home' });
          }
        }
      }, 250);
    };

    watch(() => user.value?.fullname, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        checkForDeletedUser();
      }
    });

    onMounted(() => {
      checkForDeletedUser();
    });

    return {
      isLoading,
      isMobileWidth,
      breadcrumbs,
      isEditSectionOpen,
      showProfile,
      isSelf,
      profile,
      isSectionFilled,
      isQuickInfo,
      userType,
      UserType
    };
  },
});
