import { NavigationTreeItems } from '@/models/Navigation';
import { traverseNavigationTreeItems } from '@/navigation/traverseNavigationTreeItems';
import { reactive } from 'vue';
import _navigation from './navigation.yaml';

let id = 1;
const prepareNavigation = ():NavigationTreeItems => {
  traverseNavigationTreeItems(_navigation, (item, level, key, parent) => {
    item._id = id++;
    item._opened = false;
    item._active = false;
    item._key = key;
    item._level = level;
    item._parent = parent;
  });
  return _navigation as NavigationTreeItems;
};

const navigation = reactive(prepareNavigation());

export default navigation;
