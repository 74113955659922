import SubscriptionService from '@/services/SubscriptionService';
import { Module } from 'vuex';
import {
  PostSubscription, Subscription, SubscriptionListSort, SubscriptionFeedEntry, subscriptionFeedFilter
} from '@/views/subscriptions/models/Subscription';
import {
  filter, keyBy, orderBy
} from 'lodash';

interface State {
  subscriptionList: Subscription[];
  subscriptionListSort: SubscriptionListSort;
  subscriptionFeed: SubscriptionFeedEntry[];
  subscriptionFeedFilter: subscriptionFeedFilter;
  loadingFeed: boolean;
  loadingSubscriptions: boolean;
}

const module: Module<State, any> = {
  namespaced: true,
  state: {
    subscriptionList: [],
    subscriptionListSort: {
      sortBy: 'TIMESTAMP',
      sortOrder: 'DESC'
    },
    subscriptionFeed: [],
    subscriptionFeedFilter: 'ALL',
    loadingFeed: true,
    loadingSubscriptions: true,
  },
  getters: {

    getFilteredSubscriptionFeed: (state: State) => state.subscriptionFeed.filter((subscription) => {
      switch (state.subscriptionFeedFilter) {
        case 'ALL':
          return true;
        case 'TALKS':
          return subscription.type.includes('FORUM_TALK_');
        case 'PROJECTS':
          return subscription.type.includes('PROJECT');
        default:
          return false;
      }
    }),

    getSubscriptionsByObjectId: ({ subscriptionList }) => keyBy(subscriptionList, 'subscribedObjectIdentifier'),

    getSubsciptionListSorted: (state: State) => {
      const { sortBy } = state.subscriptionListSort;
      const sortOrder: 'asc' | 'desc' = state.subscriptionListSort.sortOrder.toLowerCase() as 'asc' | 'desc';

      switch (sortBy) {
        case 'TITLE':
          return orderBy(state.subscriptionList,
            [(subscription) => (subscription.title === 'PROJECTS' ? 'all projects' : subscription.title.toLowerCase())],
            sortOrder);
        case 'HOW':
          return orderBy(state.subscriptionList,
            (subscription) => {
              let { title, createdType } = subscription;
              if (title === 'PROJECTS') {
                title = 'all projects';
              }
              if (createdType === 'SYSTEM') {
                createdType = 'AUTO';
              }
              return [createdType, title.toLowerCase()];
            },
            [sortOrder, 'asc']);
        case 'WHAT':
          return orderBy(state.subscriptionList, (subscription) => {
            let { title } = subscription;
            const { type } = subscription;
            let sortType: string;

            if (title === 'PROJECTS') {
              title = 'all projects';
            }
            if (type === 'FORUM_TALK') {
              sortType = 'Talk';
            } else if (type === 'FORUM_TALKS') {
              sortType = 'Talks';
            } else {
              sortType = 'Project';
            }
            return [sortType, title.toLowerCase()];
          },
          [sortOrder, 'asc']);
        case 'TIMESTAMP':
        default:
          return state.subscriptionList;
      }
    }

  },
  mutations: {
    setSubscriptionsList(state, subscriptionsList: Subscription[]) {
      state.subscriptionList = subscriptionsList;
    },
    setSubscriptionFeed(state, subscriptionFeed: SubscriptionFeedEntry[]) {
      state.subscriptionFeed = subscriptionFeed;
    },
    setSubscriptionFeedFilter(state, filter: subscriptionFeedFilter) {
      state.subscriptionFeedFilter = filter;
    },
    setSubscriptionListSort(state, sort: SubscriptionListSort) {
      state.subscriptionListSort = sort;
    },
    setLoadingFeed(state, loadingStatus) {
      state.loadingFeed = loadingStatus;
    },
    setLoadingSubscriptions(state, loadingStatus) {
      state.loadingSubscriptions = loadingStatus;
    },
    removeSubscription(state, subscriptionId) {
      state.subscriptionList = filter(state.subscriptionList, (subscription) => subscription.subscriptionId !== subscriptionId);
    }
  },
  actions: {

    fetchSubscriptions({ commit }) {
      commit('setLoadingSubscriptions', true);
      SubscriptionService.getAllSubscriptions().then((subscriptionList) => {
        commit('setSubscriptionsList', subscriptionList.subscriptionList);
      }).finally(() => {
        commit('setLoadingSubscriptions', false);
      });
    },

    fetchSubscriptionFeed({ commit }) {
      commit('setLoadingFeed', true);
      SubscriptionService.getSubscriptionFeed().then((subscriptionFeed) => {
        commit('setSubscriptionFeed', subscriptionFeed.feedEntryList);
      }).finally(() => {
        commit('setLoadingFeed', false);
      });
    },

    postSubscription({ commit }, postSubscripiton: PostSubscription) {
      SubscriptionService.postSubscriptions(postSubscripiton).then(() => {
        this.dispatch('subscriptions/fetchSubscriptions');
      });
    },

    deleteSubscription({ commit }, subscriptionId: string) {
      SubscriptionService.deleteSubscription(subscriptionId).then(() => {
        commit('removeSubscription', subscriptionId);
      });
    },

    clearSubscriptionFeed({ commit }) {
      commit('setSubscriptionFeed', []);
    },

    clearSubscriptionsList({ commit }) {
      commit('setSubscriptionsList', []);
    },

    filterSubscriptionFeed({ commit }, filter: subscriptionFeedFilter) {
      commit('setSubscriptionFeedFilter', filter);
    },

    setSubscriptionListSort({ commit }, sort: SubscriptionListSort) {
      commit('setSubscriptionListSort', sort);
    },
    setLoadingFeed({ commit }, stateLoading: boolean) {
      commit('setLoadingFeed', stateLoading);
    },
    setLoadingSubscriptions({ commit }, stateLoading: boolean) {
      commit('setLoadingSubscriptions', stateLoading);
    }
  },
};

export default module;
