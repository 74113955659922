import useI18n from '@/mixins/useI18n';
import Autolinker from 'autolinker';
import DOMPurify from 'dompurify';
import { logDebug } from './logger';

const BCXHTMLSanitizer = {
  beforeSave(dirty: string): string {
    logDebug('Sanitize before save');
    return DOMPurify.sanitize(dirty, {
      FORBID_ATTR: ['style'],
      FORBID_TAGS: ['style', 'div'],
    });
  },

  beforeRenderEditor(dirty: string): string {
    return DOMPurify.sanitize(dirty, {
      FORBID_ATTR: ['style'],
      FORBID_TAGS: ['style', 'div'],
      USE_PROFILES: { html: true },
    });
  },

  beforeRenderMarkdown(dirty: string): string {
    const autolinked = BCXHTMLSanitizer.linkSanitizer(
      BCXHTMLSanitizer.autoLinkNodes(
        new DOMParser().parseFromString(dirty, 'text/html').body
      ).toString()
    );
    return DOMPurify.sanitize(autolinked);
  },

  autoLinkNodes(node: HTMLElement) {
    const { t } = useI18n();
    return Autolinker.link(node.innerHTML, {
      stripPrefix: false,
      stripTrailingSlash: false,
      phone: false,
      mention: false,
      hashtag: false,
      replaceFn: (match: any) => {
        const tag = match.buildTag();
        switch (match.getType()) {
          case 'url':
            if (tag.attrs.href.match(/^(\/.+|https?:\/\/(?:(?:www\.)?stage\.|www\.)?(bytecookie\.net|localhost:[\d]{4})?(\/.+))$/i)) {
              let href = tag.getAttr('href');
              if (href.startsWith('http')) {
                href = href.replace(/^(https?:\/\/(?:(?:www\.)?stage\.|www\.)?(bytecookie\.net|localhost:[\d]{4})?)/i, '');
              }
              tag.setAttr('data-markdown-event', 'router-go');
              tag.setAttr('data-markdown-event-detail', href);
              tag.setAttr('title', '');
            } else {
              tag.setAttr('target', '_blank');
              tag.setAttr('title', (t('common.externalLink') as string));
              tag.setAttr('rel', 'noopener noreferrer');
            }
            break;
          default:
            return match.getAnchorText();
        }
        return tag;
      },
      decodePercentEncoding: true,
    });
  },

  linkSanitizer(str: string) {
    const { t } = useI18n();
    const node = document.createElement('div');
    const editLinks = (node: HTMLElement | ChildNode) => {
      [...node.childNodes].forEach((node) => {
        const innerNode = (node as HTMLElement);
        if (node.nodeType === 1) {
          editLinks(node);
          const regexByteCookieURL = new RegExp(/^(\/.+|https?:\/\/(?:(?:www\.)?stage\.|www\.)?(bytecookie\.net|localhost:[\d]{4})?(\/.+))$/i);

          switch (node.nodeName) {
            case 'A': {
              innerNode.removeAttribute('target');
              innerNode.removeAttribute('rel');
              innerNode.removeAttribute('ping');
              innerNode.removeAttribute('media');
              innerNode.removeAttribute('download');
              innerNode.removeAttribute('title');

              const href = innerNode.getAttribute('href') ?? '';

              if (regexByteCookieURL.test(href)) {
                const newHref = href.startsWith('http')
                  ? href.replace(/^(https?:\/\/(?:(?:www\.)?stage\.|www\.)?(bytecookie\.net|localhost:[\d]{4})?)/i, '')
                  : href;
                innerNode.setAttribute('data-markdown-event', 'router-go');
                innerNode.setAttribute('data-markdown-event-detail', newHref);
                innerNode.classList.add('md-restricted-link');
              } else if (/^mailto:.+/.test(href)) {
                innerNode.removeAttribute('data-markdown-event-detail');
                innerNode.removeAttribute('data-markdown-event');
              } else if (/javascript/i.test(href)) {
                innerNode.removeAttribute('href');
              } else {
                if (!href.startsWith('@')) {
                  innerNode.setAttribute('target', '_blank');
                  innerNode.setAttribute('rel', 'noopener noreferrer');
                  innerNode.setAttribute('title', t('common.externalLink').toString());
                }
                innerNode.classList.add('md-restricted-link');
              }
            // node = innerNode;
            }
              break;
            default:
              node = innerNode;
              break;
          }
        }
      });
    };
    node.innerHTML = str;
    editLinks(node);
    return node.innerHTML;
  }

};

export default BCXHTMLSanitizer;
