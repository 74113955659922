import Vue, { watch } from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

import { merge } from 'lodash';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.yaml$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/(en|de)\//i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = merge(messages[locale], locales(key));
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') ?? (process.env.VUE_APP_I18N_LOCALE || 'de'),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});

export function setDocumentLanguage(lang: string) {
  document.querySelector('html')?.setAttribute('lang', lang);
}
watch(() => i18n.locale, (locale) => {
  setDocumentLanguage(locale);
});

export default i18n;
