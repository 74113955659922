
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import Close from '@/components/svg-components/X.vue';
import useFormattedDateTime from '@/mixins/useFormattedDateTime';
import useI18n from '@/mixins/useI18n';
import { ForumMaxNum } from '@/views/forum/models/Forum';
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  components: {
    'bc-search-select': BCXSearchSelect,
    Close,
  },
  props: {
    locationValue: {
      type: String,
      default: 'REMOTE_ONLY',
    },
    remoteWorkValue: {
      type: String,
      default: 'TWO_DAYS_PER_WEEK',
    },
    teamSizeValue: {
      type: Number,
      default: 1,
    },
    startDateValue: {
      type: String,
      default: (new Date()).toISOString().split('T')[0],
    },
    durationAmountValue: {
      type: Number,
      default: 3,
    },
    durationUnitValue: {
      type: String,
      default: 'MONTH',
    },
    hoursPerWeekValue: {
      type: Number,
      default: 40,
    },
    isCreatingProject: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      location: 'REMOTE_ONLY',
      remoteWork: '',
      teamSize: 3,
      startDate: '',
      durationAmount: 3,
      durationUnit: 'MONTH',
      hours: 40,
      menuStartDate: false,
      maxHours: ForumMaxNum.PROJECT_HOURS,
      maxTeamSize: 999,
      maxProjectDuration: ForumMaxNum.PROJECT_DURATION,
    };
  },
  computed: {
    ...mapState('page', ['isSmartphoneWidth']),
    locationItems(): Array<any> {
      return this.getDropdownItems('location');
    },
    remoteWorkItems(): Array<any> {
      return this.getDropdownItems('remote-work');
    },
    durationUnitItems(): Array<any> {
      return this.getDropdownItems('duration');
    },
    datepickerSettings(): any {
      return {
        locale: 'de-de',
        'first-day-of-week': '1',
        min: (new Date()).toISOString().split('T')[0],
        flat: true,
        'locale-first-day-of-year': 4,
      };
    },
    startDateFormatted(): string {
      let date: string = this.startDate;
      if (date === '') {
        date = new Date().toString();
      }
      return this.formatDate(date, 'short');
    },
    remoteWorkVisible(): boolean {
      return this.location === 'ONSITE_AND_REMOTE';
    },
  },
  watch: {
    location() {
      this.$nextTick(() => {
        this.pushValue();
      });
    },
    remoteWork() {
      this.$nextTick(() => {
        this.pushValue();
      });
    },
    teamSize(value) {
      if (value > this.maxTeamSize) {
        this.teamSize = this.maxTeamSize;
      }
      this.$nextTick(() => {
        this.pushValue();
      });
    },
    startDate() {
      this.$nextTick(() => {
        this.pushValue();
      });
    },
    durationAmount(value) {
      if (value > this.maxProjectDuration) {
        this.durationAmount = this.maxProjectDuration;
      }
      this.$nextTick(() => {
        this.pushValue();
      });
    },
    durationUnit() {
      this.$nextTick(() => {
        this.pushValue();
      });
    },
    hours(value) {
      if (value > this.maxHours) {
        this.hours = this.maxHours;
      }
      this.$nextTick(() => {
        this.pushValue();
      });
    },
  },
  mounted() {
    if (this.locationValue !== undefined && this.locationValue !== null) {
      this.location = this.locationValue;
    }
    if (this.remoteWorkValue !== undefined && this.remoteWorkValue !== null) {
      this.remoteWork = this.remoteWorkValue;
    }
    if (this.teamSizeValue !== undefined && this.teamSizeValue !== null) {
      this.teamSize = this.teamSizeValue;
    }
    if (this.startDateValue !== undefined && this.startDateValue !== null) {
      this.startDate = this.startDateValue;
    }
    if (this.durationAmountValue !== undefined && this.durationAmountValue !== null) {
      this.durationAmount = this.durationAmountValue;
    }
    if (this.durationUnitValue !== undefined && this.durationUnitValue !== null) {
      this.durationUnit = this.durationUnitValue;
    }
    if (this.hoursPerWeekValue !== undefined && this.hoursPerWeekValue !== null) {
      this.hours = this.hoursPerWeekValue;
    }
  },
  methods: {
    pushValue() {
      const clientData = {
        location: this.location,
        remoteWork: this.remoteWork,
        teamSize: this.teamSize,
        startDate: this.startDate,
        durationAmount: this.durationAmount,
        durationUnit: this.durationUnit,
        hoursPerWeek: this.hours,
      };
      this.$emit('input', clientData);
    },
    getDropdownItems(translationKey: string): Array<any> {
      const translation = { ...this.$t(`project-forum.form-section.conditions.${translationKey}.dropdown`) as any };
      const keys = Object.keys(translation);
      return keys.map((key) => ({
        text: translation[key],
        value: key
      }));
    },
    formatDate(timestamp: string, dateformat: 'short'|'diff' = 'diff'): string {
      const { getFormattedTimeByAge, getFormattedDate } = useFormattedDateTime();
      if (dateformat === 'diff') return getFormattedTimeByAge(timestamp);
      return getFormattedDate(timestamp);
    },
    setMenuStartDate(value: boolean) {
      this.menuStartDate = value;
    },
  },
});
