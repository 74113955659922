import VueI18n from '@/i18n';
import {
  computed, provide, ref, Ref
} from 'vue';

interface ErrorFunctionParameters {
  conf: { [key:string]:string };
  fieldName: string;
}

export type ValidationFunction = (fieldName:string) => string | boolean;

export type GetErrorsType = (name:string) => string[]
export type ValidFieldsType = Ref<{[key:string]:number}>;
export type RegisterRulesType = (name:string, rules:ValidationFunction[], value:Ref<string>) => void;

type ValidateFunction = (input: string, confValue?: any) => boolean;
type ErrorFunction = (params: ErrorFunctionParameters) => string;

interface RulesWithRef {
  rules: ValidationFunction[];
  value: Ref; // won't accept Ref<string>!!!! and always casts it to "string" whyever. TS bug?!
}

interface RegisteredRules {
  [key:string]: RulesWithRef;
}

const useValidation = (config?: Ref<any>, keyPrefix = '') => {
  const validFields = ref<{ [key:string]:number}>({});
  const registeredRules = ref<RegisteredRules>({});

  const numValidFields = computed(() => {
    const values = Object.values(validFields.value);
    return values.filter((value) => !value).length;
  });

  const allFieldsValid = computed(() => Object.values(validFields.value).every((numErrors) => !numErrors));

  const createValidateFunction = (
    fieldName: string,
    validateFn: ValidateFunction,
    errorFn: ErrorFunction
  ):ValidationFunction => {
    const fullFieldName = keyPrefix ? `${keyPrefix}.${fieldName}` : fieldName;
    const conf = config?.value?.[fullFieldName];
    if (!conf) {
      throw new Error(`Warning, no validation configuration found for ${fieldName}!`);
    }
    return (input: string) => validateFn(input, conf) || errorFn({
      conf,
      fieldName: fullFieldName
    });
  };

  const i18nFieldName = (fieldName: string): string => fieldName.replaceAll('.', '-');

  const I18nVal = (key: string, options?: any) => VueI18n.t(`validation.${key}`, options) as string;

  const required = (fieldName: string) => createValidateFunction(
    fieldName, (input) => !!input,
    ({ fieldName }) => I18nVal('mandatory', {
      fieldName: VueI18n.t(`fieldnames.${i18nFieldName(fieldName)}`)
    })
  );

  const minLength = (fieldName: string) => createValidateFunction(
    fieldName, (input, confValue) => input.length >= confValue,
    ({ conf }) => I18nVal('max_length', { length: conf })
  );

  const maxLength = (fieldName: string) => createValidateFunction(
    fieldName, (input, confValue) => input.length <= confValue,
    ({ conf }) => I18nVal('min_length', { length: conf })
  );

  const regex = (fieldName: string, replaceWhitespace = false) => createValidateFunction(
    fieldName, (input, confValue) => !input || !!new RegExp(confValue).exec(
      replaceWhitespace ? input.replace(/\s/g, '') : input
    ),
    ({ fieldName }) => I18nVal(`regex.${i18nFieldName(fieldName)}`)
  );

  const getErrors = (name:string):string[] => {
    const errors:any[] = [];
    const ruleSet = registeredRules.value[name];
    const { value } = ruleSet.value;
    // console.log('Get errors for field ', name, 'set is ', ruleSet, 'value:', value);
    ruleSet.rules.forEach((rule) => {
      // console.log('Testing rule with ', value);
      const result = rule(value);
      if (typeof result === 'string') {
        // console.log('Result was a string, so it was an error:', result);
        errors.push(result);
      }
    });
    validFields.value = {
      ...validFields.value,
      [name]: errors.length
    };
    return errors;
  };

  const registerRules = (name:string, rules:ValidationFunction[], value:Ref<string>) => {
    registeredRules.value = {
      ...registeredRules.value,
      [name]: {
        rules,
        value,
      }
    };
    validFields.value = {
      ...validFields.value,
      [name]: 1
    };
  };

  if (config) {
    provide('validFields', validFields);
    provide('getErrors', getErrors);
    provide('registerRules', registerRules);
  }

  return {
    getErrors,
    required,
    minLength,
    maxLength,
    regex,
    validFields,
    numValidFields,
    allFieldsValid
  };
};

export default useValidation;
