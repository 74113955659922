
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXTextArea from '@/components/molecules/forms/BCXTextArea.vue';
import Marvin from '@/components/svg/marvin/marvin_sleeping.svg?inline';
import { AvailabilityInterface } from '@/models/Availability';
import { ProfileStoreKey } from '@/models/Profile';
import { ExternalProject, RecommendationApiError, ResponseRecommendationVerify } from '@/models/Recommendations';
import recommendationService from '@/services/RecommendationService';
import useMessengerState from '@/state/messenger/messengerState';
import useProfileState from '@/state/profile/profileState';
import ProfileSidebarAvailability from '@/views/profile/components/sidebar/ProfileSidebarAvailability.vue';
import ProjectSelector from '@/views/project-forum/components/ProjectSelector.vue';
import ProfileSidebarProjectSummary from '@/views/profile/components/sidebar/ProfileSidebarProjectSummary.vue';
import {
  computed, defineComponent, PropType, ref, toRefs
} from 'vue';
import { asyncComputed, useDebounce } from '@vueuse/core';
import { logDebug } from '@/utils/logger';

export default defineComponent({
  name: 'RecommendSidebarContainer',
  components: {
    Marvin,
    BCXMarkdown,
    ProjectSelector,
    BCXTextArea,
    ProfileSidebarProjectSummary,
    ProfileSidebarAvailability
  },
  props: {
    availability: {
      type: Object as PropType<AvailabilityInterface | null>,
      default: null
    },
    isInQuickInfo: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const { isInQuickInfo } = toRefs(props);
    const projectName = ref('');
    const selectedProject = ref<ExternalProject | null>(null);
    const projectNameDebounced = useDebounce(projectName, 250);
    const message = ref('');
    const { isOpened: isMessengerOpened } = useMessengerState();
    const { userId, user } = useProfileState(ProfileStoreKey.PROFILE);
    const hasBeenRecommended = ref(false);

    const projectList = asyncComputed<ExternalProject[]>(
      async () => (await recommendationService.searchProjects(projectNameDebounced.value.toLocaleLowerCase())).externalProjectList,
      []
    );

    const projectVerification = asyncComputed<ResponseRecommendationVerify | null>(
      async () => {
        if (selectedProject.value?.externalId) {
          return recommendationService.verifyRecommendation(userId.value, selectedProject.value.externalId);
        }
        return null;
      },
      null,
      {
        lazy: true
      }
    );

    const verifiedProject = computed(() => projectVerification.value?.project ?? null);

    const recommendationKey = computed<RecommendationApiError | null>(() => {
      const key = projectVerification.value?.resultMessageKey;
      if (!key) return null;
      return (key.match(/rule\.(.*?)$/)?.[1] as RecommendationApiError) ?? null;
    });

    const resultMessageKey = computed(() => {
      if (!recommendationKey.value) return null;
      return `recommendations.apiErrors.${recommendationKey.value}`;
    });

    const select = (item: any) => {
      selectedProject.value = item;
      if (item) {
        logDebug('is in quick info?', isInQuickInfo.value);
        if (!isInQuickInfo.value) isMessengerOpened.value = false;
        hasBeenRecommended.value = false;
      }
    };

    const recommend = async () => {
      if (selectedProject.value?.externalId) {
        hasBeenRecommended.value = true;
        await recommendationService.createRecommendation(userId.value, selectedProject.value.externalId, message.value);
        emit('recommended');
      }
    };

    const userName = computed(() => user.value?.firstname ?? user.value?.fullname ?? '');

    const disabled = computed(
      () => hasBeenRecommended.value || (projectVerification.value?.resultMessageKey && recommendationKey.value !== RecommendationApiError.PROJECTPREFERENCES_DO_NOT_MATCH)
    );

    return {
      resultMessageKey,
      projectName,
      projectList,
      projectVerification,
      verifiedProject,
      select,
      selectedProject,
      recommend,
      disabled,
      message,
      userId,
      userName,
      user,
      hasBeenRecommended
    };
  }
});
