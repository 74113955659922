
import Vue from 'vue';
import SearchInput from '@/components/molecules/SearchInput.vue';
import BCXIcon from '@/components/molecules/BCXIcon.vue';
import BCXMessengerFilters from '@/components/messenger/Tools/BCXMessengerFilters.vue';
import useMessengerState from '@/state/messenger/messengerState';
import { MessengerMaxChars } from '@/models/Messenger';

export default Vue.extend({
  name: 'BCXMessengerTools',
  components: {
    BCXMessengerFilters,
    BCXIcon,
    SearchInput,
  },

  setup() {
    const {
      showNewFilter: showNew,
      searchTerm,
      showSortPanel,
      isFlat,
    } = useMessengerState();

    return {
      showSortPanel,
      searchTerm,
      showNew,
      isFlat,
      maxLength: MessengerMaxChars.MESSAGE_SEARCH
    };
  },
});
