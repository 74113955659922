
import { ForumMaxChars } from '@/views/forum/models/Forum';
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import ProjectForumSection from '@/views/project-forum/components/ProjectForumSection.vue';
import BcxProgressCookies from '@/components/molecules/BCXProgressCookies.vue';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import LightBulb from '@/components/svg/profile-lightbulb.svg?inline';
import ProjectTitle from '@/views/project-forum/components/form-components/ProjectTitle.vue';
import Client from '@/views/project-forum/components/form-components/Client.vue';
import Conditions from '@/views/project-forum/components/form-components/Conditions.vue';
import Description from '@/views/project-forum/components/form-components/Description.vue';
import TagList from '@/components/tagging/TagList.vue';
import { Tag } from '@/services/TaggingService';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { PostProject } from '../models/ProjectForum';

export default Vue.extend({
  components: {
    BCXBreadcrumbs,
    ProjectForumSection,
    BcxProgressCookies,
    BCXCheckbox,
    LightBulb,
    ProjectTitle,
    Client,
    Conditions,
    Description,
    TagList,
    BCXMarkdown,
  },
  provide: () => ({
    maxCreateMessageTextLength: ForumMaxChars.PROJECT_REPLY
  }),
  data() {
    return {
      thread: {
        title: '',
        content: ''
      },
      projectTitle: '',
      projectClient: {
        countryCode: '',
        city: '',
        companySize: 'RANGE_50_500',
        industry: '',
        cityPlaceName: '',
        cityPostalCode: ''
      },
      projectConditions: {
        location: 'REMOTE_ONLY',
        remoteWork: 'TWO_DAYS_PER_WEEK',
        teamSize: 3,
        startDate: '',
        durationAmount: 3,
        durationUnit: 'MONTH',
        hoursPerWeek: 40
      },
      projectDescription: '',
      currentStatus: 1,
      statusCount: 5,
      projectRoleTags: [],
      projectSkillTags: [],
      testFlag: false,
      isSaving: false
    };
  },
  computed: {
    ...mapGetters('page', ['isMobileLayout']),
    ...mapState('projectForum', ['countries', 'cities', 'industries']),
    breadcrumbItems(): Array<any> {
      const breadcrumbItems: Array<any> = [
        {
          text: this.$t('project-forum.labels.projects'),
          to: { name: 'projectList' }
        },
        {
          text: this.$t('project-forum.labels.create-project'),
          to: { name: 'createProject' }
        }
      ];
      if (this.isMobileLayout) {
        // display logo as first item
        breadcrumbItems.unshift({ text: 'ByteCookie' });
      }
      return breadcrumbItems;
    },
    requirementsMet(): Array<boolean> {
      const requirementsMet: Array<boolean> = [
        true, // not used
        this.projectTitle.length > 2,
        this.projectClient.countryCode.length > 0 && this.projectClient.cityPlaceName.length > 0,
        this.projectConditions.location.length > 0,
        this.projectDescription.length > 2
      ];
      return requirementsMet;
    },
    cookieCount(): number {
      return this.statusCount - 1;
    },
    showTestFlag(): boolean {
      return [1, 5].includes(this.currentStatus);
    }
  },
  mounted() {
    this.fetchCountries();
    this.fetchIndustries();
  },
  methods: {
    ...mapActions('projectForum', ['postNewProject', 'fetchCountries', 'fetchIndustries']),
    nextStatus() {
      this.currentStatus++;
    },
    previousStatus() {
      this.currentStatus--;
    },
    getCookieStatusClass(status: number): string {
      return `progress-cookies--cookie ${status > this.currentStatus ? 'previous-cookie' : 'current-cookie'}`;
    },
    titleKeyDown(ev: any) {
      if (ev.keyCode === 13) {
        this.nextStatus();
      }
    },
    projectListLink() {
      this.$router.push({ name: 'projectList' });
    },
    finish() {
      if (!this.isSaving) {
        this.isSaving = true;
        const postProject: PostProject = {
          title: this.projectTitle,
          descriptionLong: this.projectDescription.trim(),
          status: 'ACTIVE',
          testFlag: this.testFlag,
          type: 'NONE',
          paymentType: 'DIRECT',
          cityPlacename: this.projectClient.cityPlaceName,
          cityPostalCode: this.projectClient.cityPostalCode,
          countryId: this.projectClient.countryCode,
          hourlyRateInEUR: 0,
          startDate: this.projectConditions.startDate,
          startDateLatest: this.projectConditions.startDate,
          optionToExtendDuration: true,
          remotePercentage: 0,
          companyId: '',
          companySize: this.projectClient.companySize,
          projectLocation: this.projectConditions.location,
          remoteWork: this.projectConditions.remoteWork,
          teamSize: this.projectConditions.teamSize || 0,
          industryTagId: this.projectClient.industry,
          durationAmount: this.projectConditions.durationAmount || 0,
          durationUnit: this.projectConditions.durationUnit,
          hoursPerWeek: this.projectConditions.hoursPerWeek || 0,
          tagAssignmentList: [
            ...this.projectRoleTags.map((tag: Tag) => {
              if (tag.tagId !== '') {
                return { externalTagId: tag.tagId, newTagName: tag.tagName, newTagType: tag.tagType };
              }
              return { externalTagId: '', newTagName: tag.tagName, newTagType: tag.tagType };
            }),
            ...this.projectSkillTags.map((tag: Tag) => {
              if (tag.tagId !== '') {
                return { externalTagId: tag.tagId, newTagName: tag.tagName, newTagType: tag.tagType };
              }
              return { externalTagId: '', newTagName: tag.tagName, newTagType: tag.tagType };
            })
          ]
        };
        if (this.projectConditions.location !== 'ONSITE_AND_REMOTE') {
          postProject.remoteWork = undefined;
        }
        this.postNewProject(postProject)
          .then((project) => {
            this.$router.push({ name: 'projectForumThread', params: { projectId: project.externalId } });
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    }
  }
});
