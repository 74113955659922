
import SidebarContent from '@/components/SidebarContent.vue';
import { InfoBoxType } from '@/models/InfoBox';
import useMessengerState from '@/state/messenger/messengerState';
import { defineComponent, ref } from 'vue';
import RecommendToUser from './RecommendToUser.vue';

export default defineComponent({
  name: 'RecommendToUserContainer',
  components: {
    RecommendToUser,
    SidebarContent
  },
  props: {
    inBox: {
      type: Boolean
    }
  },
  setup() {
    const isOpened = ref(true);
    const { isOpened: isMessengerOpened } = useMessengerState();

    const onSelected = () => {
      isMessengerOpened.value = false;
    };

    return { isOpened, InfoBoxType, onSelected };
  }
});
