
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import ThreadTopicSkelleton from '@/views/forum/components/ThreadTopicSkeleton.vue';
import ThreadTopicTitle from '@/views/forum/components/ThreadTopicTitle.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';
import TagList from '@/components/tagging/TagList.vue';
import ThreadEntry from '@/views/forum/components/ThreadEntry.vue';
import CreateMessage from '@/views/forum/components/CreateMessage.vue';
import IconEdit from '@/components/svg/edit-circle.svg?inline';
import { MessagePost } from '@/views/forum/models/Forum';
import { RouterEntry } from '@/models/Router';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';

export default Vue.extend({
  name: 'ThreadTopic',
  components: {
    ThreadTopicSkelleton,
    ThreadTopicTitle,
    ThreadEntry,
    CreateMessage,
    BCXBreadcrumbs,
    TagList,
    IconEdit,
    BCXMarkdown,
  },
  data() {
    return {
      userScore: 800,
      editTopicMessage: false,
      editTopicTitle: false,
      maxTitleLength: 100,
      title: ''
    };
  },
  computed: {
    ...mapState('forum', ['thread']),
    ...mapGetters('forum', ['getForumTitle', 'getForumId', 'getGroupId', 'getThreadTopic', 'getThreadTitle']),
    ...mapGetters(['user']),
    ...mapGetters('page', ['isMobileLayout']),
    breadcrumbItems(): Array<RouterEntry> {
      const breadcrumbItems: Array<RouterEntry> = [
        {
          text: this.$t('breadcrumb.community') as string,
        },
        {
          text: this.$t('forum.labels.threads.plural') as string,
          to: { name: 'forums' }
        },
        {
          text: this.getForumTitle,
          to: { name: 'threads', params: { forumId: this.getForumId, groupId: this.getGroupId, threadId: this.thread.threadId } }
        }
      ];
      if (this.isMobileLayout) {
        // display logo as first item
        breadcrumbItems[0].text = 'ByteCookie';
      }
      return breadcrumbItems;
    },
    userIsCreator(): boolean {
      return this.getThreadTopic && this.thread.creator.userId === this.user.userId;
    },
    showEditButton(): boolean {
      return (this.userIsCreator
          || this.user.roles.includes('FORUM_MODERATOR')
          || this.user.roles.includes('PROJECT_MODERATOR')) && !this.editTopicTitle;
    },
    editTitleSaveButtonEnabled(): boolean {
      return this.title.trim().length > 0;
    },
    backLink(): any {
      return { name: 'threads', params: { 'forum-id': this.getForumId, threadId: this.thread.threadId } };
    },
    charactersLeft(): number {
      const charactersLeft = this.maxTitleLength - this.title.length;
      if (charactersLeft > 0) {
        return charactersLeft;
      }
      return 0;
    }
  },
  watch: {
    getThreadTitle() {
      if (this.getThreadTitle) {
        this.title = this.getThreadTitle;
      }
    },
    editTopicTitle(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          // const { title } = this;    DR: das ging nicht, Titeledit-Input war leer, von mir laienhaft gefixt, mal bei Gelegenheit checken
          const el = this.$refs.editTitleInput as HTMLInputElement;
          el.focus();
          el.value = this.getThreadTitle;
          // war vorher el.value = title
        });
      }
    },
    title(newValue) {
      this.title = newValue.replace(/[^\u00C0-\u017FA-Za-z\d \-.,@$€%=?!()[\]/]+/, '');
    }
  },
  methods: {
    ...mapActions('forum', ['updateThreadMessage']),
    toggleEditMessage() {
      this.editTopicMessage = !this.editTopicMessage;
    },
    postMessage(postMessage: MessagePost) {
      this.$emit('post-message', postMessage);
    },
    postMessageEdit(text: string): void {
      const postMessage = {
        forumId: this.thread.forumId,
        threadId: this.thread.threadId,
        forumThreadMessageId: this.getThreadTopic.messageId,
        messageContent: text
      };
      this.updateThreadMessage({ postMessage, messageCount: 'topic' }).then(() => {
        this.$emit('updated-message', this.title);
        this.toggleEditMessage();
      });
    },
    toggleEditTitle() {
      this.editTopicTitle = !this.editTopicTitle;
    },
    saveTitle() {
      const title = this.title.trim();
      if (title.length > 0) {
        this.$emit('update-topic', this.title);
        this.toggleEditTitle();
      }
    }
  }
});
