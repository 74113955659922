
import Vue from 'vue';
import Close from '@/components/svg-components/X.vue';

export default Vue.extend({
  components: {
    Close,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'new-stuff',
    },
    specialActionText: {
      type: String,
      default: undefined,
    },
    closeButtonVisible: {
      type: Boolean,
      default: true,
    },
    align: {
      type: String,
      default: 'center',
    },
  },
  methods: {
    action() {
      this.$emit('action');
    },
    specialAction() {
      this.$emit('special-action');
    },
    close() {
      this.$emit('close');
    },
  },
});
