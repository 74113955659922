import { SmileyTypes } from '@/components/svg-components/Smiley.vue';
import { User } from '@/models/User';
import { Project, ProjectListProject } from '@/views/project-forum/models/ProjectForum';
import { EventBusKey } from '@vueuse/core';

export const RECOMMENDATIONS_EVENT_REFRESH = 'refresh';
export const RECOMMENDATIONS_EVENT_REFRESH_FEEDBACKS = 'refresh_feedbacks';

export type RecommendationAction = 'add' | 'remove' | ''

export enum RecommendationApiError {
  LIMIT_FOR_USER_REACHED = 'limit_for_user_reached',
  TIME_LIMIT_FOR_USER_2_USER_REACHED = 'limit_for_user_from_user_reached_within_timelimit_reached',
  PROJECTPREFERENCES_DO_NOT_MATCH = 'projectpreferences_not_matching',
  RECOMMENDATION_ALREADY_EXISTS = 'recommendation_already_exists',
  RECOMMENDER_IS_CREATOR = 'recommendinguser_is_projectcreator',
  USER_BLOCKED = 'recommendation_for_user_from_user_blocked'
}

export interface ExternalProject {
  created: string;
  externalId: string;
  projectCreator: User;
  projectTitle: string;
  startDate: string;
}

export interface ExternalProjectList {
  externalProjectList: ExternalProject[];
}

export enum PropertyState {
  PREFERRED='PREFERRED',
  MANDATORY='MANDATORY'
}

export interface ExternalRecommendationVerifyPreferencesListEntry {
  propertyKey: string;
  propertyState: PropertyState;
}

export interface ResponseRecommendationVerify {
  project: Project;
  resultMessageKey: string;
  projectPreferencesPropertyList: {
    verifyResultList: ExternalRecommendationVerifyPreferencesListEntry[];
  };
}

export enum RecommendationFeedbackType {
  Positive = 'POSITIVE',
  Neutral = 'NEUTRAL',
  Negative = 'NEGATIVE',
}

export type RecommendationPreference = 'LOCATION' | 'REMOTE' | 'MICRO_PROJECTS' | 'COMPANY_SIZE' | 'INDUSTRIES' | 'AVAILABILITY';

export interface RecommendationFeedbackRequest {
  userFeedback: RecommendationFeedbackType;
  userFeedbackMessage?: string;
  notFittingMySkills?: boolean;
  notFittingMyPreferences?: boolean;
  userIdToBlock?: string;
  notFittingPreferencesList?: RecommendationPreference[];
}

export enum RecommendationStatus {
  Open = 'OPEN',
  InMyProjects = 'IN_MY_PROJECTS',
  AcceptedByProject = 'ACCEPTED_BY_PROJECT',
  Rejected = 'REJECTED'
}

export enum RecommendationInitiatedBy {
  Subscription = 'SUBSCRIPTION',
  Recommendation = 'RECOMMENDATION'
}

export enum MyProjectStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED'
}

export interface ExternalRecommendation {
  externalId: string;
  created: string;
  recommendingUser: User;
  recommendedUser: User;
  status: RecommendationStatus;
  recommendingMessage?: string;
  recommendedUserFeedbackResponseMessage?: string;
  recommendedUserFeedback?: RecommendationFeedbackType;
  recommendedUserFeedbackNotFittingPreferences?: boolean;
  recommendedUserFeedbackNotFittingPreferencesList?: RecommendationPreference[];
  recommendedUserFeedbackNotFittingSkills?: boolean;
  project: ProjectListProject;
  recommendingMessageFlagged: boolean;
  recommendedUserFeedbackResponseMessageFlagged: boolean;
}
export interface MyRecommendationsResponse {
  recommendations: ExternalRecommendation[];
}

export interface ExternalUserProject {
  externalId: string;
  createdTimestamp: string;
  initiatedBy: RecommendationInitiatedBy;
  project: ProjectListProject;
  recommendation: ExternalRecommendation;
  status: MyProjectStatus;
}

export interface ResponseMyProjects {
  externalUserProjectList: ExternalUserProject[];
}

export type RecommendationsForMeResponse = MyRecommendationsResponse

export const PAGE_SIZE_RECOMMENDATIONS = 5;
export const PAGE_SIZE_MY_RECOMMENDATIONS = 10;

export const feedBackType2SmileyType:Record<RecommendationFeedbackType, SmileyTypes> = {
  [RecommendationFeedbackType.Negative]: SmileyTypes.Bad,
  [RecommendationFeedbackType.Neutral]: SmileyTypes.Neutral,
  [RecommendationFeedbackType.Positive]: SmileyTypes.Good
};

type RecommendationEvent = string;

// eslint-disable-next-line import/prefer-default-export
export const recommendationsEventKey: EventBusKey<RecommendationEvent> = Symbol('recommendationsEventKey');
