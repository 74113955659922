
import { SubscriptionFeedEntry } from '@/views/subscriptions/models/Subscription';
import {
  defineComponent, computed, PropType, reactive, ComputedRef, toRefs
} from 'vue';
import useI18n from '@/mixins/useI18n';
import Chevron from '@/components/svg-components/Chevron.vue';
import FeedListItem from '@/views/subscriptions/components/FeedListItem.vue';
import FeedListItemSkeleton from '@/views/subscriptions/components/FeedListItemSkeleton.vue';
import FeedIcon from '@/components/svg/feed.svg?inline';
import MarvinLookUpIcon from '@/components/svg/marvin/marvin_look_up.svg?inline';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  name: 'DashboardSubscriptionsFeed',
  components: {
    Chevron,
    FeedListItem,
    FeedListItemSkeleton,
    FeedIcon,
    MarvinLookUpIcon
  },
  props: {
    subscriptionType: {
      type: String as PropType<'talks' | 'projects'>,
      default: 'talks',
    },
  },
  setup(props) {
    const { subscriptionType } = toRefs(props);

    const { t } = useI18n();
    const store = useStore();

    const isLoading: ComputedRef<boolean> = computed(() => store.state.subscriptions.loading);

    const subscriptionFeed: ComputedRef<SubscriptionFeedEntry[]> = computed(() => {
      if (subscriptionType.value === 'talks') {
        return store.state.subscriptions.subscriptionFeed.filter((item: SubscriptionFeedEntry) => item.type.includes('TALK')).slice(0, 3);
      } if (subscriptionType.value === 'projects') {
        return store.state.subscriptions.subscriptionFeed.filter((item: SubscriptionFeedEntry) => item.type.includes('PROJECT')).slice(0, 3);
      }
      return [];
    });

    const hasSubscriptions: ComputedRef<boolean> = computed(() => subscriptionFeed.value.length > 0);

    const toAllSubscriptions = computed(() => ({
      name: 'subscriptions',
      query: {
        filter: subscriptionType.value.toUpperCase(),
      },
    }));

    const subscriptionHeaderTitle = computed(() => t(`dashboard.subscriptions.header.${subscriptionType.value}`) as string);

    const noEntriesState = reactive({
      textPath: 'dashboard.subscriptions.no-entries',
      routeTo: { name: subscriptionType.value === 'talks' ? 'forums' : 'projectList' },
      routeText: (t(`subscriptions.${subscriptionType.value}`) as string),
    });

    function refreshSubscription() {
      store.dispatch('subscriptions/fetchSubscriptionFeed');
    }

    return {
      hasSubscriptions,
      subscriptionFeed,
      toAllSubscriptions,
      subscriptionHeaderTitle,
      noEntriesState,
      isLoading,
      refreshSubscription,
      t
    };
  },
});
