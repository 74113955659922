
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXPoints from '@/components/molecules/BCXPoints.vue';
import BCXUserBadges from '@/components/molecules/BCXUserBadges.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import { AvatarSize, AvatarSizes } from '@/models/Avatar';
import { HtmlTarget } from '@/models/HtmlTarget';
import { User } from '@/models/User';
import { BannerUserState } from '@/models/UserState';
import useQuickInfo from '@/state/quickInfo';
import store from '@/store';
import { SingleUserFromSearch } from '@/views/user-search/models/UserSearch';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { templateRef } from '@vueuse/core';

export default defineComponent({
  name: 'BCXUserBanner',
  components: {
    BCXPoints,
    BCXAvatar,
    BCXUserBadges
  },
  props: {
    insideQuickinfo: {
      type: Boolean
    },
    insideMessenger: {
      type: Boolean
    },
    size: {
      type: String as PropType<AvatarSizes>,
      default: 'L'
    },
    showOnlineIndicator: {
      type: Boolean,
      default: false
    },
    showCookies: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: ''
    },
    user: {
      type: Object as () => SingleUserFromSearch,
      default: () => ({})
    },
    target: {
      type: String as PropType<HtmlTarget>,
      default: '_self'
    },
    type: {
      type: String as PropType<'normal' | 'user-search'>,
      default: 'normal'
    }
  },
  setup(props) {
    const {
      insideQuickinfo, size, userId, insideMessenger
    } = toRefs(props);

    const viewUser = computed<BannerUserState & User>(() => store.getters['userStates/getUserInfo'](userId.value ?? 'user'));

    const avatarSize = computed<AvatarSize>(() => AvatarSize[size.value]);

    // Quickinfo
    const el = templateRef<HTMLElement>('el');
    const { setQuickInfo, showQuickInfo, setShowBadgesLegend } = useQuickInfo();
    const { isMobileWidth } = useResponsiveness();

    const onClick = () => {
      if (insideQuickinfo.value) return;
      showQuickInfo(el, viewUser.value);
    };
    const badgesTheme = computed(() => (isMobileWidth.value && (insideQuickinfo.value || insideMessenger.value) ? 'dark' : 'bright'));

    const badgeClicked = () => {
      if (el.value?.parentElement) {
        const { left, top } = el.value.parentElement.getBoundingClientRect();
        setShowBadgesLegend(true);
        if (!insideQuickinfo.value) {
          setQuickInfo(viewUser.value, left, top + 50);
        }
      }
    };

    return {
      onClick,
      avatarSize,
      viewUser,
      setShowBadgesLegend,
      badgeClicked,
      badgesTheme
    };
  }
});
