
import { defineComponent } from 'vue';
import BCXContentArea from '@/components/molecules/BCXContentArea.vue';
import { failedQueue } from '@/plugins/axios';

export default defineComponent({
  name: 'TestingArea',
  components: { BCXContentArea },
  setup() {
    const truncateAuth = (auth: string) => (auth.length > 80 ? `${auth.substring(0, 80)}...` : auth);
    return {
      truncateAuth,
      failedQueue
    };
  }
});
