
import BCXQuickLayer from '@/components/molecules/BCXQuickLayer.vue';
import useQuickInfo from '@/state/quickInfo';
import RecommendLayer from '@/views/profile/components/RecommendLayer.vue';
import { defineComponent, ref, watch } from 'vue';
import { toRefs } from '@vueuse/core';

const RECOMMENDATION_LAYER_WIDTH = 360;
const GAP = 20;

export default defineComponent({
  name: 'RecommendQuickInfoLayer',
  components: {
    RecommendLayer,
    BCXQuickLayer
  },
  props: {
    isOpen: {
      type: Boolean
    }
  },
  setup(props) {
    const { isOpen } = toRefs(props);
    const { quickInfoX, quickInfoY } = useQuickInfo();
    const xPos = ref(0);
    const yPos = ref(0);

    const alignPositionToQuickInfo = () => {
      const x = quickInfoX.value;
      const y = quickInfoY.value;
      const totalWidth = RECOMMENDATION_LAYER_WIDTH + GAP;
      xPos.value = (x + RECOMMENDATION_LAYER_WIDTH > window.innerWidth - totalWidth) ? x - totalWidth : x + totalWidth;
      yPos.value = y;
    };

    watch(isOpen, alignPositionToQuickInfo);

    return { xPos, yPos };
  }
});
