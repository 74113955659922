
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXChevronSwitch from '@/components/molecules/BCXChevronSwitch.vue';
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import BCXLinkedUser from '@/components/molecules/BCXLinkedUser.vue';
import IconEnvelope from '@/components/svg/envelope.svg?inline';
import IconAddFavourite from '@/components/svg/favourite-add.svg?inline';
import IconRemoveFavourite from '@/components/svg/favourite.svg?inline';
import IconDelete from '@/components/svg/x-circle.svg?inline';
import useResponsiveness from '@/mixins/useResponsiveness';
import { ExternalRecommendation, RecommendationStatus } from '@/models/Recommendations';
import MyProjectDetailsCheckbox from '@/views/my-projects/components/MyProjectDetailsCheckbox.vue';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';
import FeedbackDetails from '@/views/recommendations/components/FeedbackDetails.vue';
import {
  computed, defineComponent, PropType, ref, toRefs
} from 'vue';
import { useVModel } from '@vueuse/core';
import { useStore } from 'vue2-helpers/vuex';

export default defineComponent({
  name: 'MyProjectDetails',
  components: {
    MyProjectDetailsCheckbox,
    BCXLinkedUser,
    FeedbackDetails,
    BCXCollapsible,
    BCXChevronSwitch,
    BCXAvatar,
    IconAddFavourite,
    IconRemoveFavourite,
    IconEnvelope,
    IconDelete,
  },
  props: {
    deletable: {
      type: Boolean
    },
    recommendation: {
      type: Object as PropType<ExternalRecommendation | null>,
      default: null
    },
    project: {
      type: Object as PropType<ProjectListProject>,
      required: true
    },
    myProjectId: {
      type: String,
      default: '',
    },
    isCheckable: {
      type: Boolean
    },
    value: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  emits: ['give-feedback'],
  setup(props) {
    const { project } = toRefs(props);
    const { isMobileWidth } = useResponsiveness();
    const isOpened = ref(false);
    const checkedProjectIdsVM = useVModel(props);
    const store = useStore();

    const isOwnProject = computed(() => project.value?.projectCreator?.userId === store.getters['user']?.userId);

    return {
      isMobileWidth,
      isOpened,
      RecommendationStatus,
      checkedProjectIdsVM,
      isOwnProject
    };
  }
});
