import { Module } from 'vuex';
import {
  emptyProject,
  emptyProjectThread,
  PostProject,
  Project,
  ProjectListProject,
  ProjectListSort
} from '@/views/project-forum/models/ProjectForum';
import ProjectForumService from '@/services/ProjectForumService';
import {
  Forum, Message, MessagePost, MessagesSort, Thread, ThreadsSort
} from '@/views/forum/models/Forum';
import { City, Country } from '@/models/Geo';
import { getCountryCities, getCountryCitiesByPostCode, getPlatformCountries } from '@/services/Geo/Api';
import { getIndustries } from '@/services/Tags/Api';
import { Industry } from '@/models/Tags';
import { Many, orderBy } from 'lodash';
import ForumService from '@/services/ForumService';
import { UserVoted } from '@/services/TaggingService';
import { logDebug, logError, LogLevel } from '@/utils/logger';
import { ProjectGuest } from '@/models/Project';

interface State {
  project: Project;
  projectList: Array<ProjectListProject>;
  projectListSort: ProjectListSort;
  thread: Thread;
  threadsSort: ThreadsSort;
  messagesSort: MessagesSort;
  isFetchingMessages: boolean;
  isFetchingProjects: boolean;
  allThreadsLoaded: boolean;
  allMessagesLoaded: boolean;
  editMessageId: string;
  replyMessageId: string;
  cities: Array<City>;
  countries: Array<Country>;
  industries: Array<Industry>;
  guests: Array<ProjectGuest>;
}

const module: Module<State, any> = ({
  namespaced: true,
  state: {
    threadsSort: {
      sortParam: 'TIMESTAMP',
      sortOrder: 'DESC',
    },
    projectListSort: {
      sortParam: 'CREATED',
      sortOrder: 'DESC',
    },
    messagesSort: {
      sortParam: 'TIMESTAMP',
      sortOrder: 'DESC',
    },
    project: {
      city: {
        adminCode1: '', adminName1: '', countryCode: '', placeName: '', postalCode: ''
      },
      creator: {
        firstname: '', fullname: '', lastname: '', userId: ''
      },
      companySize: 'RANGE_50_500',
      flagged: false,
      descriptionLong: '',
      durationAmount: 0,
      durationUnit: '',
      externalId: '',
      hourlyRateInEUR: 0,
      hoursPerWeek: 40,
      industryTagId: { tagId: '', tagName: '', tagType: '' },
      optionToExtendDuration: true,
      paymentType: '',
      projectLocation: '',
      remotePercentage: 0,
      remoteWork: '',
      startDate: '',
      startDateLatest: '',
      status: '',
      teamSize: 0,
      title: '',
      type: '',
      metaDataVoting: {
        downvoteCount: 0,
        upvoteCount: 0,
        voteResult: 0,
      },
      metaDataProject: {
        testFlag: false,
      },
      tagAssignmentList: [],
      userMetaDataVoting: {
        userVoted: UserVoted.NOTVOTED,
      },
      userMetaDataProject: {
        flagged: false,
        read: false,
      }
    },
    projectList: [],
    thread: {
      created: '',
      creator: {
        firstname: '', fullname: '', lastname: '', userId: '',
      },
      forumId: '',
      groupId: '',
      threadId: '',
      groupType: '',
      forumThreadMetaData: {
        numberOfReplies: 0,
        numberOfViews: 0,
        latestReplyMessageUserFullname: '',
        latestReplyMessageUserId: '',
        latestReplyMessageId: '',
        latestReplyMessageTimestamp: '',
        userSubscribed: false,
        voteResult: 0,
      },
      initialMessage: {
        created: '',
        creator: {
          firstname: '', fullname: '', lastname: '', userId: '',
        },
        downvoteCount: 0,
        messageId: '',
        text: '',
        upvoteCount: 0,
        voteResult: 0,
      },
      title: '',
      messages: [],
      tagAssignmentList: []
    },
    isFetchingMessages: false,
    isFetchingProjects: false,
    allThreadsLoaded: false,
    allMessagesLoaded: false,
    editMessageId: '',
    replyMessageId: '',
    cities: [],
    countries: [],
    industries: [],
    guests: [],
  },
  getters: {
    getOpenEditMessageId: ({ editMessageId }): string => editMessageId,
    getOpenReplyToMessageId: ({ replyMessageId }) => replyMessageId,
    getForumId: (state): string => state.thread.forumId,
    getThreadId: ({ thread }): string => thread.threadId,
    getProjectListSorted({ projectList, projectListSort }) {
      let sort: 'projectTitle' | 'created' | 'latestAddedReply' | 'numberOfViews' | 'voteResult';
      const order = projectListSort.sortOrder.toLowerCase() as Many<'asc'|'desc'>;

      switch (projectListSort.sortParam) {
        case 'TITLE':
          sort = 'projectTitle';
          break;
        case 'VIEWS':
          sort = 'numberOfViews';
          break;
        case 'VOTES':
          sort = 'voteResult';
          break;
        case 'LATEST':
          sort = 'latestAddedReply';
          break;
        case 'TIMESTAMP':
        case 'CREATED':
        default:
          sort = 'created';
          break;
      }

      return orderBy(projectList, (project) => {
        if (sort === 'projectTitle') {
          return project.projectTitle?.toLowerCase();
        }
        return project[sort];
      }, order);
    },
    getNumberOfProjects({ projectList }) {
      return projectList.length;
    },
    getProjectThreadNumberOfViews({ thread }) {
      return thread.forumThreadMetaData.numberOfViews;
    },
    getProjectThreadNumberOfReplies({ thread }) {
      return thread.forumThreadMetaData.numberOfReplies;
    },
    getThreadTopicVoteResult({ thread }) {
      return thread.initialMessage.voteResult;
    },
    getThreadRepliesSorted: ({ thread, messagesSort }) => {
      const replyMapBase: any = {};
      const localReplies: Array<Message> = thread.messages;

      localReplies.forEach((message, index) => {
        replyMapBase[message.messageId] = message;
        message.messageCount = `# ${index + 1}`;
        if (message.subMessages?.length) {
          message.subMessages.forEach((replyMessage, replyIndex) => {
            replyMapBase[replyMessage.messageId] = replyMessage;
            replyMessage.messageCount = `# ${index + 1}.${replyIndex + 1}`;
            if (replyMessage.replyToMessageId !== undefined && replyMessage.replyToMessageId !== message.messageId) {
              replyMessage.subReplyLink = `<a href="#message-${replyMessage.replyToMessageId}">
              @ ${replyMapBase[replyMessage.replyToMessageId].creator.fullname} - ${replyMapBase[replyMessage.replyToMessageId].messageCount}</a>`;
            }
          });
        }
      });

      let sort: 'creator.lastname' | 'voteResult' | 'created';
      const order = messagesSort.sortOrder.toLowerCase() as Many<'asc'|'desc'>;

      switch (messagesSort.sortParam) {
        case 'NAME':
          sort = 'creator.lastname';
          break;
        case 'VOTES':
          sort = 'voteResult';
          break;
        case 'TIMESTAMP':
        default:
          sort = 'created';
          break;
      }
      return orderBy(localReplies,
        [(reply) => {
          if (sort === 'creator.lastname') {
            return reply.creator.lastname?.toLowerCase();
          }
          return reply[sort];
        }],
        order);
    },
    getThreadRepliesTotal: ({ thread }): number => thread.forumThreadMetaData.numberOfReplies,
    getProject: ({ project }): Project => project,
  },
  mutations: {
    setCountries(state, countries: Array<Country>) {
      state.countries = countries;
    },
    setCities(state, cities: Array<City>) {
      state.cities = cities;
    },
    setIndustries(state, industries: Array<Industry>) {
      state.industries = industries;
    },
    setProjectList(state, projects: Array<ProjectListProject>) {
      state.projectList = projects;
    },
    setProject(state, project: Project) {
      state.project = { ...state.project, ...project };
    },
    setProjectListSort(state, sort: ProjectListSort) {
      state.projectListSort = sort;
    },
    setThread(state, thread: Thread) {
      state.thread = { ...state.thread, ...thread };
    },
    setOpenEditMessageId(state, messageId: string) {
      state.editMessageId = messageId;
    },
    setOpenReplyToMessageId(state, messageId: string) {
      state.replyMessageId = messageId;
    },
    setMessagesSort(state, sort: MessagesSort) {
      state.messagesSort = sort;
    },
    updateReply({ thread }, { message, messageLocation }) {
      const regexMessageCount = new RegExp(/^[1-9]+(?:\.[1-9]+)?$/);
      if (regexMessageCount.test(messageLocation)) {
        const messageLocationParts: Array<number> = messageLocation.split('.').map((item: string) => parseInt(item));
        if (messageLocationParts[1] && thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1] !== undefined) {
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].text = message.text;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].voteResult = message.voteResult;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].downvoteCount = message.downvoteCount;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].upvoteCount = message.upvoteCount;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].created = message.created;
          thread.messages[messageLocationParts[0] - 1].subMessages![messageLocationParts[1] - 1].edited = message.edited;
        } else {
          thread.messages[messageLocationParts[0] - 1].text = message.text;
          thread.messages[messageLocationParts[0] - 1].voteResult = message.voteResult;
          thread.messages[messageLocationParts[0] - 1].downvoteCount = message.downvoteCount;
          thread.messages[messageLocationParts[0] - 1].upvoteCount = message.upvoteCount;
          thread.messages[messageLocationParts[0] - 1].created = message.created;
          thread.messages[messageLocationParts[0] - 1].edited = message.edited;
        }
      }
    },
  },
  actions: {
    fetchCountries({ commit }) {
      getPlatformCountries().then((countries) => {
        commit('setCountries', countries);
      });
    },
    fetchCities({ commit }, { countryId, searchString }) {
      if (searchString.length >= 3) {
        const regexPostalCode = new RegExp(/^[\d]+$/);
        if (regexPostalCode.test(searchString)) {
          getCountryCitiesByPostCode(countryId, searchString).then((cities) => {
            if (cities.length > 0) {
              commit('setCities', cities);
            }
          });
        } else {
          getCountryCities(countryId, searchString).then((cities) => {
            if (cities.length > 0) {
              commit('setCities', cities);
            }
          });
        }
      } else {
        commit('setCities', []);
      }
    },
    fetchIndustries({ commit }) {
      getIndustries().then((industries: Array<Industry>) => {
        commit('setIndustries', industries);
      });
    },
    fetchProjectList({ state, commit }) {
      state.isFetchingProjects = true;
      ProjectForumService.getProjectList().then((projects: Array<ProjectListProject>) => {
        commit('setProjectList', projects);
      }).finally(() => {
        state.isFetchingProjects = false;
      });
    },
    async fetchProject({ commit }, projectId) {
      await ProjectForumService.getProject(projectId).then(async (project) => {
        commit('setProject', project);
      });
    },
    async fetchProjectThread({ commit }, projectId): Promise<Forum> {
      const forum = await ProjectForumService.getProjectForumMessages(projectId);
      const [thread] = forum.groups[0].threads;
      commit('setThread', thread);
      return forum;
    },
    async postNewProject({ commit }, postProject: PostProject): Promise<Project> {
      return ProjectForumService.postNewProject(postProject).then((response) => response);
    },
    async postThreadMessage({ commit, state, dispatch }, postMessage: MessagePost):Promise<Forum> {
      const { forumId, threadId, groupId } = state.thread;
      return ProjectForumService.postThreadMessage(forumId, groupId, threadId, postMessage).then((forum) => {
        const { initialMessage } = forum.groups[0].threads[0];
        if (postMessage.replyToMessageId === initialMessage.messageId) {
          dispatch('fetchProjectThread', state.project.externalId);
        } else {
          const [message] = forum.groups[0].threads[0].messages;
          state.thread.messages.forEach((stateMessage: Message, index: number) => {
            if (stateMessage.messageId === message.replyToMessageId) {
              state.thread.messages[index].subMessages!.push(message);
            } else {
              stateMessage.subMessages!.forEach((subMessage: Message) => {
                if (subMessage.messageId === message.replyToMessageId) {
                  state.thread.messages[index].subMessages!.push(message);
                }
              });
            }
          });
          setTimeout(() => {
            document.querySelector(`#message-${message.messageId}`)!.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 200);
        }
        return forum;
      });
    },
    updateThreadMessage({ commit, state }, { postMessage, messageCount }) {
      const { groupId } = state.thread;
      ForumService.updateThreadMessage(postMessage.forumId, groupId, postMessage.threadId, postMessage.forumThreadMessageId || '', postMessage.messageContent).then((forum) => {
        const [message] = forum.groups[0].threads[0].messages;
        commit('updateReply', { message, messageLocation: messageCount.replace(/#|\s/g, '') });
      }).catch((error) => {
        logError(LogLevel.ERROR_LOGGING, error);
      });
    },
    editThreadMessage({ commit }, messageId: string) {
      commit('setOpenEditMessageId', messageId);
    },
    replyToMessage({ commit }, messageId: string) {
      commit('setOpenReplyToMessageId', messageId);
    },
    sortThreadReplies({ commit }, sort: MessagesSort) {
      commit('setMessagesSort', sort);
    },
    updateProject({ commit }, postProject: PostProject) {
      ProjectForumService.updateProject(postProject.externalId as string, postProject).then((response) => {
        commit('setProject', response);
      });
    },
    sortProjectList({ commit }, sort: ProjectListSort) {
      commit('setProjectListSort', sort);
    },
    clearProjectAndThread({ commit }) {
      commit('setProject', emptyProject);
      commit('setThread', emptyProjectThread);
    },
    refreshThread({ commit, state }) {
      const { thread } = state;
      ForumService.getThread(thread.forumId, thread.groupId, thread.threadId).then((forum) => {
        const [responseThread] = forum.groups[0].threads;
        commit('setThread', responseThread);
      });
    },
    updateProjectGuests({ commit }, guests: Array<ProjectGuest>) {
      logDebug('Update guests', guests);
    }
  },
});

export default module;
