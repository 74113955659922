
import {
  computed, ComputedRef, defineComponent, reactive, ref, watch
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import { useRoute, useRouter } from 'vue2-helpers/vue-router';
import useI18n from '@/mixins/useI18n';
import SubscriptionIcon from '@/components/svg/subscriptions.svg?inline';
import FeedIcon from '@/components/svg/feed.svg?inline';
import SettingsIcon from '@/components/svg/settings.svg?inline';
import FilterIcon from '@/components/svg/filter.svg?inline';
import MarvinLookUpIcon from '@/components/svg/marvin/marvin_look_up.svg?inline';
import FeedListItem from '@/views/subscriptions/components/FeedListItem.vue';
import FeedListItemSkeleton from '@/views/subscriptions/components/FeedListItemSkeleton.vue';
import BCXBreadcrumbs from '@/components/molecules/BCXBreadcrumbs.vue';

export default defineComponent({
  name: 'SubscriptionFeed',
  components: {
    BCXBreadcrumbs,
    SubscriptionIcon,
    FeedIcon,
    SettingsIcon,
    FilterIcon,
    MarvinLookUpIcon,
    FeedListItem,
    FeedListItemSkeleton,
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();

    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);
    const isLoading: ComputedRef<boolean> = computed(() => store.state.subscriptions.loadingFeed);

    const breadcrumbItems = computed(() => {
      const breadcrumbArray: any = [
        {
          text: !isMobileLayout.value ? 'Home' : 'ByteCookie',
          to: { name: 'home' },
        },
        {
          text: t('subscriptions.title'),
          to: { name: 'subscriptions' },
        },
      ];
      if (isMobileLayout.value) {
        breadcrumbArray[0] = { text: 'ByteCookie' };
      }
      return breadcrumbArray;
    });

    const manageLink = ref({ name: 'manageSubscriptions' });

    const feedList = computed(() => store.getters['subscriptions/getFilteredSubscriptionFeed']);

    const hasFeedItems: ComputedRef<boolean> = computed(() => store.getters['subscriptions/getFilteredSubscriptionFeed'].length > 0);

    const filters = reactive(t('subscriptions.filters') as any);

    const activeFilter = computed(() => store.state.subscriptions.subscriptionFeedFilter);

    const isTalksFilterSet = computed(() => activeFilter.value === 'TALKS');
    const isProjectsFilterSet = computed(() => activeFilter.value === 'PROJECTS');

    const noEntriesState = computed(() => {
      if (isTalksFilterSet.value || isProjectsFilterSet.value) {
        return {
          textPath: 'subscriptions.no-entries-feed-filtered',
          routeTo: { name: activeFilter.value.toLowerCase() === 'talks' ? 'forums' : 'projectList' },
          routeText: (t(`subscriptions.${activeFilter.value.toLowerCase()}`) as string),
        };
      }
      return {
        textPath: 'subscriptions.no-entries-feed-all',
        routeToTalks: { name: 'forums' },
        routeTextTalks: (t('subscriptions.talks') as string),
        routeToProjects: { name: 'projectList' },
        routeTextProjects: (t('subscriptions.projects') as string),
      };
    });
    function setFilter(key: string) {
      if (key) {
        key = key.toUpperCase();
        const filterKeys: string[] = Object.keys(filters);
        if (filterKeys.includes(key) && key !== activeFilter.value && key !== 'ALL') {
          const currentQuery = router.currentRoute.query;
          router.replace({
            name: router.currentRoute.name as string,
            query: {
              ...currentQuery,
              filter: key,
            },
          }).catch((_e) => { /* DO NOTHING */ });
          store.dispatch('subscriptions/filterSubscriptionFeed', key);
        } else {
          router.replace({
            name: router.currentRoute?.name as string,
            query: {
              ...router.currentRoute.query,
              filter: undefined,
            },
          });
          store.dispatch('subscriptions/filterSubscriptionFeed', 'ALL');
        }
      }
    }

    function isFilterActive(key: any): boolean {
      return key === activeFilter.value;
    }

    const routeQuery = computed(() => route.query);

    watch(routeQuery, ({ filter }) => {
      if (filter !== activeFilter.value) {
        setFilter(filter as string);
      }
    }, { immediate: true, deep: true });

    function refreshSubscriptions() {
      store.dispatch('subscriptions/fetchSubscriptionFeed');
    }

    return {
      manageLink,
      breadcrumbItems,
      isMobileLayout,
      isLoading,
      filters,
      setFilter,
      isFilterActive,
      isTalksFilterSet,
      isProjectsFilterSet,
      noEntriesState,
      feedList,
      hasFeedItems,
      refreshSubscriptions,
      t,
    };
  },
});
