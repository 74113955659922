
import {
  defineComponent, ref, PropType, computed, Ref, watch, onMounted, toRefs
} from 'vue';
import { without } from 'lodash';
import { useVModel } from '@vueuse/core';

interface Filter {
  text: string;
  value: string | number;
}

export default defineComponent({
  props: {
    value: {
      type: Array as PropType< Array< string|number > >,
      default: () => []
    },
    filters: {
      type: Array as PropType<Filter[]>,
      default: () => []
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const activeFilters: Ref<Filter[]> = ref([]);
    const { filters } = toRefs(props);

    const activeFilterValues = useVModel(props, 'value', emit, {
      eventName: 'input',
      defaultValue: []
    });

    const isFilterActive = (filter: string|number): boolean => activeFilterValues.value.includes(filter);

    const toggleFilter = (filter: Filter) => {
      if (!isFilterActive(filter.value)) {
        activeFilters.value = [filter];
      } else {
        activeFilters.value = without(activeFilters.value, filter);
      }
    };

    watch(() => activeFilters.value, (newValue) => {
      activeFilterValues.value = newValue.map((filter) => filter.value);
    }, { deep: true });

    onMounted(() => {
      if (activeFilterValues.value.length > 0) {
        filters.value.forEach((filter) => {
          if (activeFilterValues.value.includes(filter.value)) {
            activeFilters.value.push(filter);
          }
        });
      }
    });

    return {
      isFilterActive,
      toggleFilter,
      activeFilters
    };
  },
});
