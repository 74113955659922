import MostAppreciatedContainer from '@/components/most-appreciated/MostAppreciatedContainer.vue';
import RecommendToUserContainer from '@/views/recommendations/components/recommend-to-user/RecommendToUserContainer.vue';
import { RouteConfig } from 'vue-router';
import store from '@/store';
import ProjectForumThread from '@/views/project-forum/pages/ProjectForumThread.vue';
import ProjectList from '@/views/project-forum/pages/ProjectList.vue';
import NewProjectForumThread from '@/views/project-forum/pages/NewProjectForumThread.vue';
import ProjectGuestInvitation from '@/views/project-forum/pages/ProjectGuestInvitation.vue';

const routes: Array<RouteConfig> = [
  {
    name: 'createProject',
    path: '/projects/new-project',
    component: NewProjectForumThread,
    meta: {
      sidebarComponent: MostAppreciatedContainer,
      layout: 'full',
      auth: true,
    },
  },
  {
    name: 'projectList',
    path: '/projects',
    component: ProjectList,
    beforeEnter(_to, _from, next) {
      store.dispatch('projectForum/fetchProjectList').then(() => {
        store.dispatch('subscriptions/fetchSubscriptions');
        next();
      });
    },
    meta: {
      sidebarComponent: MostAppreciatedContainer,
      layout: 'full',
      auth: true,
    },
  },
  {
    name: 'projectForumThread',
    path: '/projects/:projectId',
    component: ProjectForumThread,
    meta: {
      sidebarComponent: RecommendToUserContainer,
      layout: 'full',
      auth: true,
    },
  },
  {
    name: 'projectGuests',
    path: '/projects/:projectId/guests',
    component: ProjectGuestInvitation,
    meta: {
      sidebarComponent: RecommendToUserContainer,
      layout: 'full',
      auth: true,
    },
    async beforeEnter(to, _from, next) {
      next({ name: 'projectForumThread', params: { projectId: to.params.projectId } });
      /*
      await store.dispatch('projectForum/fetchProject', to.params.projectId).then(() => {
        const project = store.getters['projectForum/getProject'];
        if (store.getters['user'].userId !== project.creator.userId) {
          next({ name: 'projectForumThread', params: { projectId: to.params.projectId } });
          return;
        }
        next();
      }); */
    }
  }
];
export default routes;
