// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
const useWebAudioPlayer = () => {
  const context = new (
    window.AudioContext || window.webkitAudioContext
  )();

  let audiobuffer = null;
  let resolveAudioBuffer = null;

  const init = async (audioFileUrl) => {
    const buffer = await fetch(audioFileUrl)
      .then((resp) => resp.ok && resp.arrayBuffer());
    if (!buffer) return;
    audiobuffer = await context.decodeAudioData(buffer);
    resolveAudioBuffer?.();
  };

  const play = async () => {
    if (!audiobuffer) {
      await new Promise((resolve) => {
        resolveAudioBuffer = resolve;
      });
    }
    const buffersource = context.createBufferSource();
    buffersource.buffer = audiobuffer;
    buffersource.connect(context.destination);
    buffersource.start(0);
  };

  return {
    play,
    init,
  };
};

export default useWebAudioPlayer;
