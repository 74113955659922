
import NotificationPanelItems from '@/components/toolbar/notification/NotificationPanelItems.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import { computed, defineComponent } from 'vue';
import useNotificationState from '@/state/notificationState';
import { NotificationCountable, NotificationType } from '@/models/Notification';

export default defineComponent({
  name: 'NotificationPanel',
  components: { NotificationPanelItems },
  setup() {
    const { isSmartphoneWidth } = useResponsiveness();
    const {
      notifications, which, clear, panelPos
    } = useNotificationState();

    const style = computed(() => {
      if (isSmartphoneWidth.value) {
        return {
          left: `${25 + panelPos.value * 50}%`,
        };
      }
      return null;
    });

    const createPanelItems = (obj?:NotificationCountable) => (obj ? Object.entries(obj)
      .filter(([key]) => key !== 'sum')
      .map(([key, value]) => ({
        count: value,
        name: key,
      })) : []);

    const messages = computed(() => createPanelItems(notifications.value?.[NotificationType.Messages]));
    const projects = computed(() => createPanelItems(notifications.value?.[NotificationType.Projects]));
    const talks = computed(() => createPanelItems(notifications.value?.[NotificationType.Talks]));

    const isPanelVisible = (name:string, count:number) => (!which.value || which.value === name) && count;
    const addPanel = (name:NotificationType, count:number, items:any[]) => (isPanelVisible(name, count) ? { [name]: items } : {});

    const panelItems = computed(() => ({
      ...addPanel(NotificationType.Projects, notifications.value?.[NotificationType.Projects]?.sum ?? 0, projects.value),
      ...addPanel(NotificationType.Talks, notifications.value?.[NotificationType.Talks]?.sum ?? 0, talks.value),
      ...addPanel(NotificationType.Messages, notifications.value?.[NotificationType.Messages]?.sum ?? 0, messages.value),
    }));

    return {
      isSmartphoneWidth,
      panelItems,
      clear,
      style
    };
  },
});
