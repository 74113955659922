import { BackendError, BackendErrors, TranslatedErrorCode } from '@/models/BackendError';
import { AxiosResponse } from 'axios';

const translateErrorCode = (rawCode:string) => {
  switch (rawCode) {
    case 'BC-X-001': return TranslatedErrorCode.UNSPECIFIC_ERROR;
    case 'BC-UIC-005':
    case '{invite_code_not_valid}': return TranslatedErrorCode.INVALID_INVITE_CODE;
    case 'BC-UIC-004': return TranslatedErrorCode.INVITE_CODE_ALREADY_USED;
    case 'user.email.not_unique': return TranslatedErrorCode.EMAIL_NOT_UNIQUE;
    case 'user.email.not_valid': return TranslatedErrorCode.EMAIL_NOT_VALID;
    case 'user.email.not_verified': return TranslatedErrorCode.EMAIL_NOT_VERIFIED;
    case 'BC-USR-090': return TranslatedErrorCode.REGISTRATION_WRONG_STATUS;
    case 'BC-USR-021': return TranslatedErrorCode.EMAIL_CODE_INVALID;
    case 'user.firstname.not_consistent': return TranslatedErrorCode.FIRSTNAME_NOT_CONSISTENT;
    case 'user.firstname.min_length': return TranslatedErrorCode.FIRSTNAME_MIN_LENGTH;
    case 'user.firstname.max_length': return TranslatedErrorCode.FIRSTNAME_MAX_LENGTH;
    case 'user.firstname.size': return TranslatedErrorCode.FIRSTNAME_SIZE;
    case 'user.firstname.empty': return TranslatedErrorCode.FIRSTNAME_EMPTY;
    case 'user.lastname.not_consistent': return TranslatedErrorCode.LASTNAME_NOT_CONSISTENT;
    case 'user.lastname.min_length': return TranslatedErrorCode.LASTNAME_MIN_LENGTH;
    case 'user.lastname.max_length': return TranslatedErrorCode.LASTNAME_MAX_LENGTH;
    case 'user.lastname.size': return TranslatedErrorCode.LASTNAME_SIZE;
    case 'user.lastname.empty': return TranslatedErrorCode.LASTNAME_EMPTY;
    case 'registration.legal.not_confirmed': return TranslatedErrorCode.REGISTRATION_LEGAL_NOT_CONFIRMED;
    case 'user.mobilephone.not_valid_or_inuse': return TranslatedErrorCode.MOBILE_NUMBER_IN_USE;
    case 'user.mobilephone.already_exists': return TranslatedErrorCode.MOBILE_NUMBER_IN_USE;
    case 'user.mobilephone.not_valid': return TranslatedErrorCode.MOBILE_NUMBER_NOT_VALID;
    case 'BC-USR-034':
    case 'BC-USR-0345': return TranslatedErrorCode.MOBILE_VERIFICATION_CODE_INVALID;
    case 'user.mobilephone.not_verified': return TranslatedErrorCode.MOBILE_NUMBER_NOT_VERIFIED;
    case 'BC-USET-003': return TranslatedErrorCode.PASSWORD_DOES_NOT_MATCH;
    case 'BC-SEC-022': return TranslatedErrorCode.TOO_MANY_LOGINS;
    case 'user.password.empty': return TranslatedErrorCode.PASSWORD_EMPTY;
    case 'user.password.min_length': return TranslatedErrorCode.PASSWORD_TOO_SHORT;
    case 'user.password.not_valid': return TranslatedErrorCode.PASSWORD_NOT_VALID;
    case 'BC-USR-092': return TranslatedErrorCode.PASSWORD_ALREADY_GIVEN;
    case 'BC-USR-012': return TranslatedErrorCode.ACCOUNT_SUSPENDED;
    default:
      return null;
      // return `${TranslatedErrorCode.UNKNOWN_ERROR}++#!#++${rawCode}`; Fallback for other errors -> needs rework
  }
};

const backendErrorTranslator = (response:AxiosResponse):BackendErrors | null => {
  const { data } = response;

  const errorCode = translateErrorCode(data?.error?.code);

  const errorMessage:string | null = data?.error?.message;

  const validationErrors = Object
    .entries(data?.validationError ?? {})
    .map(([key, value]) => {
      const codeInfo = key.split(/\.|---/);
      const code = translateErrorCode(codeInfo?.[2] ?? value) ?? translateErrorCode(value as string);
      return ({
        code,
        message: value as string,
        inputField: codeInfo?.[1]
      } as BackendError);
    });

  return {
    errorCode,
    errorMessage,
    validationErrors
  };
};

class TranslatedBackendErrors {
  public backendErrors

  constructor(response:AxiosResponse) {
    this.backendErrors = backendErrorTranslator(response);
  }

  setError(code:TranslatedErrorCode) {
    if (this.backendErrors) {
      this.backendErrors.errorCode = code;
    }
  }

  hasError(code:TranslatedErrorCode) {
    return code === this.backendErrors?.errorCode
      || this.validationErrors.includes(code);
  }

  hasErrors() {
    return !!(this.backendErrors?.errorCode || this.backendErrors?.validationErrors?.length);
  }

  getValidationErrorByCode(code:TranslatedErrorCode) {
    return this.backendErrors?.validationErrors?.find?.((backendError) => backendError.code === code);
  }

  getValidationErrorByField(field:string) {
    return this.backendErrors?.validationErrors?.find?.((backendError) => backendError.inputField === field);
  }

  get validationErrors():TranslatedErrorCode[] {
    return this.backendErrors?.validationErrors?.map?.((backendError) => backendError.code) ?? [];
  }

  get allErrors():TranslatedErrorCode[] | string[] {
    const errorCode = this.backendErrors?.errorCode;
    return [
      ...errorCode ? [errorCode] : [],
      ...this.validationErrors
    ];
  }

  get errorCode():string {
    return this.backendErrors?.errorCode ?? '';
  }

  get errorMessage():string {
    return this.backendErrors?.errorMessage ?? '';
  }
}

export default TranslatedBackendErrors;
