

import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { ExternalProjectListEntry } from '@/__generated__/types';

export default defineComponent({
  name: 'ProjectListItemOpenState',
  props: {
    projectStatus: {
      type: String as PropType<ExternalProjectListEntry['projectStatus']>,
      required: true,
    }
  },
  setup(props) {
    const { projectStatus } = toRefs(props);

    const projectStatusIndicatorClass = computed(() => {
      switch (projectStatus.value) {
        case 'ACTIVE':
          return 'bc-project-list-item-open-state--open';
        case 'HOLD':
          return 'bc-project-list-item-open-state--hold';
        default:
          return 'bc-project-list-item-open-state--closed';
      }
    });

    return {
      projectStatusIndicatorClass
    };
  }
});

