
import Chevron from '@/components/svg-components/Chevron.vue';
import ByteCookieIcon from '@/components/svg/logo/logo-circles-only.svg?inline';
import useI18n from '@/mixins/useI18n';
import useResponsiveness from '@/mixins/useResponsiveness';
import { ProfileStoreKey } from '@/models/Profile';
import useProfileState from '@/state/profile/profileState';
import ProfileBasicEditor from '@/views/profile/components/editors/ProfileBasicEditor.vue';
import ProfileBasicView from '@/views/profile/components/views/ProfileBasicView.vue';
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  components: {
    ByteCookieIcon,
    ProfileBasicEditor,
    ProfileBasicView,
    Chevron
  },
  setup() {
    const { t } = useI18n();
    const profileLink = { name: 'profile' };
    const avatarRerenderKey = ref(0);
    const {
      init, isLoading, user, isEditSectionOpen, toggleEditSection
    } = useProfileState(ProfileStoreKey.PROFILE);
    init();
    const { isMobileWidth } = useResponsiveness();

    onMounted(() => {
      toggleEditSection('basic');
    });
    return {
      t,
      isEditSectionOpen,
      avatarRerenderKey,
      isLoading,
      isMobileWidth,
      user,
      profileLink
    };
  }
});
