

import { computed, defineComponent } from 'vue';
import useProfileState from '@/state/profile/profileState';
import { ProfileStoreKey } from '@/models/Profile';

export default defineComponent({
  name: 'ProfileAboutusView',
  setup() {
    const { profile } = useProfileState(ProfileStoreKey.PROFILE);

    const companyInformation = computed(() => profile.value?.companyInformation);

    return {
      companyInformation
    };
  }
});

