
import BCXNavigationItem from '@/components/navigation/BCXNavigationItem.vue';
import { NavigationTree, NavigationTreeItems } from '@/models/Navigation';
import BCXCollapsible from '@/components/molecules/BCXCollapsible.vue';
import X from '@/components/svg-components/X.vue';
import Vue, { computed, Ref, toRefs } from 'vue';
import useI18n from '@/mixins/useI18n';
import useResponsiveness from '@/mixins/useResponsiveness';
import { useStore } from 'vue2-helpers/vuex';

export default Vue.extend({
  name: 'BCXNavigationItems',
  components: { X, BCXNavigationItem, BCXCollapsible },
  props: {
    items: {
      type: Object as () => NavigationTreeItems,
      default: () => ({})
    },
    allCollapsed: { type: Boolean, default: false },
    parent: { type: Object as () => NavigationTree, default: () => null },
    i18Key: { type: String, default: '' },
    level: { type: Number, default: 0 }
  },
  setup(props) {
    const store = useStore();
    const showAdminNav = (isAdminMenu: boolean) => {
      if (isAdminMenu) {
        return (store.getters.user.roles as Array<string>).includes('ADMIN');
      }
      return true;
    };
    const {
      level, allCollapsed, parent, items, i18Key
    } = toRefs(props) as {
      level: Ref<number>;
      allCollapsed: Ref<boolean>;
      parent: Ref<NavigationTree>;
      items: Ref<NavigationTreeItems>;
      i18Key: Ref<string>;
    };
    const { isMobileLayout } = useResponsiveness();
    const { t } = useI18n();

    const isOpened = computed(() => {
      if (allCollapsed.value) return false;
      return (parent.value as NavigationTree)?._opened ?? false;
    });

    const showMobileCloseButton = computed(() => isMobileLayout.value && level.value === 1 && isOpened.value);

    const isVisible = (item: NavigationTree): boolean => {
      if (item._hide) {
        return isMobileLayout.value ? item._hide === 'desktop' : item._hide === 'mobile';
      }
      return true;
    };

    const getLabel = (key: string) => {
      const hasItems = !!items.value?.[key]?._items;
      const i18SubKey: string = hasItems ? `${key}.name` : key;
      return t(`${i18Key.value}.${i18SubKey}`) as string;
    };

    const subContainer = computed(() => (level.value ? 'BCXCollapsible' : 'div'));

    return {
      isMobileLayout,
      showMobileCloseButton,
      isVisible,
      isOpened,
      getLabel,
      subContainer,
      showAdminNav
    };
  }
});
