
import useProfileState from '@/state/profile/profileState';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import {
  computed, defineComponent, provide, ref, watch
} from 'vue';
import useMessengerState from '@/state/messenger/messengerState';
import ProfileEditActionButtons from '@/views/profile/components/ProfileEditActionButtons.vue';
import BCXSearchSelect from '@/components/molecules/BCXSearchSelect.vue';
import IconAvatarEdit from '@/components/svg/avatar-circle.svg?inline';
import { updateBasicData } from '@/services/Profile/Api';
import BCXTextArea from '@/components/molecules/forms/BCXTextArea.vue';
import BCXInfoSection from '@/components/molecules/BCXInfoSection.vue';
import { getValueByText } from '@/utils/selectItemTools';
import { ProfileMaxChars } from '@/models/Profile';
import AvatarUpload from '../AvatarUpload.vue';

export default defineComponent({
  name: 'ProfileBasicEditor',
  components: {
    BCXInfoSection,
    BCXTextArea,
    BCXSearchSelect,
    ProfileEditActionButtons,
    BCXAvatar,
    IconAvatarEdit,
    AvatarUpload
  },
  setup() {
    const {
      user,
      profile,
      isSelf,
      countries,
    } = useProfileState();
    const { isMobileWidth } = useResponsiveness();
    const {
      createNewChat,
      isOpened,
    } = useMessengerState();

    provide('currentEditSection', ref('basic'));

    const country = ref<string>('');
    const city = ref<string>('');
    const summary = ref<string>('');
    const citySearch = ref<string>('');
    const countryIsoCode = ref<string>('');
    const avatarDialog = ref<boolean>(false);
    const avatarRerenderKey = ref<number>(0);

    const cityCharactersLeft = computed(() => {
      const charsLeft = ProfileMaxChars.HEADER_CITY - city.value.length;
      if (charsLeft > 0) {
        return charsLeft;
      }
      return 0;
    });

    watch(profile, (profile) => {
      if (profile?.basic) {
        const {
          country: _country,
          city: _city,
          profileSummary
        } = profile.basic;
        country.value = _country ?? '';
        city.value = _city ?? '';
        summary.value = profileSummary ?? '';
      }
    }, {
      immediate: true
    });

    const countryIsoCodeFromProfile = computed(
      () => getValueByText(countries.value, country.value) ?? '',
    );

    watch(countryIsoCodeFromProfile, (isoCode) => {
      countryIsoCode.value = isoCode;
    }, { immediate: true });

    // const cities = asyncComputed<any[]>(async () => (
    //   citySearch.value?.length >= 3
    //     ? convertToItemList(uniqBy(
    //       await getCountryCities(countryIsoCode.value, citySearch.value),
    //       ({ placeName }) => placeName,
    //     ), 'placeName', 'placeName')
    //     : []),
    // [],
    // { lazy: true });

    const avatarSize = computed(() => (isMobileWidth.value ? 120 : 120));

    const writeMessage = async () => {
      if (user.value) {
        isOpened.value = true;
        await createNewChat(user.value);
      }
    };

    const save = async () => {
      await updateBasicData({
        city: city.value,
        countryIsoCode: countryIsoCode.value,
        profileSummary: summary.value,
      });
    };

    const clearLocation = () => {
      city.value = '';
    };

    return {
      user,
      avatarSize,
      profile,
      isSelf,
      countries,
      country,
      // cities,
      city,
      cityCharactersLeft,
      countryIsoCode,
      summary,
      citySearch,
      writeMessage,
      clearLocation,
      save,
      avatarDialog,
      avatarRerenderKey,
      maxLength: {
        city: ProfileMaxChars.HEADER_CITY,
        summary: ProfileMaxChars.HEADER_SUMMARY
      }
    };
  },
});
