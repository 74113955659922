
import Vue from 'vue';
import { ForumLink } from '@/models/ForumLink';
import { SelectItem } from '@/models/SelectItem';
import { debounce } from 'lodash';
import { mapGetters, mapMutations, mapState } from 'vuex';
import ParticipationService from '@/services/ParticipationService';
import BCXParticipateLinks from '@/components/navigation/panels/BCXParticipateLinks.vue';
import BCXTextArea from '@/components/molecules/forms/BCXTextArea.vue';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import X from '@/components/svg-components/X.vue';
import Chevron from '@/components/svg-components/Chevron.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXSearchSelectNew from '@/components/molecules/BCXSearchSelect.vue';

export default Vue.extend({
  name: 'BCXParticipate',
  components: {
    BCXSearchSelectNew,
    BCXMarkdown,
    Chevron,
    X,
    BCXAvatar,
    BCXTextArea,
    BCXParticipateLinks,
  },
  data() {
    return {
      category: '',
      text: '',
      errorsText: 0,
      title: '',
      errorsTitle: 0,
      linkList: [] as ForumLink[],
      isTextAreaBig: false,
      debouncedSearch: null as any,
      isSubmitted: false,
      bigAreaHeight: 125,
      maxLength: 5000
    };
  },

  computed: {
    ...mapState('page', ['isMobileWidth']),
    ...mapGetters(['user']),
    categoryItems(): SelectItem[] {
      return Object.entries(this.$t('participate.categories'))
        .map(([value, text]) => ({
          value,
          text,
        }));
    },
    inputEnabled(): boolean {
      return this.category !== '';
    },
    canSend(): boolean {
      return !!this.title.trim().length
        && !!this.text.trim().length
        && !!this.category
        && !this.isSubmitted;
    },
    canSearch(): boolean {
      return !!this.title.trim().length && !!this.category;
    },
  },

  watch: {
    isTextAreaBig(isBig) {
      if (isBig) {
        this.setEnlargedHeight();
      }
    },
    // eslint-disable-next-line func-names
    title() {
      this.debouncedSearch();
    },
    category() {
      this.debouncedSearch();
    },
  },

  created() {
    this.debouncedSearch = debounce(this.search, 500);
    this.text = this.$t('participate.default_text').toString();
  },

  methods: {
    ...mapMutations('page', ['setIsParticipateVisible']),
    setEnlargedHeight() {
      const elements:any = Array.from(this.$el.querySelectorAll('.c-hidden-when-big'));
      let height = 0;
      elements.forEach((element:HTMLElement) => {
        height += element.getBoundingClientRect().height;
      });
      this.bigAreaHeight = height - 32; // Substract Text row bottom padding
    },
    requiredRule(txt: string): boolean | string {
      return txt || this.$t('participate.error.required') as string;
    },
    resetForm() {
      this.isSubmitted = false;
      this.title = '';
      this.category = '';
      this.text = '';
      this.isTextAreaBig = false;
      this.linkList = [];
    },
    closePanel() {
      this.setIsParticipateVisible(false);
    },
    closeDesktopPanel() {
      if (!this.isMobileWidth) {
        this.closePanel();
      }
    },
    async search() {
      if (this.canSearch) {
        const linkList = await ParticipationService.getMatchingTopics(this.title, this.category);
        if (Array.isArray(linkList)) {
          if (linkList?.length > 10) linkList.length = 10;
          this.linkList = linkList;
        } else {
          this.linkList = [];
        }
      }
    },
    async submit() {
      await ParticipationService.addParticipationTopic(this.title, this.category, this.text);
      this.isSubmitted = true;
      setTimeout(() => {
        this.closePanel();
        this.resetForm();
      }, 2000);
    },
  },
});
