import { createGlobalState } from '@vueuse/core';
import { ref } from 'vue';

const useTriggerLegalTextsPopup = createGlobalState(() => {
  const legalText = ref<'terms' | 'privacy' | 'legalnotice' | false>(false);
  const setLegalText = (type: 'terms' | 'privacy' | 'legalnotice') => {
    legalText.value = type;
  };

  return {
    legalText,
    setLegalText
  };
});

export default useTriggerLegalTextsPopup;
