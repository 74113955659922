
import scrollIntoView from '@/utils/scrollIntoView';
import {
  computed, defineComponent, nextTick, onMounted, ref, toRefs, watch, watchEffect
} from 'vue';
import {
  computedEager, useRafFn, useResizeObserver
} from '@vueuse/core';
import asyncWait from '@/utils/asyncWait';

export default defineComponent({
  name: 'BCXCollapsible',
  props: {
    isOpened: { type: Boolean },
    // defining type "HTMLElement" does not work here unfortunately:
    // eslint-disable-next-line vue/require-prop-types
    scrollIntoViewTarget: {
      type: HTMLElement,
      default: () => undefined
    },
    // eslint-disable-next-line vue/require-prop-types
    scrollIntoViewBoundary: {
      type: HTMLElement,
      default: () => undefined
    },
    immediate: {
      type: Boolean
    },
    removeHidden: {
      type: Boolean
    },
    isOpening: {
      type: Boolean
    },
    isClosing: {
      type: Boolean
    }
  },
  setup(props, { emit, listeners }) {
    const {
      isOpened, scrollIntoViewTarget, scrollIntoViewBoundary, immediate, removeHidden
    } = toRefs(props);
    const innerHeight = ref(0);
    const inner = ref<HTMLElement>();
    const isAnimating = ref(false);
    const startClosing = ref(false);
    const useChangedInnerSizeEvent = !!listeners['changed-inner-size'];

    const useOverflowHidden = computed(() => {
      if (removeHidden.value) {
        return !(!isAnimating.value && isOpened.value);
      }
      return true;
    });

    const onTransitionEnd = (evt:TransitionEvent) => {
      if (evt.target === evt.currentTarget) {
        if (isOpened.value) isAnimating.value = false;
        emit('update:isOpening', false);
        emit('update:isClosing', false);
      }
    };

    const measureHeight = () => {
      if (inner.value) {
        innerHeight.value = inner.value.offsetHeight;
        if (useChangedInnerSizeEvent) {
          emit('changed-inner-size', inner.value);
        }
      }
    };

    const scrollWatcher = useRafFn(async () => {
      scrollIntoView(scrollIntoViewTarget.value, scrollIntoViewBoundary.value);
    }, { immediate: false });

    const shouldScrollWatch = computedEager(() => !!(isAnimating.value && isOpened.value && scrollIntoViewTarget.value && scrollIntoViewBoundary.value));

    watch(shouldScrollWatch, async (should) => {
      if (should) scrollWatcher.resume();
      else scrollWatcher.pause();
    });

    if (useChangedInnerSizeEvent) {
      useResizeObserver(inner, () => {
        if (inner.value) {
          emit('changed-inner-size', inner.value);
        }
      });
    }

    onMounted(() => {
      measureHeight();
    });

    const height = computed(() => (isOpened.value ? innerHeight.value : 0));

    watch(isOpened, (is) => {
      isAnimating.value = true;
      if (is) {
        emit('update:isOpening', true);
        measureHeight();
      } else {
        emit('update:isClosing', true);
        startClosing.value = true;
      }
    });

    const innerStyle = computed(
      () => useOverflowHidden.value && {
        overflow: 'hidden'
      }
    );

    const style = computed(() => {
      const style: any = { ...innerStyle.value };
      if (immediate.value) {
        if (!isOpened.value) {
          style.display = 'none';
        }
      } else if (startClosing.value) {
        measureHeight();
        // eslint-disable-next-line vue/no-async-in-computed-properties
        setTimeout(() => {
          startClosing.value = false;
        }, 10);
        style.height = `${innerHeight.value}px`;
      } else if (isAnimating.value || !isOpened.value) {
        style.height = `${height.value}px`;
      }
      return style;
    });

    return {
      inner,
      style,
      innerStyle,
      onTransitionEnd
    };
  }
});
