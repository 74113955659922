
import useI18n from '@/mixins/useI18n';
import { ExternalRecommendation, RecommendationFeedbackType } from '@/models/Recommendations';
import useMessengerState from '@/state/messenger/messengerState';
import markdownQuoter from '@/utils/markdown/markdownQuoter';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';
import RecommendationActions from '@/views/recommendations/components/RecommendationActions.vue';
import {
  computed, defineComponent, PropType, toRefs
} from 'vue';
import { logDebug } from '@/utils/logger';

export default defineComponent({
  name: 'FeedbackDetails',
  components: { RecommendationActions },
  props: {
    recommendation: {
      type: Object as PropType<ExternalRecommendation>,
      default: null
    },
    project: {
      type: Object as PropType<ProjectListProject>,
      default: null
    },
    isFeedback: {
      type: Boolean,
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { recommendation, project, isFeedback } = toRefs(props);
    const { createNewChat, startNewChat, isInputFocussed } = useMessengerState();

    const onReport = () => {
      logDebug('would report');
    };

    const toUser = computed(() => (isFeedback.value
      ? recommendation.value?.recommendedUser
      : recommendation.value?.recommendingUser));

    const onAnswer = async () => {
      startNewChat();

      const message = (isFeedback.value
        ? recommendation.value?.recommendedUserFeedbackResponseMessage
        : recommendation.value?.recommendingMessage);

      if (!toUser.value) return;

      const projectTitle = project.value?.projectTitle;

      const sendMessage = (message
        ? t('recommendations.recommendations.replyMessage', {
          projectTitle,
          quotedMessage: markdownQuoter(message)
        })
        : t('recommendations.recommendations.replyMessageEmpty', {
          projectTitle
        })).toString();

      await createNewChat(toUser.value, `${sendMessage}\n\n<br><br>`);
      isInputFocussed.value = true;
    };

    const fName = computed(() => toUser.value?.firstname ?? toUser.value?.fullname ?? '?');

    const ratingKey = computed(() => recommendation.value?.recommendedUserFeedback ?? RecommendationFeedbackType.Neutral);

    const feedbackRating = computed(() => t(`recommendations.feedback.ratings.${ratingKey.value}`, { fname: fName.value }));

    const notFittingTextKey = computed(() => `recommendations.feedback.not-fitting-texts.${ratingKey.value}`);

    const feedbackFitsPreferencesList = computed(() => {
      const items = recommendation.value?.recommendedUserFeedbackNotFittingPreferencesList;
      return items ? items.map((item) => t(`recommendations.feedback.not-fitting-preferences.${item}`)).join(', ') : null;
    });

    const feedbackFitsPreferences = computed(() => {
      const notFitsPreferences = recommendation.value?.recommendedUserFeedbackNotFittingPreferences;
      return notFitsPreferences ? t(`${notFittingTextKey.value}.preferences${feedbackFitsPreferencesList.value ? '-with-list' : ''}`, { fname: fName.value }) : '';
    });

    const feedbackFitsSkills = computed(() => {
      const notFitsSkills = recommendation?.value?.recommendedUserFeedbackNotFittingSkills;
      return notFitsSkills ? t(`${notFittingTextKey.value}.skills`, { fname: fName.value }) : null;
    });

    return {
      feedbackFitsPreferences,
      feedbackFitsPreferencesList,
      feedbackFitsSkills,
      onReport,
      onAnswer,
      toUser,
      feedbackRating
    };
  }
});
