
import Vue, { reactive, ref } from 'vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import { useStore } from 'vue2-helpers/vuex';
import { useRouter } from 'vue2-helpers/vue-router';
import useVuelidate from '@vuelidate/core';
import useBCValidators from '@/mixins/useBCValidators';
import ButtonBlock from '@/components/login-register/ButtonBlock.vue';
import { logError } from '@/utils/logger';

export default Vue.extend({
  name: 'PasswordReset',
  components: { ButtonBlock, BCXMarkdown, BCXValidatedInput },
  setup() {
    const store = useStore();
    const router = useRouter();
    const invalidEmail = ref(false);

    const fields = reactive({
      email: ''
    });

    const { emailValidation } = useBCValidators();

    const rules = {
      email: emailValidation
    };

    const v = useVuelidate(rules, fields);

    const resetPassword = async () => {
      if (await v.value.$validate()) {
        store.dispatch('resetPassword', fields.email)
          .catch((error) => {
            logError('There was an error!', error);
            if (error.response?.status !== 200) {
              invalidEmail.value = true;
            }
          });
      }
    };

    return {
      fields,
      v,
      invalidEmail,
      resetPassword,
      goBack: () => router.back()
    };
  },
});
