import axios from '@/plugins/axios';
import { User } from '@/models/User';
import { autoEncodeUserId } from '@/utils/userIdEncoding';
import { pick } from 'lodash';
import { AxiosResponse } from 'axios';

interface PreRegisterRequest {
  isBetaTest: boolean;
  fullname: string;
  email: string;
  url?: string;
}

export default {
  async registerBasicData(
    user: User,
    inviteCode: string,
    checkedAgbAndPrivacy: boolean,
    registrationProcessId?: string,
  ): Promise<any> {
    const userData = pick(user, ['email', 'firstname', 'lastname', 'checkedAgbAndPrivacy', 'userId']);
    const { userId = '', ...rest } = userData;
    return axios.post('/v1/users/user/register/initial', {
      ...rest,
      userId: autoEncodeUserId(userId),
      inviteCode,
      checkedAgbAndPrivacy,
      registrationProcessId,
    });
  },

  async registerSecondStep(
    emailVerificationCode: string,
    password: string,
    userId: string,
    registrationProcessId: string,
  ): Promise<AxiosResponse> {
    return axios.post('/v1/users/user/register/password', {
      emailVerificationCode,
      password,
      userId: autoEncodeUserId(userId),
      registrationProcessId,
    }).catch(({ response }) => response);
    // return {
    //   ...response.data,
    //   token: response.headers.authorization,
    // };
  },

  async resendEmail(userId: string, registrationProcessId: string): Promise<any> {
    return axios.post('/v1/users/user/register/resend-verification-mail', {
      userId: autoEncodeUserId(userId),
      registrationProcessId,
    });
  },

  async preRegister(data: PreRegisterRequest): Promise<any> {
    return (await axios.post('/v1/users/user/preregister', data)).data;
  },

  async submitMobilephone(mobilephoneNumber: string): Promise<any> {
    return axios.post('/v1/users/user/register/mobilephone', { mobilephoneNumber }).catch((error) => error.response);
  },

  async verifyMobilephone(mobilephoneVerificationCode: string): Promise<any> {
    return axios.post('/v1/users/user/register/mobilephone/verify', { mobilephoneVerificationCode })
      .then(({ data, headers }) => {
        localStorage.setItem('token', (headers.authorization ?? ''));
        localStorage.setItem('refresh_token', (headers.refresh_token ?? ''));
        return data;
      }).catch((error) => error.response);
  },

  async resendSMS(): Promise<any> {
    return axios.post('/v1/users/user/register/mobilephone/resend-verification-sms');
  },
};
